import { EntityBase } from '../base/entitybase';
import { Company } from '../symbiosis/company';
import { Lodging } from '../symbiosis/lodging';
import { User } from '../symbiosis/user';

export class Archive extends EntityBase {
  filePath: string;
  file?: File;
  fileType?: number;
  user?: User;
  lodging?: Lodging;
  company?: Company;
  uniqueFileReference?: string;

  constructor(
    filePath: string,
    file?: File,
    fileType?: number,
    user?: User,
    lodging?: Lodging,
    company?: Company,
    uniqueFileReference?: string
  ) {
    super();
    this.file = file;
    this.filePath = filePath;
    this.fileType = fileType;
    this.user = user;
    this.lodging = lodging;
    this.company = company;
    this.uniqueFileReference = uniqueFileReference;
  }
}
