import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpHeadersService {
  constructor() {}

  public GetHttpHeaders(contentType?: string): HttpHeaders {
    return contentType
      ? new HttpHeaders({
          'Content-Type': contentType,
          'Access-Control-Allow-origin': '*',
        })
      : new HttpHeaders({
          'Access-Control-Allow-origin': '*',
        });
  }
}
