<ng-container [class]="'calendar-component'">
  <div class="calendar-component__container">
    <div class="calendar-component__container__calendar-section">
      <mat-select
        class="calendar-component__container__calendar-section__filter"
        placeholder="Filtrer par logement..."
        (selectionChange)="calendarService.filterEventListByLodging($event)"
      >
        <mat-option
          *ngFor="let lodging of calendarService.lodgings; let index = index"
          [value]="lodging"
        >
          {{ lodging.label }}
        </mat-option>
        <mat-option [value]="calendarService.calendarEventList"
          >Synthèse des calendriers</mat-option
        >
      </mat-select>
      <full-calendar
        class="calendar-component__container__calendar-section__full-calendar"
        [options]="calendarService.calendarSubjectValue"
      ></full-calendar>

      <div
        [class]="
          width < 1024
            ? 'calendar-component__container__calendar-section__buttons --mobile'
            : 'calendar-component__container__calendar-section__buttons'
        "
      >
        <div
          [class]="
            width < 1150
              ? 'calendar-component__container__calendar-section__buttons__refresh --mobile'
              : 'calendar-component__container__calendar-section__buttons__refresh'
          "
        >
          <button
            mat-raised-button
            (click)="
              this.calendarService.refreshCalendar(
                calendarService.refreshCalendarParameters
              )
            "
          >
            <mat-icon
              class="calendar-component__container__calendar-section__buttons__refresh__icon"
              aria-hidden="false"
              >autorenew</mat-icon
            >
            <a *ngIf="width >= 1024">Mise à jour du calendrier</a>
          </button>
        </div>

        <div
          class="calendar-component__container__calendar-section__buttons__toggle"
        >
          <div class="reservation-toggle-bloc">
            <div
              [class]="
                width < 1024
                  ? 'calendar-component__container__calendar-section__buttons__toggle__reservation --mobile'
                  : 'calendar-component__container__calendar-section__buttons__toggle__reservation'
              "
            >
              <mat-slide-toggle
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === 'Owner'
                "
                [formControl]="reservationSlideToggle"
                (change)="calendarService.filterReservationList()"
              >
                <span *ngIf="width >= 1450"> Afficher les réservations </span>
                <span *ngIf="width < 1450"> Réservations </span>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="toggle-bloc">
            <div
              [class]="
                width < 1450
                  ? 'calendar-component__container__calendar-section__buttons__toggle__housework --mobile'
                  : 'calendar-component__container__calendar-section__buttons__toggle__housework'
              "
            >
              <mat-slide-toggle
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === 'Owner'
                "
                [formControl]="houseworkSlideToggle"
                (change)="calendarService.filterHouseworkList()"
                ><span *ngIf="width >= 1450"> Afficher les ménages </span>
                <span *ngIf="width < 1450"> Ménages </span>
              </mat-slide-toggle>
            </div>
            <div
              [class]="
                width < 1450
                  ? 'calendar-component__container__calendar-section__buttons__toggle__check --mobile'
                  : 'calendar-component__container__calendar-section__buttons__toggle__check'
              "
            >
              <mat-slide-toggle
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === 'Owner'
                "
                [formControl]="checkSlideToggle"
                (change)="calendarService.filterCheckList()"
                ><span *ngIf="width >= 1450"> Afficher les check-in/out </span>
                <span *ngIf="width < 1450"> Réceptions </span>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="toggle-bloc">
            <div
              [class]="
                width < 1450
                  ? 'calendar-component__container__calendar-section__buttons__toggle__not-available --mobile'
                  : 'calendar-component__container__calendar-section__buttons__toggle__not-available'
              "
            >
              <mat-slide-toggle
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === 'Owner'
                "
                [formControl]="notAvailableSlideToggle"
                (change)="calendarService.filterNotAvailableList()"
                ><span *ngIf="width >= 1450"> Afficher les vérrouillés </span>
                <span *ngIf="width < 1450"> Vérrouillés </span>
              </mat-slide-toggle>
            </div>
            <div
              [class]="
                width < 1450
                  ? 'calendar-component__container__calendar-section__buttons__toggle__canceled --mobile'
                  : 'calendar-component__container__calendar-section__buttons__toggle__canceled'
              "
            >
              <mat-slide-toggle
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === 'Owner'
                "
                [formControl]="canceledSlideToggle"
                (change)="calendarService.filterCanceledList()"
                ><span *ngIf="width >= 1450"> Afficher les annulations </span>
                <span *ngIf="width < 1450"> Annulations </span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-component__container__event-section">
      <app-event></app-event>
    </div>
  </div>
</ng-container>
