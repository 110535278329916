import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GridColumns } from 'src/app/core/user-interface/GridColumns';
import { MatGridList } from '@angular/material/grid-list';
import { Directive, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[gridCols]',
})
export class GridColsDirective implements OnInit {
  private gridCols: GridColumns = { xs: 1, sm: 2, md: 4, lg: 6, xl: 8 };

  public constructor(
    private breakpointObserver: BreakpointObserver,
    private grid: MatGridList
  ) {
    if (this.grid != null) {
      this.grid.cols = this.gridCols.md;
    }
  }

  public ngOnInit(): void {
    this.defineCols();
  }

  public get cols(): GridColumns {
    return this.gridCols;
  }

  @Input('gridCols')
  public set cols(map: GridColumns) {
    if (map && 'object' === typeof map) {
      this.gridCols = map;
    }
  }

  private defineCols() {
    if (this.grid != null) {
      this.grid.cols = this.gridCols.md;
    }
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.grid.cols = this.gridCols.xs;
        }
        if (result.breakpoints[Breakpoints.Small]) {
          this.grid.cols = this.gridCols.sm;
        }
        if (result.breakpoints[Breakpoints.Medium]) {
          this.grid.cols = this.gridCols.md;
        }
        if (result.breakpoints[Breakpoints.Large]) {
          this.grid.cols = this.gridCols.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.grid.cols = this.gridCols.xl;
        }
      });
  }
}
