<ng-container class="admin-company-component">
  <h1 mat-dialog-title *ngIf="operation === 0">
    Création d'une nouvelle société
  </h1>

  <h1 mat-dialog-title *ngIf="operation === 1">Modification de la société</h1>

  <p>Saisissez les données de votre société.</p>

  <form
    [formGroup]="companyForm"
    (keyup.enter)="onSubmit()"
    class="admin-company-component__company-form"
  >
    <div
      [class]="
        width < 1024
          ? 'admin-company-component__company-form__company-address --mobile'
          : 'admin-company-component__company-form__company-address'
      "
    >
      <div
        class="admin-company-component__company-form__company-address__company-fields"
      >
        <mat-form-field
          class="admin-company-component__company-form__company-address__company-fields__company-input"
          appearance="fill"
        >
          <mat-label>Nom de la société</mat-label>
          <input
            matInput
            type="text"
            formControlName="label"
            placeholder="Symbiosis..."
            [ngClass]="{ 'is-invalid': submitted && f['label'].errors }"
          />
          <div *ngIf="submitted && f['label'].errors">
            <div *ngIf="f['label'].errors['required']">
              Le nom de la société est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__company-fields__company-input"
          appearance="fill"
        >
          <mat-label>Numéro de SIRET</mat-label>
          <input
            matInput
            type="number"
            formControlName="siret"
            placeholder="37..."
            [ngClass]="{
              'is-invalid': submitted && f['siret'].errors
            }"
          />
          <div *ngIf="submitted && f['siret'].errors">
            <div *ngIf="f['siret'].errors['required']">
              Le numéro de SIRET est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__company-fields__company-input"
          appearance="fill"
        >
          <mat-label>Numéro de téléphone</mat-label>
          <input
            matInput
            type="number"
            formControlName="phoneNumber"
            placeholder="37..."
            [ngClass]="{
              'is-invalid': submitted && f['phoneNumber'].errors
            }"
          />
          <div *ngIf="submitted && f['phoneNumber'].errors">
            <div *ngIf="f['phoneNumber'].errors['required']">
              Le numéro de SIRET est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__company-fields__company-input"
          appearance="fill"
        >
          <mat-label>E-mail</mat-label>
          <input
            matInput
            type="text"
            formControlName="email"
            placeholder="37..."
            [ngClass]="{
              'is-invalid': submitted && f['email'].errors
            }"
          />
          <div *ngIf="submitted && f['email'].errors">
            <div *ngIf="f['email'].errors['required']">
              L'adresse mail est requise
            </div>
            <div *ngIf="f['email'].errors['pattern']">
              L'adresse mail n'est pas au bon format
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__company-fields__company-input"
          appearance="fill"
        >
          <mat-label>Description</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="description"
            class="form-control"
            placeholder="Décrivez votre société et son activité..."
            [ngClass]="{ 'is-invalid': submitted && f['description'].errors }"
          ></textarea>
          <div *ngIf="submitted && f['description'].errors">
            <div *ngIf="f['description'].errors['required']">
              La description de la société est requise
            </div>
            <div *ngIf="f['description'].errors['maxlength']">
              300 caractères maximum
            </div>
          </div>
        </mat-form-field>
      </div>

      <div id="address-fields">
        <mat-form-field
          class="admin-company-component__company-form__company-address__address-fields__company-input"
          appearance="fill"
        >
          <mat-label>Adresse</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyAddressLabel"
            class="form-control"
            placeholder="1 rue du Capitol..."
            [ngClass]="{
              'is-invalid': submitted && f['companyAddressLabel'].errors
            }"
          />
          <div
            *ngIf="submitted && f['companyAddressLabel'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyAddressLabel'].errors['required']">
              L'adresse est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__address-fields__company-input"
          appearance="fill"
        >
          <mat-label>Code postal</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyPostalCode"
            class="form-control"
            placeholder="69000..."
            [ngClass]="{
              'is-invalid': submitted && f['companyPostalCode'].errors
            }"
          />
          <div
            *ngIf="submitted && f['companyPostalCode'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyPostalCode'].errors['required']">
              Le code postal est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__address-fields__company-input"
          appearance="fill"
        >
          <mat-label>Ville</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyCity"
            class="form-control"
            placeholder="Lyon..."
            [ngClass]="{ 'is-invalid': submitted && f['companyCity'].errors }"
          />
          <div
            *ngIf="submitted && f['companyCity'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyCity'].errors['required']">
              La ville est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__address-fields__company-input"
          appearance="fill"
        >
          <mat-label>Région</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyRegion"
            class="form-control"
            placeholder="Rhône-Alpes..."
            [ngClass]="{ 'is-invalid': submitted && f['companyRegion'].errors }"
          />
          <div
            *ngIf="submitted && f['companyRegion'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyRegion'].errors['required']">
              La région est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-company-component__company-form__company-address__address-fields__company-input"
          appearance="fill"
        >
          <mat-label>Pays</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyCountry"
            class="form-control"
            placeholder="France..."
            [ngClass]="{
              'is-invalid': submitted && f['companyCountry'].errors
            }"
          />
          <div
            *ngIf="submitted && f['companyCountry'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyCountry'].errors['required']">
              Le pays est requis
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div
      mat-dialog-actions
      class="admin-company-component__company-form__buttons"
    >
      <button
        mat-button
        class="admin-company-component__company-form__buttons__cancel-button"
        (click)="onClose()"
      >
        Annuler
      </button>
      <button
        mat-raised-button
        class="admin-company-component__company-form__buttons__validate-button"
        (click)="onSubmit()"
      >
        <mat-icon>check_circle</mat-icon>
        Valider
      </button>
    </div>
  </form>
</ng-container>
