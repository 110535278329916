import { AlertMessage } from '../alert-message';
import { EntityBase } from '../base/entitybase';
import { User } from './user';

export class CompanyMember extends EntityBase {
  public memberId?: number;
  public companyId?: number;
  public members?: Array<User>;
  public alertMessage?: AlertMessage;

  constructor(
    memberId?: number,
    companyId?: number,
    members?: Array<User>,
    alertMessage?: AlertMessage
  ) {
    super();
    this.memberId = memberId;
    this.companyId = companyId;
    this.members = members;
    this.alertMessage = alertMessage;
  }
}
