import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { SignalRHubService } from './shared/services/signalR-hub/signalR-hub.service';
import { dialogusHub } from 'src/environments/environment.api.urls';
import { Component, OnInit } from '@angular/core';
import {
  dialogusEndPoint,
  dialogusHubEndPoint,
} from 'src/environments/environment.api.endpoints';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'SymbiosisFrontApp';

  constructor(
    private authenticationService: AuthenticationService,
    private signalRHubService: SignalRHubService
  ) {}

  ngOnInit(): void {
    if (
      this.authenticationService.currentAuthenticationValue?.user !== undefined
    ) {
      this.signalRHubService.buildConnection(
        dialogusHub.baseUrl,
        dialogusEndPoint.message
      );
      this.signalRHubService.startConnection();
      this.signalRHubService.setListener(dialogusHubEndPoint.privateMessage);
    }
  }
}
