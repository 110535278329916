<div mat-dialog-content class="message-component">
  <p>Saisissez le message destiné à l'hôte.</p>
  <form
    [formGroup]="messageForm"
    (keyup.enter)="onSubmit()"
    class="message-component__message-form"
  >
    <mat-form-field
      class="message-component__message-form__message-field"
      appearance="fill"
    >
      <mat-label>Message</mat-label>
      <textarea
        matInput
        rows="10"
        type="text"
        formControlName="message"
        class="form-control"
        placeholder="Soignez votre message, donnez envie à l'hôte de travailler avec vous..."
        [ngClass]="{ 'is-invalid': submitted && f['message'].errors }"
      ></textarea>
      <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
        <div *ngIf="f['message'].errors['required']">
          Veuillez saisir votre message
        </div>
        <div *ngIf="f['message'].errors['maxlength']">
          300 caractères maximum
        </div>
      </div>
    </mat-form-field>

    <div mat-dialog-actions class="message-component__buttons">
      <button
        mat-button
        class="message-component__buttons__cancel-button"
        (click)="onClose()"
      >
        Annuler
      </button>
      <button
        mat-raised-button
        class="message-component__buttons__send-button"
        (click)="onSubmit()"
      >
        <mat-icon>send</mat-icon>
        Envoyer
      </button>
    </div>
  </form>
</div>
