import { AuthenticationService } from '../services/authentication/authentication.service';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { Archive } from 'src/app/core/models/archivum/archive';
import { ImageType } from 'src/app/core/enum/image-type';
import { OperationType } from './enum/operation.type';
import { LodgingBuilder } from './lodging-builder';
import { CompanyBuilder } from './company-builder';
import { UserBuilder } from './user-builder';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AuthBuilder {
  private badIndex: number = -1;

  constructor(
    private authenticationService: AuthenticationService,
    private lodgingBuilder: LodgingBuilder,
    private companyBuilder: CompanyBuilder,
    private userBuilder: UserBuilder
  ) {}

  public buildAuthenticationFromForm(
    form: FormGroup,
    operationType?: OperationType,
    isUserUpdate?: boolean,
    lodgingId?: number,
    isLodgingUpdate?: boolean,
    companyId?: number,
    isCompanyUpdate?: boolean
  ): Authentication {
    let authentication = new Authentication();

    this.buildAuthUser(form, authentication, operationType, isUserUpdate);

    this.buildAuthCompanyList(
      form,
      authentication,
      operationType,
      companyId,
      isCompanyUpdate
    );

    this.buildAuthLodgingList(
      form,
      authentication,
      operationType,
      lodgingId,
      isLodgingUpdate
    );

    return authentication;
  }

  private buildAuthUser(
    form: FormGroup,
    authentication: Authentication,
    operationType?: OperationType,
    isUserUpdate?: boolean
  ): Authentication {
    authentication.user =
      this.authenticationService.currentAuthenticationValue?.user;
    authentication.address =
      this.authenticationService.currentAuthenticationValue?.address;
    authentication.role =
      this.authenticationService.currentAuthenticationValue?.role!;
    authentication.token =
      this.authenticationService.currentAuthenticationValue?.token!;
    authentication.refreshToken =
      this.authenticationService.currentAuthenticationValue?.refreshToken;
    authentication.refreshTokenExpiryTime =
      this.authenticationService.currentAuthenticationValue?.refreshTokenExpiryTime;

    if (isUserUpdate) {
      authentication.user = this.userBuilder.buildUserFromForm(
        form,
        operationType
      );
    }

    return authentication;
  }

  private buildAuthLodgingList(
    form: FormGroup,
    authentication: Authentication,
    operationType?: OperationType,
    lodgingId?: number,
    isLodgingUpdate?: boolean
  ): Authentication {
    authentication.lodgings =
      this.authenticationService.currentAuthenticationValue?.lodgings;
    if (
      isLodgingUpdate &&
      authentication.lodgings?.findIndex((x) => x.id === lodgingId) !==
        this.badIndex
    ) {
      const index = authentication.lodgings?.findIndex(
        (x) => x.id === lodgingId
      );
      authentication.lodgings![index!] = this.lodgingBuilder.buildLodging(
        form,
        operationType,
        lodgingId
      );
    }

    return authentication;
  }

  private buildAuthCompanyList(
    form: FormGroup,
    authentication: Authentication,
    operationType?: OperationType,
    companyId?: number,
    isCompanyUpdate?: boolean
  ): Authentication {
    authentication.companies =
      this.authenticationService.currentAuthenticationValue?.companies;
    if (
      isCompanyUpdate &&
      authentication.companies?.findIndex((x) => x.id === companyId) !==
        this.badIndex
    ) {
      const index = authentication.companies?.findIndex(
        (x) => x.id === companyId
      );
      authentication.companies![index!] = this.companyBuilder.buildCompany(
        form,
        operationType,
        companyId
      );
    }

    return authentication;
  }

  public updateUserAuthenticationFromArchive(
    authentication: Authentication,
    archive: Archive,
    imageType: ImageType,
    lodgingId?: number
  ): Authentication {
    if (imageType === ImageType.User) {
      authentication.user!.avatar = archive.uniqueFileReference;
    }
    if (imageType === ImageType.Lodging) {
      const index = authentication.lodgings?.findIndex(
        (x) => x.id === lodgingId
      );
      authentication.lodgings![index!] = this.lodgingBuilder.buildLodging(
        undefined,
        undefined,
        lodgingId,
        archive.uniqueFileReference
      );
    }

    return authentication;
  }
}
