import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { HttpClientType } from 'src/app/shared/services/httpclient/enum/httpClientType';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { UserFileService } from 'src/app/shared/services/file/user-file.service';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { contentType } from 'src/environments/environment.content.types';
import { ArchiveBuilder } from 'src/app/shared/builder/archive-builder';
import { FileService } from 'src/app/shared/services/file/file.service';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { MatchScanner } from 'src/app/shared/helpers/match.scanner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { routeEndPoint } from 'src/environments/environment.routes';
import { UserBuilder } from 'src/app/shared/builder/user-builder';
import { Register } from 'src/app/core/models/symbiosis/register';
import { Component, HostListener, OnInit } from '@angular/core';
import { Archive } from 'src/app/core/models/archivum/archive';
import { User } from 'src/app/core/models/symbiosis/user';
import { role } from 'src/environments/environment.roles';
import { HttpErrorResponse } from '@angular/common/http';
import { FileType } from 'src/app/core/enum/file-type';
import { MatDialog } from '@angular/material/dialog';
import {
  archivumEndPoint,
  symbiosisEndPoint,
} from 'src/environments/environment.api.endpoints';
import { Gender } from 'src/app/core/enum/gender';
import {
  defaultFilePath,
  fileBasePath,
} from 'src/environments/environment.file.path';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public submitted: boolean = false;
  public registerForm!: FormGroup;
  public showSpinner = false;
  public gender!: Gender;

  public userArchive: Archive = new Archive(defaultFilePath.user_default_icon);
  private file: any;

  constructor(
    private authenticationService: AuthenticationService,
    public userFileService: UserFileService,
    private spinnerService: SpinnerService,
    private archiveBuilder: ArchiveBuilder,
    private httpClient: HttpClientService,
    private userBuilder: UserBuilder,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) {
    if (
      this.authenticationService.currentAuthenticationValue &&
      this.authenticationService.currentAuthenticationValue.role?.label ===
        role.admin
    ) {
      this.router.navigate([routeEndPoint.owner]);
    }
    if (this.authenticationService.currentAuthenticationValue) {
      this.router.navigate([
        `./${this.authenticationService.currentAuthenticationValue.role?.label?.toLocaleLowerCase()}`,
      ]);
    }
  }

  ngOnInit() {
    this.setRegisterForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    const user = this.userBuilder.buildUserFromForm(
      this.registerForm,
      OperationType.create
    );

    if (this.file) {
      this.userArchive.filePath = fileBasePath.image;
      this.userArchive.file = this.file;
      this.userArchive = this.archiveBuilder.buildArchive(
        this.userArchive,
        user
      );

      this.upload(this.userArchive, user);
    } else {
      this.createUser(user);
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  public upload(archive: Archive, user: User): void {
    this.fileService
      .uploadFileInArchivum(
        archivumEndPoint.uploadRegister,
        this.userFileService.setUserFormData(archive),
        HttpClientType.backend
      )
      .pipe(
        tap({
          next: (archive: Archive) => {
            if (archive !== undefined) {
              user.avatar = archive.uniqueFileReference;
              this.createUser(user);
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  public selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.fileService
        .uploadFileInArchivum(
          archivumEndPoint.uploadRegister,
          this.userFileService.setUserFormData(
            new Archive(
              defaultFilePath.register,
              this.file,
              FileType.Image,
              undefined
            )
          ),
          HttpClientType.backend
        )
        .pipe(
          tap({
            next: (archive: Archive) => {
              if (archive !== undefined) {
                this.userArchive = archive;
              }
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  public cancelRegistration(): void {
    this.router.navigate([routeEndPoint.login]);
  }

  public setGender(gender: Gender): void {
    this.gender = gender;
  }

  private createUser(user: User) {
    this.httpClient
      .httpCall(
        this.httpClient.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.createUser
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(user).replace('/\\/', ''),
        undefined,
        undefined,
        HttpClientType.backend
      )
      .pipe(first())
      .subscribe({
        next: (register: Register) => {
          if (register !== undefined) {
            this.router.navigate([routeEndPoint.login]);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.spinnerService.requestEnded();
          console.error(error);
        },
      });
  }

  private setRegisterForm() {
    this.registerForm = this.formBuilder.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        birthDay: [null, Validators.required],
        phoneNumber: ['', Validators.required],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ]),
        ],
        password: [
          '',
          Validators.compose([Validators.required, Validators.minLength(6)]),
        ],
        confirm: ['', Validators.required],
        gender: [null, Validators.required],
        roleLabel: ['', Validators.required],
        addressLabel: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        region: ['', Validators.required],
        country: ['', Validators.required],
      },
      {
        validator: MatchScanner('password', 'confirm'),
      }
    );
  }
}
