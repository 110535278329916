import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private showButtonsSubject: BehaviorSubject<boolean>;

  constructor() {
    this.showButtonsSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem('showButtons') || `${false}`)
    );
  }

  public setShowButtons(show: boolean): void {
    localStorage.setItem('showButtons', JSON.stringify(show));
    this.showButtonsSubject.next(show);
  }

  public getShowButtonsState(): Observable<boolean> {
    return this.showButtonsSubject.asObservable();
  }

  public resetShowButtons(): void {
    localStorage.removeItem('showButtons');
    this.showButtonsSubject.next(false);
  }

  public clearAuthentication(): void {
    localStorage.removeItem('currentAuthentication');
    localStorage.removeItem('onlineUsers');
    localStorage.removeItem('userChatList');
  }
}
