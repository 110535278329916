<ng-container class="welcomer-event-component">
  <mat-card class="welcomer-event-component__infos">
    <h2
      [class]="
        checkEvent === undefined
          ? 'welcomer-event-component__infos__lodging-title --hidden'
          : 'welcomer-event-component__infos__lodging-title'
      "
    >
      {{ checkEvent?.check?.lodging?.label }}
      <mat-card-subtitle
        class="welcomer-event-component__infos__lodging-title__address"
        >{{ checkEvent?.check?.lodging?.address?.label }}
        {{ checkEvent?.check?.lodging?.address?.postalCode }}
        {{ checkEvent?.check?.lodging?.address?.city }}</mat-card-subtitle
      >
    </h2>
    <mat-card-header class="welcomer-event-component__infos__lodging">
      <div class="welcomer-event-component__infos__lodging__image">
        <img
          mat-card-image
          [src]="lodgingArchive?.filePath"
          (error)="lodgingFileService.handleMissingImage(lodgingArchive!)"
          alt="Photo de l'appartement"
        />
      </div>
    </mat-card-header>

    <h2 class="welcomer-event-component__infos__check-title">Réception</h2>
    <mat-card-header class="welcomer-event-component__infos__check">
      <mat-card-title
        >Statut :
        <span
          [class]="
            checkEvent?.check?.summary === 'Annulé'
              ? 'welcomer-event-component__infos__check__status --canceled'
              : 'welcomer-event-component__infos__check__status'
          "
          >{{ checkEvent?.check?.summary }}</span
        >
      </mat-card-title>
      <mat-card-title
        >Check-in : {{ checkEvent?.check?.in | formatDate }}</mat-card-title
      >
      <mat-card-title
        >Check-out : {{ checkEvent?.check?.out | formatDate }}</mat-card-title
      >
      <mat-card-title class="welcomer-event-component__infos__title__address"
        >Adresse : {{ checkEvent?.check?.lodging?.address?.label }}
        {{ checkEvent?.check?.lodging?.address?.postalCode }}
        {{ checkEvent?.check?.lodging?.address?.city }}</mat-card-title
      >
    </mat-card-header>
  </mat-card>
</ng-container>
