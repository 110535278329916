import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Calendar } from 'src/app/core/models/symbiosis/calendar';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-calendar',
  templateUrl: './import-calendar.component.html',
  styleUrls: ['./import-calendar.component.scss'],
})
export class ImportCalendarComponent implements OnInit {
  public iCalForm!: FormGroup;
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<ImportCalendarComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public calendar: Calendar
  ) {
    this.iCalForm = this.formBuilder.group({
      iCalUrl: [calendar.url ?? '', Validators.required],
    });
  }

  ngOnInit(): void {}

  public get f() {
    return this.iCalForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.iCalForm.invalid) {
      return;
    }

    this.dialogRef.close(this.iCalForm.get('iCalUrl')?.value);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
