<ng-container class="search-component">
  <form
    class="search-component__search-form"
    [formGroup]="searchForm"
    (keyup.enter)="$event.preventDefault()"
  >
    <div class="search-component__search-form__search">
      <mat-form-field
        class="search-component__search-form__search__search-field"
        appearance="fill"
      >
        <mat-label>Recherche</mat-label>
        <input
          matInput
          class="search-component__search-form__search__search-field__search-input"
          type="text"
          formControlName="searchInput"
          class="form-control"
          placeholder="Saisir un code postal, département ou nom de ville..."
        />
      </mat-form-field>
      <a class="search-component__search-form__search__button">
        <button mat-button (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
      </a>
    </div>
  </form>

  <app-lodging-result></app-lodging-result>
</ng-container>
