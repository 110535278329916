import { LodgingType } from '../../enum/lodging.type';
import { EntityBase } from '../base/entitybase';
import { FormControl } from '@angular/forms';
import { Time } from '@angular/common';
import { Calendar } from './calendar';
import { Address } from './address';
import { User } from './user';

export class Lodging extends EntityBase {
  public label?: string;
  public surfaceArea?: number;
  public lodgingType?: LodgingType;
  public description?: string;
  public checkInStart?: Time;
  public checkOutEnd?: Time;
  public searchable?: boolean;
  public image?: string;
  public ownerId?: number;
  public address?: Address;
  public calendars?: Array<Calendar>;
  public welcomerForCheck?: boolean;
  public owner?: User;
  public searchableSlideToggle!: FormControl;

  constructor(
    label: string,
    surfaceArea: number,
    lodgingType: LodgingType,
    description: string,
    checkInStart?: Time,
    checkOutEnd?: Time,
    searchable?: boolean,
    image?: string,
    ownerId?: number,
    address?: Address,
    calendars?: Array<Calendar>
  ) {
    super();
    this.label = label;
    this.surfaceArea = surfaceArea;
    this.lodgingType = lodgingType;
    this.description = description;
    this.checkInStart = checkInStart;
    this.checkOutEnd = checkOutEnd;
    this.searchable = searchable;
    this.image = image;
    this.ownerId = ownerId;
    this.address = address;
    this.calendars = calendars;
  }
}
