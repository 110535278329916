import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { contentType } from 'src/environments/environment.content.types';
import { HttpHeadersService } from '../httpheaders/httpheaders.service';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { httpCallMethod } from 'src/environments/environment.methods';
import { HttpClientService } from '../httpclient/httpclient.service';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { routeEndPoint } from 'src/environments/environment.routes';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { HttpCallType } from '../httpclient/enum/httpCallType';
import { NavbarService } from '../navbar/navbar.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentAuthenticationSubject: BehaviorSubject<Authentication | null>;
  public currentAuthentication: Observable<Authentication | null>;
  public httpClient: HttpClient;

  constructor(
    private httpClientService: HttpClientService,
    private httpHeaders: HttpHeadersService,
    private navbarService: NavbarService,
    public handler: HttpBackend,
    private router: Router
  ) {
    this.currentAuthenticationSubject =
      new BehaviorSubject<Authentication | null>(
        JSON.parse(localStorage.getItem('currentAuthentication') || `${null}`)
      );
    this.currentAuthentication =
      this.currentAuthenticationSubject.asObservable();
    this.httpClient = new HttpClient(handler);
  }

  public get currentAuthenticationValue(): Authentication | null {
    return this.currentAuthenticationSubject.value;
  }

  public login(
    email: string,
    password: string
  ): Observable<Authentication | null> {
    return this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.login
        ),
        HttpCallType.Post,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify({ email: email, password: password })
      )
      .pipe(
        map((authentication: Authentication) => {
          if (
            authentication.alertMessage !== undefined &&
            authentication.alertMessage !== null
          ) {
            return null;
          }

          return this.setOrRefreshLocalStorage(authentication);
        })
      );
  }

  public logout(): Promise<boolean> {
    this.navbarService.resetShowButtons();
    this.navbarService.clearAuthentication();
    this.currentAuthenticationSubject.next(null);
    return this.router.navigate([routeEndPoint.login]);
  }

  public async tryRefreshAccessToken(): Promise<boolean> {
    if (
      this.currentAuthenticationValue?.token === undefined ||
      this.currentAuthenticationValue?.refreshToken === undefined
    ) {
      this.logout();
      return false;
    }

    try {
      const observable = this.httpClient.post(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.refreshAccessToken
        ),
        JSON.stringify(this.currentAuthenticationValue),
        {
          headers: this.httpHeaders.GetHttpHeaders(
            contentType.application_json
          ),
        }
      );
      const response: Authentication | null = await lastValueFrom(observable);

      if (response.token !== undefined && response.refreshToken !== undefined) {
        this.setOrRefreshLocalStorage(response);
        this.router.createUrlTree([`./${this.router.url}`]);
        return true;
      }
    } catch (error) {
      console.error(error);
      this.logout();
      return false;
    }

    this.logout();
    return false;
  }

  public setOrRefreshLocalStorage(
    authentication: Authentication
  ): Authentication | null {
    if (authentication === null) {
      return null;
    }

    this.navbarService.setShowButtons(true);
    localStorage.setItem(
      'currentAuthentication',
      JSON.stringify(authentication)
    );
    this.currentAuthenticationSubject.next(authentication);
    return authentication;
  }
}
