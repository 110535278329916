import { EntityBase } from '../base/entitybase';

export class Role extends EntityBase {
  public label?: string;

  constructor(label?: string) {
    super();
    this.label = label;
  }
}
