<ng-container class="profile-component">
  <mat-card class="profile-component__lodging-card">
    <h2 *ngIf="cleaner?.role?.label === 'Cleaner'">
      <mat-icon aria-hidden="false">brush</mat-icon>
      Agent d'entretien
    </h2>
    <h2 *ngIf="cleaner?.role?.label === 'Welcomer'">
      <mat-icon aria-hidden="false">key</mat-icon>
      Welcomer
    </h2>
    <mat-card-header class="profile-component__lodging-card__header">
      <img
        mat-card-avatar
        [src]="cleanerArchive?.filePath"
        (error)="userFileService.handleMissingImage(cleanerArchive!)"
        alt="Photo du postulant"
      />
      <mat-card-title
        >{{ cleaner?.lastname }} {{ cleaner?.firstname }}</mat-card-title
      >
      <mat-card-subtitle>{{ cleaner?.phoneNumber }}</mat-card-subtitle>
      <mat-card-subtitle>{{ cleaner?.email }}</mat-card-subtitle>
    </mat-card-header>

    <h2>Logement</h2>
    <mat-card-header>
      <mat-card-title>{{ chat.lodging.label }}</mat-card-title>
      <mat-card-subtitle
        >{{ chat.lodging.address!.label }} {{ chat.lodging.address!.city }} ({{
          chat.lodging.address!.postalCode
        }})</mat-card-subtitle
      >
    </mat-card-header>

    <img
      mat-card-image
      [src]="lodgingArchive?.filePath"
      (error)="lodgingFileService.handleMissingImage(lodgingArchive!)"
      alt="Photo de l'appartement"
    />

    <mat-card-actions class="profile-component__lodging-card__buttons">
      <div class="profile-component__lodging-card__buttons__action">
        <button
          mat-button
          class="profile-component__lodging-card__buttons__action__cancel-button"
          (click)="onClose()"
        >
          Annuler
        </button>
      </div>
      <div class="profile-component__lodging-card__buttons__action">
        <button
          mat-raised-button
          class="profile-component__lodging-card__buttons__action__connexion-button"
          (click)="onSubmit(cleaner!.id, chat.lodging.id)"
        >
          Etablir la connection
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
