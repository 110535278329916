import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  public messageForm: FormGroup;
  public submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MessageComponent>
  ) {
    this.messageForm = this.formBuilder.group({
      message: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(300)]),
      ],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.messageForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.messageForm.invalid) {
      return;
    }

    this.dialogRef.close(this.messageForm.value);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
