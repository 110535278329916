import { ImportCalendarComponent } from './lodging/import-calendar/import-calendar.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { ApiMessageInterceptor } from './shared/helpers/api.message.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { SharedComponent } from './shared/shared.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [SharedComponent],
  imports: [SharedModule],
  providers: [
    CalendarComponent,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiMessageInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  entryComponents: [ImportCalendarComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
