<ng-container class="login-component">
  <form
    [formGroup]="loginForm"
    (keyup.enter)="onSubmit()"
    [class]="
      width < 1024
        ? 'login-component__login-form --mobile'
        : 'login-component__login-form'
    "
  >
    <div class="login-component__login-form__login">
      <div class="login-component__login-form__login__user-email">
        <mat-form-field class="login-input" appearance="fill">
          <mat-label>E-mail</mat-label>
          <input
            matInput
            id="login-email"
            type="text"
            formControlName="email"
            class="form-control"
            placeholder="Adresse mail"
            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
          />
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']">
              L'adresse mail est requise
            </div>
          </div>
        </mat-form-field>
      </div>

      <div class="login-component__login-form__login__user-password">
        <mat-form-field class="login-input" appearance="fill">
          <mat-label>Mot de passe</mat-label>
          <input
            matInput
            id="login-password"
            [type]="hidePassword === true ? 'password' : 'text'"
            formControlName="password"
            class="form-control"
            placeholder="Mot de passe"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">
            {{ hidePassword ? "visibility_off" : "visibility" }}
          </mat-icon>
          <div
            *ngIf="submitted && f['password'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['password'].errors['required']">
              Le mot de passe est requis
            </div>
          </div>
        </mat-form-field>
      </div>

      <a class="login-component__login-form__login__button">
        <button
          class="login-component__login-form__login__button__connexion-button"
          mat-raised-button
          color="accent"
          (click)="onSubmit()"
        >
          Connexion
        </button>
      </a>

      <a class="login-component__login-form__login__button">
        <button
          class="login-component__login-form__login__button__new-button"
          mat-raised-button
          color="accent"
          (click)="goToRegister()"
        >
          Nouveau compte
        </button>
      </a>
    </div>
  </form>
</ng-container>
