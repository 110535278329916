import { OwnerEventPopupComponent as OwnerEventPopupComponent } from './components/calendar/event/owner-event-popup/owner-event-popup.component';
import { WelcomerEventPopupComponent } from './components/calendar/event/welcomer-event-popup/welcomer-event-popup.component';
import { CleanerEventPopupComponent } from './components/calendar/event/cleaner-event-popup/cleaner-event-popup.component';
import { WelcomerEventComponent } from './components/calendar/event/welcomer-event/welcomer-event.component';
import { CleanerEventComponent } from './components/calendar/event/cleaner-event/cleaner-event.component';
import { HouseworkMissionComponent } from '../mission/housework-mission/housework-mission.component';
import { OwnerEventComponent } from './components/calendar/event/owner-event/owner-event.component';
import { LodgingLineComponent } from '../lodging/lodging-list/lodging-line/lodging-line.component';
import { CompanyLineComponent } from '../company/company-list/company-line/company-line.component';
import { ImportCalendarComponent } from '../lodging/import-calendar/import-calendar.component';
import { AddWorkerComponent } from './components/worker-list/add-worker/add-worker.component';
import { LodgingResultComponent } from '../search/lodging-result/lodging-result.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { AdminLodgingComponent } from '../lodging/admin-lodging/admin-lodging.component';
import { CheckMissionComponent } from '../mission/check-mission/check-mission.component';
import { AdminCompanyComponent } from '../company/admin-company/admin-company.component';
import { WorkerListComponent } from './components/worker-list/worker-list.component';
import { LodgingListComponent } from '../lodging/lodging-list/lodging-list.component';
import { CompanyListComponent } from '../company/company-list/company-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridRowHeightDirective } from './directives/grid-rowHeight-directive';
import { CalendarComponent } from './components/calendar/calendar.component';
import { EventComponent } from './components/calendar/event/event.component';
import { MessageComponent } from './components/message/message.component';
import { EditUserComponent } from '../user/edit-user/edit-user.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { GridColsDirective } from './directives/grid-columns-directive';
import { RegisterComponent } from '../user/register/register.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProfileComponent } from '../chat/profile/profile.component';
import { WelcomerComponent } from '../welcomer/welcomer.component';
import { DialogComponent } from '../chat/dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormatDatePipe } from './services/date/format-date.pipe';
import { CompanyComponent } from '../company/company.component';
import { MissionComponent } from '../mission/mission.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LodgingComponent } from '../lodging/lodging.component';
import { CleanerComponent } from '../cleaner/cleaner.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginComponent } from '../user/login/login.component';
import { SharedRoutingModule } from './shared-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchComponent } from '../search/search.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FullCalendarModule } from '@fullcalendar/angular';
import { OwnerComponent } from '../owner/owner.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormatTime } from './services/date/format-time';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { UserComponent } from '../user/user.component';
import { ChatComponent } from '../chat/chat.component';
import iCalendarPlugin from '@fullcalendar/icalendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import { LayoutModule } from '@angular/cdk/layout';
import dayGridPlugin from '@fullcalendar/daygrid';
import { AppComponent } from '../app.component';
import { CommonModule } from '@angular/common';
import listPlugin from '@fullcalendar/list';
import { NgModule } from '@angular/core';

FullCalendarModule.registerPlugins([
  iCalendarPlugin,
  timeGridPlugin,
  dayGridPlugin,
  listPlugin,
]);

@NgModule({
  declarations: [
    WelcomerEventPopupComponent,
    CleanerEventPopupComponent,
    HouseworkMissionComponent,
    OwnerEventPopupComponent,
    ImportCalendarComponent,
    LodgingResultComponent,
    GridRowHeightDirective,
    WelcomerEventComponent,
    AdminLodgingComponent,
    AlertMessageComponent,
    ConfirmPopupComponent,
    CleanerEventComponent,
    CheckMissionComponent,
    AdminCompanyComponent,
    LodgingLineComponent,
    LodgingListComponent,
    WorkerListComponent,
    CompanyListComponent,
    CompanyLineComponent,
    OwnerEventComponent,
    AddWorkerComponent,
    WelcomerComponent,
    GridColsDirective,
    EditUserComponent,
    RegisterComponent,
    CalendarComponent,
    CompanyComponent,
    CleanerComponent,
    LodgingComponent,
    SpinnerComponent,
    MessageComponent,
    ProfileComponent,
    MissionComponent,
    DialogComponent,
    NavbarComponent,
    SearchComponent,
    EventComponent,
    LoginComponent,
    OwnerComponent,
    FormatDatePipe,
    UserComponent,
    ChatComponent,
    AppComponent,
    FormatTime,
  ],
  imports: [
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    MatFormFieldModule,
    MatFormFieldModule,
    FullCalendarModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    HttpClientModule,
    MatTooltipModule,
    AppRoutingModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatRadioModule,
    MatInputModule,
    MatRadioModule,
    MatInputModule,
    MatListModule,
    BrowserModule,
    MatIconModule,
    MatCardModule,
    CommonModule,
    LayoutModule,
    FormsModule,
  ],
  exports: [
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    MatFormFieldModule,
    MatFormFieldModule,
    FullCalendarModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    HttpClientModule,
    MatTooltipModule,
    AppRoutingModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatRadioModule,
    MatInputModule,
    MatRadioModule,
    MatInputModule,
    MatListModule,
    BrowserModule,
    MatIconModule,
    MatCardModule,
    CommonModule,
    LayoutModule,
    FormsModule,
  ],
})
export class SharedModule {}
