import { EntityBase } from '../base/entitybase';

export class Address extends EntityBase {
  public label: string;
  public postalCode: string;
  public city: string;
  public region: string;
  public country: string;

  constructor(
    label: string,
    postalCode: string,
    city: string,
    region: string,
    country: string
  ) {
    super();
    this.label = label;
    this.postalCode = postalCode;
    this.city = city;
    this.region = region;
    this.country = country;
  }
}
