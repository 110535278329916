import { AuthenticationService } from '../services/authentication/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      new JwtHelperService().isTokenExpired(
        this.authenticationService.currentAuthenticationValue?.token
      )
    ) {
      from(this.authenticationService.tryRefreshAccessToken());
    }

    if (
      this.authenticationService.currentAuthenticationValue?.user != null &&
      this.authenticationService.currentAuthenticationValue?.token != null
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.currentAuthenticationValue?.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
