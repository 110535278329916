export class WorkerResponse {
  public eventId?: number;
  public workerId?: number;
  public lodgingId?: number;
  public accepted?: boolean;

  constructor(
    eventId?: number,
    workerId?: number,
    lodgingId?: number,
    accepted?: boolean
  ) {
    this.eventId = eventId;
    this.workerId = workerId;
    this.lodgingId = lodgingId;
    this.accepted = accepted;
  }
}
