<ng-container class="admin-lodging-component">
  <h1 mat-dialog-title *ngIf="operation === 0">
    Création d'un nouveau logement
  </h1>

  <h1 mat-dialog-title *ngIf="operation === 1">Modification du logement</h1>

  <p>Saisissez les données de votre logement.</p>

  <form
    [formGroup]="lodgingForm"
    (keyup.enter)="onSubmit()"
    class="admin-lodging-component__lodging-form"
  >
    <div
      [class]="
        width < 1024
          ? 'admin-lodging-component__lodging-form__lodging-address --mobile'
          : 'admin-lodging-component__lodging-form__lodging-address'
      "
    >
      <div
        class="admin-lodging-component__lodging-form__lodging-address__lodging-fields"
      >
        <mat-form-field appearance="fill">
          <mat-label>Type de logement</mat-label>
          <mat-select formControlName="lodgingType">
            <mat-option
              *ngFor="let key of lodgingTypeKeys; let index = index"
              [value]="index"
            >
              {{ key }}
            </mat-option>
            <mat-error *ngIf="submitted && f['lodgingType'].errors">
              <mat-error *ngIf="f['lodgingType'].errors['required']"
                >Le type du logement est requis</mat-error
              >
            </mat-error>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__lodging-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Nom du logement</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingLabel"
            placeholder="Appartement n°1..."
            [ngClass]="{ 'is-invalid': submitted && f['lodgingLabel'].errors }"
          />
          <div *ngIf="submitted && f['lodgingLabel'].errors">
            <div *ngIf="f['lodgingLabel'].errors['required']">
              Le nom du logement est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__lodging-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Surface du logement</mat-label>
          <input
            matInput
            type="number"
            formControlName="lodgingSurfaceArea"
            placeholder="37..."
            [ngClass]="{
              'is-invalid': submitted && f['lodgingSurfaceArea'].errors
            }"
          />
          <div *ngIf="submitted && f['lodgingSurfaceArea'].errors">
            <div *ngIf="f['lodgingSurfaceArea'].errors['required']">
              La surface du logement est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__lodging-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Description</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="description"
            class="form-control"
            placeholder="Donnez envie aux agents d'entretien de s'engager pour votre logement..."
            [ngClass]="{ 'is-invalid': submitted && f['description'].errors }"
          ></textarea>
          <div *ngIf="submitted && f['description'].errors">
            <div *ngIf="f['description'].errors['required']">
              La description du logement est requise
            </div>
            <div *ngIf="f['description'].errors['maxlength']">
              300 caractères maximum
            </div>
          </div>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Arrivée à partir de</mat-label>
          <input
            matInput
            type="time"
            formControlName="checkInStart"
            [format]="24"
            [ngxTimepicker]="checkInStartPicker"
          />
          <ngx-material-timepicker
            #checkInStartPicker
          ></ngx-material-timepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Départ au plus tard à</mat-label>
          <input
            matInput
            type="time"
            formControlName="checkOutEnd"
            [format]="24"
            [ngxTimepicker]="checkOutEndPicker"
          />
          <ngx-material-timepicker #checkOutEndPicker></ngx-material-timepicker>
        </mat-form-field>
      </div>

      <div id="address-fields">
        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__address-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Adresse</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingAddressLabel"
            class="form-control"
            placeholder="1 rue du Capitol..."
            [ngClass]="{
              'is-invalid': submitted && f['lodgingAddressLabel'].errors
            }"
          />
          <div
            *ngIf="submitted && f['lodgingAddressLabel'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lodgingAddressLabel'].errors['required']">
              L'adresse est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__address-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Code postal</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingPostalCode"
            class="form-control"
            placeholder="69000..."
            [ngClass]="{
              'is-invalid': submitted && f['lodgingPostalCode'].errors
            }"
          />
          <div
            *ngIf="submitted && f['lodgingPostalCode'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lodgingPostalCode'].errors['required']">
              Le code postal est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__address-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Ville</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingCity"
            class="form-control"
            placeholder="Lyon..."
            [ngClass]="{ 'is-invalid': submitted && f['lodgingCity'].errors }"
          />
          <div
            *ngIf="submitted && f['lodgingCity'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lodgingCity'].errors['required']">
              La ville est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__address-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Région</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingRegion"
            class="form-control"
            placeholder="Rhône-Alpes..."
            [ngClass]="{ 'is-invalid': submitted && f['lodgingRegion'].errors }"
          />
          <div
            *ngIf="submitted && f['lodgingRegion'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lodgingRegion'].errors['required']">
              La région est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="admin-lodging-component__lodging-form__lodging-address__address-fields__lodging-input"
          appearance="fill"
        >
          <mat-label>Pays</mat-label>
          <input
            matInput
            type="text"
            formControlName="lodgingCountry"
            class="form-control"
            placeholder="France..."
            [ngClass]="{
              'is-invalid': submitted && f['lodgingCountry'].errors
            }"
          />
          <div
            *ngIf="submitted && f['lodgingCountry'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lodgingCountry'].errors['required']">
              Le pays est requis
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div
      mat-dialog-actions
      class="admin-lodging-component__lodging-form__buttons"
    >
      <button
        mat-button
        class="admin-lodging-component__lodging-form__buttons__cancel-button"
        (click)="onClose()"
      >
        Annuler
      </button>
      <button
        mat-raised-button
        class="admin-lodging-component__lodging-form__buttons__validate-button"
        (click)="onSubmit()"
      >
        <mat-icon>check_circle</mat-icon>
        Valider
      </button>
    </div>
  </form>
</ng-container>
