<ng-container class="cleaner-event-popup-component">
  <mat-card class="cleaner-event-popup-component__infos">
    <h2 class="cleaner-event-popup-component__infos__title">
      {{ houseworkEvent?.housework?.lodging?.label }}
    </h2>
    <mat-card-header class="cleaner-event-popup-component__infos__lodging">
      <div class="cleaner-event-popup-component__infos__lodging__image">
        <img
          mat-card-image
          [src]="lodgingArchive?.filePath"
          (error)="lodgingFileService.handleMissingImage(lodgingArchive!)"
          alt="Photo de l'appartement"
        />
      </div>
    </mat-card-header>

    <h2 class="cleaner-event-popup-component__infos__title">Ménage</h2>
    <mat-card-header class="cleaner-event-popup-component__infos__housework">
      <mat-card-title
        >Statut :
        <span
          [class]="
            houseworkEvent?.housework?.summary === 'Annulé'
              ? 'cleaner-event-popup-component__infos__housework__status --canceled'
              : 'cleaner-event-popup-component__infos__housework__status'
          "
          >{{ houseworkEvent?.housework?.summary }}</span
        ></mat-card-title
      >
      <mat-card-title
        >Date d'intervention :
        {{ houseworkEvent?.housework?.eventDate | formatDate }}</mat-card-title
      >
      <mat-card-title
        >Fin des check-out :
        {{
          houseworkEvent?.housework?.lodging?.checkOutEnd | formatTime
        }}</mat-card-title
      >
      <mat-card-title
        >Début des check-in :
        {{
          houseworkEvent?.housework?.lodging?.checkInStart | formatTime
        }}</mat-card-title
      >
      <mat-card-title
        class="cleaner-event-popup-component__infos__title__address"
        >Adresse : {{ houseworkEvent?.housework?.lodging?.address?.label }}
        {{ houseworkEvent?.housework?.lodging?.address?.postalCode }}
        {{ houseworkEvent?.housework?.lodging?.address?.city }}</mat-card-title
      >
    </mat-card-header>
  </mat-card>
  <div mat-dialog-actions class="cleaner-event-popup-component__buttons">
    <button
      mat-button
      class="cleaner-event-popup-component__buttons__confirm"
      (click)="close()"
      cdkFocusInitial
    >
      Fermer
    </button>
  </div>
</ng-container>
