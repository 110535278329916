import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { HttpClientService } from '../shared/services/httpclient/httpclient.service';
import { HttpCallType } from '../shared/services/httpclient/enum/httpCallType';
import { UserFileService } from '../shared/services/file/user-file.service';
import { Authentication } from '../core/models/symbiosis/authentication';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { OperationType } from '../shared/builder/enum/operation.type';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { EditUserComponent } from './edit-user/edit-user.component';
import { FileService } from '../shared/services/file/file.service';
import { AuthBuilder } from '../shared/builder/auth-builder';
import { UserBuilder } from '../shared/builder/user-builder';
import { Archive } from '../core/models/archivum/archive';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../core/models/symbiosis/user';
import { ImageType } from '../core/enum/image-type';
import {
  archivumEndPoint,
  symbiosisEndPoint,
} from 'src/environments/environment.api.endpoints';
import { FileType } from '../core/enum/file-type';
import {
  defaultFilePath,
  fileBasePath,
} from 'src/environments/environment.file.path';
import { FormGroup } from '@angular/forms';
import { finalize, tap } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public userImageType: ImageType = ImageType.User;
  public progress!: number;
  public message!: string;

  @ViewChild('selector')
  selector!: ElementRef;

  @Output()
  public userArchive: Archive = new Archive(defaultFilePath.user_default_icon);
  public onUploadFinished = new EventEmitter();
  public rowHeightValue: number = 45;
  public colsValue: number = 4;
  public user!: User;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    public userFileService: UserFileService,
    private authBuilder: AuthBuilder,
    private userBuilder: UserBuilder,
    public fileService: FileService,
    public dialog: MatDialog
  ) {
    this.setUser();
  }

  ngOnInit(): void {}

  public get width() {
    return window.innerWidth;
  }

  public setTiles(description: string, userAttribute: string): Array<Tile> {
    return [
      { text: description, cols: 1, rows: 1, color: '#F0F0F0' },
      { text: userAttribute, cols: 3, rows: 1, color: '#F0F0F0' },
    ];
  }

  public editUser(): void {
    this.setUser();
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '1000px',
      data: this.user,
    });

    dialogRef.afterClosed().subscribe((editUserForm: FormGroup) => {
      if (editUserForm != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.updateUser,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.put,
            contentType.application_json,
            JSON.stringify(
              this.authBuilder.buildAuthenticationFromForm(
                editUserForm,
                OperationType.edit,
                true,
                undefined,
                false,
                undefined,
                false
              )
            ).replace('/\\/', '')
          )
          .subscribe({
            next: (authentication: Authentication) => {
              this.authenticationService.setOrRefreshLocalStorage(
                authentication
              );
              this.setUser(authentication);
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  public selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.fileService
        .uploadFileInArchivum(
          archivumEndPoint.upload,
          this.userFileService.setUserFormData(
            new Archive(
              fileBasePath.image,
              event.target.files[0],
              FileType.Image,
              this.authenticationService.currentAuthenticationValue?.user!
            )
          )
        )
        .pipe(
          tap({
            next: (archive: Archive) => {
              if (archive !== undefined) {
                this.userArchive = archive;
              }
              this.fileService.refreshLocalStorage(archive, ImageType.User);
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          }),
          finalize(() => {
            this.fileService
              .refreshGuid(
                symbiosisApi.baseUrl,
                symbiosisEndPoint.updateUser,
                this.authenticationService.currentAuthenticationValue?.user?.id!
              )
              .subscribe({
                next: (authentication: Authentication) => {
                  this.authenticationService.setOrRefreshLocalStorage(
                    authentication
                  );

                  if (
                    authentication.user !== undefined &&
                    authentication.user.avatar !== undefined
                  ) {
                    this.userArchive.uniqueFileReference =
                      authentication.user.avatar;
                  }
                },
                error: (error: HttpErrorResponse) => {
                  console.error(error);
                },
              });
          })
        )
        .subscribe();
    }
  }

  private setUser(authentication?: Authentication): void {
    this.user = this.userBuilder.buildUserFromAuthenticaction(
      authentication ?? this.authenticationService.currentAuthenticationValue!
    );
    this.setUserArchive();
  }

  private setUserArchive(): void {
    this.fileService
      .getArchive(
        this.authenticationService.currentAuthenticationValue?.user?.avatar!
      )
      .subscribe({
        next: (archive: Archive) => {
          if (archive !== undefined) {
            this.userArchive = archive;
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }
}
