import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MissionService } from 'src/app/shared/services/mission/mission.service';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { GridRowHeights } from 'src/app/core/user-interface/GridRowHeights';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { GridColumns } from 'src/app/core/user-interface/GridColumns';
import { Archive } from 'src/app/core/models/archivum/archive';
import { Check } from 'src/app/core/models/symbiosis/check';
import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-check-mission',
  templateUrl: './check-mission.component.html',
  styleUrls: ['./check-mission.component.scss'],
})
export class CheckMissionComponent implements OnInit {
  @Input() public checkList: Array<Check> = new Array<Check>();
  @Input() public lodgingArchiveList = new Array<Archive>();

  public gridColumns: GridColumns = { xs: 1, sm: 1, md: 2, lg: 2, xl: 3 };
  public gridRowHeights: GridRowHeights = {
    xs: '1:1.1',
    sm: '1:1.1',
    md: '1:1.1',
    lg: '1:1.1',
    xl: '1:1.1',
  };

  constructor(
    private authenticationService: AuthenticationService,
    public lodgingFileService: LodgingFileService,
    private spinnerService: SpinnerService,
    private missionService: MissionService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  public response(checkId: number, lodgingId: number, accepted: boolean) {
    this.spinnerService.requestStarted();
    this.missionService
      .response(
        checkId,
        lodgingId,
        this.authenticationService.currentAuthenticationValue?.user?.id!,
        symbiosisEndPoint.setMissionWelcomerResponse,
        accepted
      )
      .subscribe({
        next: (currentCheck: Check) => {
          this.checkList.forEach((check) => {
            if (check.id === currentCheck.id) {
              const index = this.checkList.indexOf(check, 0);
              if (index! > -1) {
                this.checkList.splice(index!, 1);
              }
            }
          });
          this.spinnerService.requestEnded();
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.error(error.message);
          this.spinnerService.requestEnded();
        },
      });
  }

  public getLodgingFilePath(image: string): string | undefined {
    return this.lodgingArchiveList.find(
      (archive) => archive.lodging?.image === image
    )?.filePath;
  }
}
