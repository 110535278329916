<ng-container class="owner-event-component">
  <mat-card class="owner-event-component__infos">
    <h2
      [class]="
        reservationEvent === undefined
          ? 'owner-event-component__infos__lodging-title --hidden'
          : 'owner-event-component__infos__lodging-title'
      "
    >
      {{ reservationEvent?.reservation?.label }}
      <mat-card-subtitle
        class="owner-event-component__infos__lodging-title__address"
        >{{ houseworkEvent?.housework?.lodging?.address?.label }}
        {{ houseworkEvent?.housework?.lodging?.address?.postalCode }}
        {{
          houseworkEvent?.housework?.lodging?.address?.city
        }}</mat-card-subtitle
      >
    </h2>
    <mat-card-header class="owner-event-component__infos__lodging">
      <div class="owner-event-component__infos__lodging__image">
        <img
          mat-card-image
          [src]="lodgingArchive?.filePath"
          (error)="lodgingFileService.handleMissingImage(lodgingArchive!)"
          alt="Photo de l'appartement"
        />
      </div>
    </mat-card-header>

    <h2 class="owner-event-component__infos__reservation-title">Réservation</h2>
    <mat-card-header class="owner-event-component__infos__reservation">
      <div class="owner-event-component__infos__reservation__details">
        <mat-card-title
          >Plateforme : {{ reservationEvent?.platform }}</mat-card-title
        >
        <mat-card-title
          >Statut :
          <span
            *ngIf="reservationEvent?.status === 'Loué'"
            [class]="
              'owner-event-component__infos__reservation__details__status --booked'
            "
            >{{ reservationEvent?.status }}</span
          >
          <span
            *ngIf="reservationEvent?.status === 'Annulé'"
            [class]="
              'owner-event-component__infos__reservation__details__status --canceled'
            "
            >{{ reservationEvent?.status }}</span
          >
          <span
            *ngIf="reservationEvent?.status === 'Vérrouillé'"
            [class]="
              'owner-event-component__infos__reservation__details__status --closed'
            "
            >{{ reservationEvent?.status }}</span
          >
        </mat-card-title>
      </div>
      <div class="owner-event-component__infos__reservation__date">
        <mat-card-title
          >Date de début :
          {{
            reservationEvent?.reservation?.start | formatDate
          }}</mat-card-title
        >
        <mat-card-title
          >Date de fin :
          {{ reservationEvent?.reservation?.end | formatDate }}</mat-card-title
        >
        <mat-card-title
          *ngIf="reservationEvent?.reservation?.reservationUrl"
          class="owner-event-component__infos__reservation__link"
        >
          <a
            mat-button
            [href]="reservationEvent?.reservation?.reservationUrl"
            target="_blank"
          >
            <mat-icon class="icon" aria-hidden="false">forward</mat-icon>
            Lien vers la réservation</a
          >
        </mat-card-title>
      </div>
    </mat-card-header>

    <h2
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__housework-title --hidden'
          : 'owner-event-component__infos__housework-title'
      "
    >
      Ménage
    </h2>
    <mat-card-header
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__housework --hidden'
          : 'owner-event-component__infos__housework'
      "
    >
      <div class="owner-event-component__infos__housework__details">
        <mat-card-title
          >Statut :
          <span
            *ngIf="houseworkEvent?.housework?.summary === 'Nouveau'"
            [class]="
              'owner-event-component__infos__housework__details__status --new'
            "
            >{{ houseworkEvent?.housework?.summary }}</span
          >
          <span
            *ngIf="houseworkEvent?.housework?.summary === 'Programmé'"
            [class]="
              'owner-event-component__infos__housework__details__status --programed'
            "
            >{{ houseworkEvent?.housework?.summary }}</span
          >
          <span
            *ngIf="houseworkEvent?.housework?.summary === 'Annulé'"
            [class]="
              'owner-event-component__infos__housework__details__status --canceled'
            "
            >{{ houseworkEvent?.housework?.summary }}</span
          >
        </mat-card-title>
      </div>
      <div class="owner-event-component__infos__housework__date">
        <mat-card-title
          >Date d'intervention :
          {{
            houseworkEvent?.housework?.eventDate | formatDate
          }}</mat-card-title
        >
      </div>
    </mat-card-header>

    <mat-card-header
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__cleaner --hidden'
          : 'owner-event-component__infos__cleaner'
      "
    >
      <img
        mat-card-avatar
        [src]="cleanerArchive?.filePath"
        (error)="userFileService.handleMissingImage(cleanerArchive!)"
        class="owner-event-component__infos__cleaner__avatar"
        alt="Photo de l'agent d'entretien"
      />
      <div class="owner-event-component__infos__cleaner__display">
        <mat-card-title
          class="owner-event-component__infos__cleaner__display__title"
          ><span
            class="owner-event-component__infos__cleaner__display__title__label"
            >Agent d'entretien</span
          >
          <button
            *ngIf="houseworkEvent?.housework?.cleaner"
            class="owner-event-component__infos__cleaner__display__title__delete"
            mat-raised-button
            (click)="unlinkCleanerToMission()"
          >
            Supprimer
          </button>
          <button
            *ngIf="!houseworkEvent?.housework?.cleaner && eventSelected"
            class="owner-event-component__infos__cleaner__display__title__add"
            mat-raised-button
            (click)="showLodgingCleanerList()"
          >
            Ajouter
          </button>
        </mat-card-title>
        <div
          *ngIf="houseworkEvent?.housework?.cleaner"
          class="owner-event-component__infos__cleaner__display__is-worker"
        >
          <div
            class="owner-event-component__infos__cleaner__display__is-worker__details"
          >
            <div
              class="owner-event-component__infos__cleaner__display__is-worker__details__label"
            >
              <mat-card-title>
                {{ houseworkEvent?.housework?.cleaner?.lastname }}
                {{
                  houseworkEvent?.housework?.cleaner?.firstname
                }}</mat-card-title
              >
            </div>
            <div
              class="owner-event-component__infos__cleaner__display__is-worker__details__phone-number"
            >
              <mat-card-subtitle>
                {{
                  houseworkEvent?.housework?.cleaner?.phoneNumber
                }}</mat-card-subtitle
              >
            </div>
          </div>
        </div>
        <mat-card-title
          *ngIf="!houseworkEvent?.housework?.cleaner && eventSelected"
          class="owner-event-component__infos__cleaner__display__no-worker"
        >
          <div>Aucun agent d'entretien n'est attribué à cette mission</div>
        </mat-card-title>
      </div>
    </mat-card-header>

    <h2
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__check-title --hidden'
          : 'owner-event-component__infos__check-title'
      "
    >
      Réception
    </h2>
    <mat-card-header
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__check --hidden'
          : 'owner-event-component__infos__check'
      "
    >
      <div class="owner-event-component__infos__check__details">
        <mat-card-title
          >Statut :
          <span
            *ngIf="checkEvent?.check?.summary === 'Nouveau'"
            [class]="
              'owner-event-component__infos__check__details__status --new'
            "
            >{{ checkEvent?.check?.summary }}</span
          >
          <span
            *ngIf="checkEvent?.check?.summary === 'Programmé'"
            [class]="
              'owner-event-component__infos__check__details__status --programed'
            "
            >{{ checkEvent?.check?.summary }}</span
          >
          <span
            *ngIf="checkEvent?.check?.summary === 'Annulé'"
            [class]="
              'owner-event-component__infos__check__details__status --canceled'
            "
            >{{ checkEvent?.check?.summary }}</span
          >
        </mat-card-title>
      </div>
      <div class="owner-event-component__infos__check__date">
        <mat-card-title
          >Check-in : {{ checkEvent?.check?.in | formatDate }}</mat-card-title
        >
        <mat-card-title
          >Check-out : {{ checkEvent?.check?.out | formatDate }}</mat-card-title
        >
      </div>
    </mat-card-header>

    <mat-card-header
      [class]="
        reservationEvent?.status === 'Vérrouillé'
          ? 'owner-event-component__infos__welcomer --hidden'
          : 'owner-event-component__infos__welcomer'
      "
    >
      <img
        mat-card-avatar
        [src]="welcomerArchive?.filePath"
        (error)="userFileService.handleMissingImage(welcomerArchive!)"
        class="owner-event-component__infos__welcomer__avatar"
        alt="Photo de l'agent d'entretien"
      />
      <div class="owner-event-component__infos__welcomer__display">
        <mat-card-title
          class="owner-event-component__infos__welcomer__display__title"
          ><span
            class="owner-event-component__infos__welcomer__display__title__label"
          >
            Welcomer
          </span>
          <button
            *ngIf="checkEvent?.check?.welcomer"
            class="owner-event-component__infos__welcomer__display__title__delete"
            mat-raised-button
            (click)="unlinkWelcomerToMission()"
          >
            Supprimer
          </button>
          <button
            *ngIf="!checkEvent?.check?.welcomer && eventSelected"
            class="owner-event-component__infos__welcomer__display__title__add"
            mat-raised-button
            (click)="showLodgingCleanerList()"
          >
            Ajouter
          </button>
        </mat-card-title>
        <div
          *ngIf="checkEvent?.check?.welcomer"
          class="owner-event-component__infos__welcomer__display__is-worker"
        >
          <div
            class="owner-event-component__infos__welcomer__display__is-worker__details"
          >
            <mat-card-title
              class="owner-event-component__infos__welcomer__display__is-worker__details__label"
            >
              {{ checkEvent?.check?.welcomer?.lastname }}
              {{ checkEvent?.check?.welcomer?.firstname }}</mat-card-title
            >
            <mat-card-subtitle
              class="owner-event-component__infos__welcomer__display__is-worker__details__phone-number"
            >
              {{ checkEvent?.check?.welcomer?.phoneNumber }}</mat-card-subtitle
            >
          </div>
        </div>
        <mat-card-title
          *ngIf="!checkEvent?.check?.welcomer && eventSelected"
          class="owner-event-component__infos__welcomer__display__no-worker"
        >
          <div>Aucun welcomer n'est attribué à cette mission</div>
        </mat-card-title>
      </div>
    </mat-card-header>
  </mat-card>
</ng-container>
