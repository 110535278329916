import { FormGroup } from '@angular/forms';

export function MatchScanner(
  controlString: string,
  matchingControlString: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlString];
    const matchingControl = formGroup.controls[matchingControlString];

    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
