import { AuthenticationService } from '../../services/authentication/authentication.service';
import { SignalRHubService } from '../../services/signalR-hub/signalR-hub.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from '../../services/calendar/calendar.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavbarService } from '../../services/navbar/navbar.service';
import { routeEndPoint } from 'src/environments/environment.routes';
import { MatSidenav } from '@angular/material/sidenav';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  @ViewChild('drawer') private sideNavbar!: MatSidenav;
  public welcomerRole: string = 'Welcomer';
  public cleanerRole: string = 'Cleaner';
  public ownerRole: string = 'Owner';
  public loading!: boolean;

  constructor(
    public authenticationService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private signalRHubService: SignalRHubService,
    private calendarService: CalendarService,
    private navbarService: NavbarService,
    private router: Router
  ) {
    this.navbarService.getShowButtonsState().subscribe({
      next: (loading: boolean) => {
        this.loading = loading;
      },
    });
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public openCalendar(): void {
    this.router.navigate([
      `./${this.authenticationService.currentAuthenticationValue?.role?.label?.toLocaleLowerCase()}`,
    ]);
    this.toggleSideNavbar();
  }

  public openUserInformation(): void {
    this.router.navigate([routeEndPoint.user], {
      queryParams: this.authenticationService.currentAuthenticationValue,
    });
  }

  public openChat(): void {
    this.router.navigate([routeEndPoint.chat]);
    this.toggleSideNavbar();
  }

  public openMission(): void {
    this.router.navigate([routeEndPoint.mission]);
    this.toggleSideNavbar();
  }

  public openLodgingList(): void {
    this.router.navigate([routeEndPoint.lodgingList]);
    this.toggleSideNavbar();
  }

  public openCompany(): void {
    this.router.navigate([routeEndPoint.companyList]);
    this.toggleSideNavbar();
  }

  public openSearchPage(): void {
    this.router.navigate([routeEndPoint.search]);
    this.toggleSideNavbar();
  }

  public logout(): void {
    this.signalRHubService.stopConnection();
    this.calendarService.clearCalendar();
    this.authenticationService.logout();
    this.toggleSideNavbar();
  }

  private toggleSideNavbar() {
    if (this.width < 1024) {
      this.sideNavbar.close();
    } else {
      this.sideNavbar.opened;
    }
  }
}
