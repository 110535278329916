<div
  [class]="width < 1330 ? 'register-component --mobile' : 'register-component'"
>
  <div
    [class]="
      width < 1330
        ? 'register-component__picture --mobile'
        : 'register-component__picture'
    "
  >
    <img
      mat-card-avatar
      class="register-component__picture__image"
      [src]="userArchive!.filePath"
      (error)="userFileService.handleMissingImage(userArchive)"
      alt="Photo de profil"
    />
    <div class="register-component__picture__button">
      <div>
        <button
          mat-flat-button
          class="register-component__picture__button__select"
          color="primary"
        >
          <input
            #selector
            type="file"
            id="select"
            class="register-component__picture__button__select__input"
            (change)="selectFile($event)"
          />
          <label
            class="register-component__picture__button__select__label"
            for="select"
            >Choisir une image</label
          >
        </button>
      </div>
    </div>
  </div>

  <form
    [formGroup]="registerForm"
    [class]="
      width < 1330
        ? 'register-component__form --mobile'
        : 'register-component__form'
    "
  >
    <div
      [class]="
        width < 1330
          ? 'register-component__form__activity-type --mobile'
          : 'register-component__form__activity-type'
      "
    >
      <div
        [class]="
          width < 1330
            ? 'register-component__form__activity-type__radio-container --mobile'
            : 'register-component__form__activity-type__radio-container'
        "
      >
        <h2
          [class]="
            width < 1330
              ? 'register-component__form__activity-type__radio-container__label --mobile'
              : 'register-component__form__activity-type__radio-container__label'
          "
        >
          Type d'activité :
        </h2>
        <mat-radio-group
          [class]="
            width < 1330
              ? 'register-component__form__activity-type__radio-container__radio-group --mobile'
              : 'register-component__form__activity-type__radio-container__radio-group'
          "
          formControlName="roleLabel"
        >
          <mat-radio-button
            [class]="
              width < 1330
                ? 'register-component__form__activity-type__radio-container__radio-group__radio --mobile'
                : 'register-component__form__activity-type__radio-container__radio-group__radio'
            "
            value="Cleaner"
            >Ménage</mat-radio-button
          >
          <mat-radio-button
            [class]="
              width < 1330
                ? 'register-component__form__activity-type__radio-container__radio-group__radio --mobile'
                : 'register-component__form__activity-type__radio-container__radio-group__radio'
            "
            value="Owner"
            >Hébergement</mat-radio-button
          >
          <mat-radio-button
            [class]="
              width < 1330
                ? 'register-component__form__activity-type__radio-container__radio-group__radio --mobile'
                : 'register-component__form__activity-type__radio-container__radio-group__radio'
            "
            value="Welcomer"
            >Accueil</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>

    <div class="register-component__form__container">
      <div
        [class]="
          width < 1330
            ? 'register-component__form__container__user-address --mobile'
            : 'register-component__form__container__user-address'
        "
      >
        <div
          class="register-component__form__container__user-address__user-fields"
        >
          <div
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__radio --mobile'
                : 'register-component__form__container__user-address__user-fields__radio'
            "
          >
            <mat-radio-group
              class="register-component__form__container__user-address__user-fields__radio__gender"
              formControlName="gender"
            >
              <mat-radio-button
                class="register-component__form__container__user-address__user-fields__radio__gender__female"
                value="1"
                >Mme</mat-radio-button
              >
              <mat-radio-button
                class="register-component__form__container__user-address__user-fields__radio__gender__male"
                value="0"
                >M.</mat-radio-button
              >
            </mat-radio-group>
          </div>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__register-input --mobile'
                : 'register-component__form__container__user-address__user-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Nom</mat-label>
            <input
              matInput
              class="register-firstname"
              type="text"
              formControlName="firstname"
              class="form-control"
              placeholder="Dupont..."
              [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }"
            />
            <div
              *ngIf="submitted && f['firstname'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['firstname'].errors['required']">
                Le nom est requis
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__register-input --mobile'
                : 'register-component__form__container__user-address__user-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Prénom</mat-label>
            <input
              matInput
              class="register-lastname"
              type="text"
              formControlName="lastname"
              class="form-control"
              placeholder="Jean..."
              [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }"
            />
            <div
              *ngIf="submitted && f['lastname'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['lastname'].errors['required']">
                Le prénom est requis
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__register-input --mobile'
                : 'register-component__form__container__user-address__user-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Date de naissance</mat-label>
            <input
              matInput
              class="register-birthDay"
              type="date"
              formControlName="birthDay"
              class="form-control"
              placeholder="01/01/1980..."
              [ngClass]="{ 'is-invalid': submitted && f['birthDay'].errors }"
            />
            <div
              *ngIf="submitted && f['birthDay'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['birthDay'].errors['required']">
                La date de naissance est requise
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__register-input --mobile'
                : 'register-component__form__container__user-address__user-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Numéro de portable</mat-label>
            <input
              matInput
              class="register-phonenumber"
              type="number"
              formControlName="phoneNumber"
              class="form-control"
              placeholder="0601020304..."
              [ngClass]="{ 'is-invalid': submitted && f['phoneNumber'].errors }"
            />
            <div
              *ngIf="submitted && f['phoneNumber'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['phoneNumber'].errors['required']">
                La numéro de mobile est requis
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__user-fields__register-input --mobile'
                : 'register-component__form__container__user-address__user-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>E-mail</mat-label>
            <input
              matInput
              class="register-email"
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="e-mail@mail.com..."
              [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
            />
            <div
              *ngIf="submitted && f['email'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['email'].errors['required']">
                L'adresse mail est requise
              </div>
              <div *ngIf="f['email'].errors['pattern']">
                L'adresse mail n'est pas au bon format
              </div>
            </div>
          </mat-form-field>
        </div>

        <div
          class="register-component__form__container__user-address__address-fields"
        >
          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__address-fields__register-input --mobile'
                : 'register-component__form__container__user-address__address-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Adresse</mat-label>
            <input
              matInput
              class="address-label"
              type="text"
              formControlName="addressLabel"
              class="form-control"
              placeholder="1 rue du Capitol..."
              [ngClass]="{
                'is-invalid': submitted && f['addressLabel'].errors
              }"
            />
            <div
              *ngIf="submitted && f['addressLabel'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['addressLabel'].errors['required']">
                L'adresse est requise
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__address-fields__register-input --mobile'
                : 'register-component__form__container__user-address__address-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Code postal</mat-label>
            <input
              matInput
              class="postal-code"
              type="text"
              formControlName="postalCode"
              class="form-control"
              placeholder="69000..."
              [ngClass]="{ 'is-invalid': submitted && f['postalCode'].errors }"
            />
            <div
              *ngIf="submitted && f['postalCode'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['postalCode'].errors['required']">
                Le code postal est requis
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__address-fields__register-input --mobile'
                : 'register-component__form__container__user-address__address-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Ville</mat-label>
            <input
              matInput
              class="city"
              type="text"
              formControlName="city"
              class="form-control"
              placeholder="Lyon..."
              [ngClass]="{ 'is-invalid': submitted && f['city'].errors }"
            />
            <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
              <div *ngIf="f['city'].errors['required']">
                La ville est requise
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__address-fields__register-input --mobile'
                : 'register-component__form__container__user-address__address-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Région</mat-label>
            <input
              matInput
              class="region"
              type="text"
              formControlName="region"
              class="form-control"
              placeholder="Rhône-Alpes..."
              [ngClass]="{ 'is-invalid': submitted && f['region'].errors }"
            />
            <div
              *ngIf="submitted && f['region'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['region'].errors['required']">
                La région est requise
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            [class]="
              width < 1330
                ? 'register-component__form__container__user-address__address-fields__register-input --mobile'
                : 'register-component__form__container__user-address__address-fields__register-input'
            "
            appearance="fill"
          >
            <mat-label>Pays</mat-label>
            <input
              matInput
              class="country"
              type="text"
              formControlName="country"
              class="form-control"
              placeholder="France..."
              [ngClass]="{ 'is-invalid': submitted && f['country'].errors }"
            />
            <div
              *ngIf="submitted && f['country'].errors"
              class="invalid-feedback"
            >
              <div *ngIf="f['country'].errors['required']">
                Le pays est requis
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div
        [class]="
          width < 1330
            ? 'register-component__form__container__password-fields --mobile'
            : 'register-component__form__container__password-fields'
        "
      >
        <mat-form-field
          [class]="
            width < 1330
              ? 'register-component__form__container__password-fields__password-input --mobile'
              : 'register-component__form__container__password-fields__password-input'
          "
          appearance="fill"
        >
          <mat-label>Mot de passe</mat-label>
          <input
            matInput
            class="register-password"
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="Saisir le mot de passe..."
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          />
          <div
            *ngIf="submitted && f['password'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['password'].errors['required']">
              Le mot de passe est requis
            </div>
            <div *ngIf="f['password'].errors['minlength']">
              Le mot de passe doit contenir au moins 6 caractères
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          [class]="
            width < 1330
              ? 'register-component__form__container__password-fields__confirm-input --mobile'
              : 'register-component__form__container__password-fields__confirm-input'
          "
          appearance="fill"
        >
          <mat-label>Confirmez le mot de passe</mat-label>
          <input
            matInput
            class="register-confirm"
            type="password"
            formControlName="confirm"
            class="form-control"
            placeholder="Confirmez le mot de passe..."
            [ngClass]="{ 'is-invalid': submitted && f['confirm'].errors }"
          />
          <div
            *ngIf="submitted && f['confirm'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['confirm'].errors['required']">
              Veuillez confirmer le mot de passe
            </div>
            <div *ngIf="f['confirm'].errors['mustMatch']">
              Veuillez confirmer le mot de passe
            </div>
          </div>
        </mat-form-field>
      </div>

      <a class="register-component__form__container__button">
        <button
          [class]="
            width < 1330
              ? 'register-component__form__container__button__create-button --mobile'
              : 'register-component__form__container__button__create-button'
          "
          mat-raised-button
          color="accent"
          (click)="onSubmit()"
        >
          Créer
        </button>
      </a>

      <a class="register-component__form__container__button">
        <button
          [class]="
            width < 1330
              ? 'register-component__form__container__button__cancel-button --mobile'
              : 'register-component__form__container__button__cancel-button'
          "
          mat-raised-button
          color="accent"
          (click)="cancelRegistration()"
        >
          Annuler
        </button>
      </a>
    </div>
  </form>
</div>
