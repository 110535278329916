import { AlertMessageType } from '../enum/alert-message.type';

export class AlertMessage {
  public content?: string;
  public type?: AlertMessageType;

  constructor(content?: string, type?: AlertMessageType) {
    this.content = content;
    this.type = type;
  }

  static [Symbol.hasInstance](instance: any) {
    return (
      typeof instance.content === 'string' && typeof instance.type === 'number'
    );
  }
}
