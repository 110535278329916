import { EntityBase } from '../base/entitybase';
import { Gender } from '../../enum/gender';
import { Address } from './address';
import { Role } from './role';
import { CalendarFilters } from './calendar-filters';

export class User extends EntityBase {
  public firstname?: string;
  public lastname?: string;
  public birthDay?: Date;
  public phoneNumber?;
  public email?: string;
  public address?: Address;
  public gender?: Gender;
  public avatar?: string;
  public role?: Role;
  public password?: string;
  public calendarFilters?: CalendarFilters;

  constructor(
    firstname: string,
    lastname: string,
    birthday: Date,
    phonenumber: string,
    email: string,
    gender?: Gender,
    avatar?: string,
    address?: Address,
    role?: Role,
    password?: string
  ) {
    super();
    this.firstname = firstname;
    this.lastname = lastname;
    this.birthDay = birthday;
    this.phoneNumber = phonenumber;
    this.email = email;
    this.gender = gender;
    this.avatar = avatar;
    this.address = address;
    this.role = role;
    this.password = password;
  }
}
