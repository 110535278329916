import { refreshCalendarParameters } from 'src/app/core/models/symbiosis-front-app/refresh-calendar';
import { FullCalendarEventBuilder } from '../../builder/full-calendar-event-builder';
import { HttpClientService } from '../../services/httpclient/httpclient.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { CalendarFilters } from 'src/app/core/models/symbiosis/calendar-filters';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { HttpCallType } from '../../services/httpclient/enum/httpCallType';
import { SpinnerService } from '../../components/spinner/spinner.service';
import { contentType } from 'src/environments/environment.content.types';
import { Reservation } from 'src/app/core/models/symbiosis/reservation';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { Housework } from 'src/app/core/models/symbiosis/housework';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { UserTarget } from '../../builder/enum/user.target ';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Check } from 'src/app/core/models/symbiosis/check';
import { MatSelectChange } from '@angular/material/select';
import { HttpErrorResponse } from '@angular/common/http';
import { CalendarOptions } from '@fullcalendar/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  public fullCalendarEventListSubject: BehaviorSubject<Array<FullCalendarEvent> | null>;
  public refreshCalendarParameters: refreshCalendarParameters =
    new refreshCalendarParameters('', '', 0, UserTarget.none);
  public calendar: Observable<CalendarOptions | null>;
  public calendarEventList: Array<FullCalendarEvent> =
    new Array<FullCalendarEvent>();
  public filteredEventList: Array<FullCalendarEvent> =
    new Array<FullCalendarEvent>();
  private calendarSubject: BehaviorSubject<CalendarOptions>;
  public lodgings: Array<Lodging> = new Array<Lodging>();

  public isMobileSubject?: Subject<boolean>;
  public calendarOptions!: CalendarOptions;
  public uniqueReference: Subject<string>;
  public selectedOption?: MatSelectChange;
  public subscribeOneTime: number = 0;
  public selectedLodging?: Lodging;

  public calendarFiltersSubject: BehaviorSubject<CalendarFilters> =
    new BehaviorSubject<CalendarFilters>(
      this.authenticationService.currentAuthenticationValue?.user?.calendarFilters!
    );

  public calendarFilters?: CalendarFilters = new CalendarFilters();

  constructor(
    public authenticationService: AuthenticationService,
    private eventBuilder: FullCalendarEventBuilder,
    private httpClientService: HttpClientService,
    private spinnerService: SpinnerService
  ) {
    this.fullCalendarEventListSubject =
      new BehaviorSubject<Array<FullCalendarEvent> | null>(null);

    this.uniqueReference = this.setCalendarOptions();

    this.calendarSubject = new BehaviorSubject<CalendarOptions>(
      this.calendarOptions
    );
    this.calendar = this.calendarSubject.asObservable();

    this.calendarFiltersSubject.subscribe(
      (calendarFilters) => (this.calendarFilters! = calendarFilters)
    );
  }

  public get calendarSubjectValue(): CalendarOptions {
    return this.calendarSubject.value;
  }

  public refreshCalendar(
    refreshCalendarParameters: refreshCalendarParameters
  ): void {
    this.spinnerService.requestStarted();
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          refreshCalendarParameters.baseUrl,
          refreshCalendarParameters.endPoint,
          refreshCalendarParameters.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(refreshCalendarParameters.id)
      )
      .subscribe({
        next: (eventList: Array<Reservation | Housework | Check>) => {
          this.calendarEventList =
            this.eventBuilder.buildFullCalendarListFromReservationList(
              eventList,
              refreshCalendarParameters.userTarget
            );

          this.fullCalendarEventListSubject.next(this.calendarEventList);

          if (this.selectedLodging) {
            this.filterEventListByLodging(this.selectedOption);
          }

          this.spinnerService.requestEnded();
        },
        error: (error: HttpErrorResponse) => {
          this.spinnerService.requestEnded();
          console.error(error);
        },
      });
  }

  public clearCalendar() {
    this.calendarOptions.events = new Array<FullCalendarEvent>();
  }

  public filterEventListByLodging(option?: MatSelectChange): void {
    this.selectedOption = option;
    if (option?.value === this.calendarEventList) {
      this.selectedLodging = undefined;
      this.calendarOptions.events = this.calendarEventList;
      this.eventBuilder.fullCalendarEventList.next(this.calendarEventList);
    } else {
      this.selectedLodging = option?.value as Lodging;
      let filteredEventList = new Array<FullCalendarEvent>();
      this.calendarEventList.forEach((event) => {
        if (event.title?.includes(option?.value.label)) {
          filteredEventList.push(event);
        }
      });
      this.eventBuilder.fullCalendarEventList.next(filteredEventList);
    }
  }

  public showEventsOnCalendar(): void {
    this.eventBuilder
      .getCurrentFullCalendarEventList()
      .subscribe((fullCalendarEventList: Array<FullCalendarEvent>) => {
        this.filteredEventList = new Array<FullCalendarEvent>();
        this.calendarOptions.events = [];

        if (this.calendarFilters?.showReservationList) {
          fullCalendarEventList.forEach((fullCalEvent) => {
            if (fullCalEvent.title?.includes('Loué')) {
              this.filteredEventList.push(fullCalEvent);
            }
          });
        } else {
          this.filteredEventList = this.filteredEventList.filter(
            (fullCalEvent, i) => !fullCalEvent.title?.includes('Loué')
          );
        }

        if (this.calendarFilters?.showHouseworkList) {
          fullCalendarEventList.forEach((fullCalEvent) => {
            if (fullCalEvent.title?.includes('MENAGE')) {
              this.filteredEventList.push(fullCalEvent);
            }
          });
        } else {
          this.filteredEventList = this.filteredEventList.filter(
            (fullCalEvent, i) => !fullCalEvent.title?.includes('MENAGE')
          );
        }

        if (this.calendarFilters?.showCheckList) {
          fullCalendarEventList.forEach((fullCalEvent) => {
            if (fullCalEvent.title?.includes('Check')) {
              this.filteredEventList.push(fullCalEvent);
            }
          });
        } else {
          this.filteredEventList = this.filteredEventList.filter(
            (fullCalEvent, i) => !fullCalEvent.title?.includes('Check')
          );
        }

        if (this.calendarFilters?.showCanceledList) {
          fullCalendarEventList.forEach((fullCalEvent) => {
            if (fullCalEvent.title?.includes('Annulé')) {
              this.filteredEventList.push(fullCalEvent);
            }
          });
        } else {
          this.filteredEventList = this.filteredEventList.filter(
            (fullCalEvent, i) => !fullCalEvent.title?.includes('Annulé')
          );
        }

        if (this.calendarFilters?.showNotAvailableList) {
          fullCalendarEventList.forEach((fullCalEvent) => {
            if (fullCalEvent.title?.includes('Vérrouillé')) {
              this.filteredEventList.push(fullCalEvent);
            }
          });
        } else {
          this.filteredEventList = this.filteredEventList.filter(
            (fullCalEvent, i) => !fullCalEvent.title?.includes('Vérrouillé')
          );
        }

        this.calendarOptions.events = this.filteredEventList;
      });
  }

  public filterReservationList(): void {
    this.calendarFilters!.showReservationList = this.setShowEventList(
      this.calendarFilters?.showReservationList!
    );
    this.calendarFiltersSubject.next(this.calendarFilters!);
    this.filterEventList();
    this.saveCalendarFiltersChanges();
  }

  public filterHouseworkList(): void {
    this.calendarFilters!.showHouseworkList = this.setShowEventList(
      this.calendarFilters?.showHouseworkList!
    );
    this.calendarFiltersSubject.next(this.calendarFilters!);
    this.filterEventList();
    this.saveCalendarFiltersChanges();
  }

  public filterCheckList(): void {
    this.calendarFilters!.showCheckList = this.setShowEventList(
      this.calendarFilters?.showCheckList!
    );
    this.calendarFiltersSubject.next(this.calendarFilters!);
    this.filterEventList();
    this.saveCalendarFiltersChanges();
  }

  public filterCanceledList(): void {
    this.calendarFilters!.showCanceledList = this.setShowEventList(
      this.calendarFilters?.showCanceledList!
    );
    this.calendarFiltersSubject.next(this.calendarFilters!);
    this.filterEventList();
    this.saveCalendarFiltersChanges();
  }

  public filterNotAvailableList(): void {
    this.calendarFilters!.showNotAvailableList = this.setShowEventList(
      this.calendarFilters?.showNotAvailableList!
    );
    this.calendarFiltersSubject.next(this.calendarFilters!);
    this.filterEventList();
    this.saveCalendarFiltersChanges();
  }

  private saveCalendarFiltersChanges() {
    this.authenticationService.currentAuthenticationValue!.user!.calendarFilters =
      this.calendarFilters;
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateUser,
          this.authenticationService.currentAuthenticationValue?.user?.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(
          this.authenticationService.currentAuthenticationValue
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  private setShowEventList(show: boolean): boolean {
    if (show) {
      return false;
    } else {
      return true;
    }
  }

  private filterEventList() {
    if (
      this.selectedOption?.value === undefined ||
      this.selectedOption?.value.length > 1
    ) {
      this.eventBuilder.fullCalendarEventList.next(this.calendarEventList!);
    } else {
      let filteredEventList = new Array<FullCalendarEvent>();
      this.calendarEventList?.forEach((event) => {
        if (event.title?.includes(this.selectedOption?.value.label)) {
          filteredEventList.push(event);
        }
      });
      this.eventBuilder.fullCalendarEventList.next(filteredEventList);
    }
  }

  private setCalendarOptions(): Subject<string> {
    let uniqueReferenceSubject: Subject<string> = new Subject<string>();
    let isMobileSubject: Subject<boolean> = new Subject<boolean>();
    this.isMobileSubject = isMobileSubject;

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locale: 'ISO',
      firstDay: 1,
      eventDisplay: 'block',
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridDay,dayGridWeek,dayGridMonth,listWeek',
      },
      buttonText: {
        today: `Aujourd'hui`,
        day: `Jour`,
        week: `Semaine`,
        month: `Mois`,
        list: 'Liste évènements',
      },
      displayEventTime: false,
      eventClick: function (info) {
        info.jsEvent.preventDefault();

        if (
          info.event._def.extendedProps['reservation'] &&
          info.event._def.extendedProps['reservation']['uniqueReference']
        ) {
          uniqueReferenceSubject.next(
            info.event._def.extendedProps['reservation']['uniqueReference']
          );
        } else if (
          info.event._def.extendedProps['housework'] &&
          info.event._def.extendedProps['housework']['uniqueReference']
        ) {
          uniqueReferenceSubject.next(
            info.event._def.extendedProps['housework']['uniqueReference']
          );
        } else if (
          info.event._def.extendedProps['check'] &&
          info.event._def.extendedProps['check']['uniqueReference']
        ) {
          uniqueReferenceSubject.next(
            info.event._def.extendedProps['check']['uniqueReference']
          );
        }

        if (window.innerWidth < 1149) {
          isMobileSubject.next(true);
        } else {
          isMobileSubject.next(false);
        }
      },
    };

    return uniqueReferenceSubject;
  }
}
