<ng-container class="import-calendar-component">
  <h1 mat-dialog-title>Importer un calendrier</h1>
  <div mat-dialog-content class="import-calendar-component__content">
    <p>
      Importez un calendrier que vous utilisez et nous actualiserons
      automatiquement la disponibilité de ce logement.
    </p>
    <form
      [formGroup]="iCalForm"
      (keyup.enter)="onSubmit()"
      class="import-calendar-component__content__ical-form"
    >
      <mat-form-field
        class="import-calendar-component__content__ical-form__ical-input"
        appearance="fill"
      >
        <mat-label>Adresse du calendrier (URL)</mat-label>
        <input
          matInput
          class="import-calendar-component__content__ical-form__ical-input__ical-url"
          type="text"
          formControlName="iCalUrl"
          class="form-control"
          placeholder="https://www.airbnb.fr/calendar/ical/..."
          [ngClass]="{ 'is-invalid': submitted && f['iCalUrl'].errors }"
        />
        <div *ngIf="submitted && f['iCalUrl'].errors" class="invalid-feedback">
          <div *ngIf="f['iCalUrl'].errors['required']">L'url est requise</div>
        </div>
      </mat-form-field>
      <div
        mat-dialog-actions
        class="import-calendar-component__content__ical-form__buttons"
      >
        <button
          mat-button
          class="import-calendar-component__content__ical-form__buttons__cancel-button"
          (click)="onClose()"
        >
          Annuler
        </button>
        <button
          mat-raised-button
          class="import-calendar-component__content__ical-form__buttons__validate-button"
          (click)="onSubmit()"
        >
          <mat-icon>check_circle</mat-icon>
          Valider
        </button>
      </div>
    </form>
  </div>
</ng-container>
