<ng-container class="chat-component">
  <div
    [class]="
      width < 769
        ? 'chat-component__container --mobile'
        : 'chat-component__container'
    "
  >
    <div
      [class]="
        width < 769
          ? 'chat-component__container__user-container --mobile'
          : 'chat-component__container__user-container'
      "
    >
      <div
        *ngIf="width > 768"
        class="chat-component__container__user-container__user-profile"
      >
        <div
          class="chat-component__container__user-container__user-profile__user-pic"
        >
          <mat-icon
            mat-list-icon
            class="chat-component__container__user-container__user-profile__user-pic__user-icon"
            color="primary"
            >person</mat-icon
          >
        </div>
        <div
          class="chat-component__container__user-container__user-profile__user-name"
        >
          {{ user.firstname }} {{ user.lastname }}
        </div>
      </div>

      <mat-divider *ngIf="width >= 1024"></mat-divider>

      <h2>Liste des conversations</h2>

      <!-- Affichage pc -->
      <div
        *ngIf="width > 768"
        class="chat-component__container__user-container__user-list"
      >
        <mat-list>
          <mat-list-item
            [class]="
              authenticationService.currentAuthenticationValue?.role?.label ===
              ownerRole
                ? 'chat-component__container__user-container__user-list__item --owner'
                : 'chat-component__container__user-container__user-list__item'
            "
            *ngFor="let chat of chatList"
            (click)="openDialog(chat)"
          >
            <div
              class="chat-component__container__user-container__user-list__item__contact"
            >
              <div
                class="chat-component__container__user-container__user-list__item__contact__contact-item"
              >
                <mat-icon
                  mat-list-icon
                  class="chat-component__container__user-container__user-list__item__contact__contact-item__contact-icon"
                  color="primary"
                  >account_circle</mat-icon
                >
                <div
                  mat-line
                  class="chat-component__container__user-container__user-list__item__contact__contact-item__contact-name"
                  *ngIf="
                    chat.firstContact.id ===
                    authenticationService.currentAuthenticationValue?.user?.id
                  "
                >
                  {{ chat.secondContact.lastname }}
                  {{ chat.secondContact.firstname }}
                </div>
                <div
                  mat-line
                  class="chat-component__container__user-container__user-list__item__contact__contact-item__contact-name"
                  *ngIf="
                    chat.secondContact.id ===
                    authenticationService.currentAuthenticationValue?.user?.id
                  "
                >
                  {{ chat.firstContact.lastname }}
                  {{ chat.firstContact.firstname }}
                </div>
              </div>
              <button
                class="chat-component__container__user-container__user-list__item__contact__profile-button"
                mat-raised-button
                *ngIf="
                  authenticationService.currentAuthenticationValue?.role
                    ?.label === ownerRole
                "
                (click)="openProfile(chat)"
              >
                Profile
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- Affichage mobile/tablette -->
      <div
        *ngIf="width < 769"
        class="chat-component__container__user-container__user-list"
      >
        <mat-list>
          <mat-list-item
            class="chat-component__container__user-container__user-list__item --mobile"
            *ngFor="let chat of chatList"
            (click)="openDialog(chat)"
          >
            <mat-icon
              mat-list-icon
              class="chat-component__container__user-container__user-list__item__contact-icon"
              color="primary"
              >account_circle</mat-icon
            >
            <div
              mat-line
              class="chat-component__container__user-container__user-list__item__contact-name"
              *ngIf="
                chat.firstContact.id ===
                authenticationService.currentAuthenticationValue?.user?.id
              "
            >
              {{ chat.secondContact.lastname }}
              {{ chat.secondContact.firstname }}
            </div>
            <div
              mat-line
              class="chat-component__container__user-container__user-list__item__contact-name"
              *ngIf="
                chat.secondContact.id ===
                authenticationService.currentAuthenticationValue?.user?.id
              "
            >
              {{ chat.firstContact.lastname }} {{ chat.firstContact.firstname }}
            </div>
            <button
              mat-raised-button
              *ngIf="
                authenticationService.currentAuthenticationValue?.role
                  ?.label === ownerRole
              "
              (click)="openProfile(chat)"
            >
              Profile
            </button>

            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div
      [class]="
        width < 769
          ? 'chat-component__container__message-container --mobile'
          : 'chat-component__container__message-container'
      "
    >
      <div
        class="chat-component__container__message-container__no-chat"
        *ngIf="
          authenticationService.currentAuthenticationValue?.role?.label ===
            'Owner' &&
          (chatList?.length === 0 ||
            chatList === undefined ||
            chatList === null)
        "
      >
        Vous n'avez pas encore reçu de demande de collaboration. Pour se faire,
        il suffit de se rendre dans la rubrique "Logements", puis de créer un
        logement et d'activer la recherche d'agents d'entretien.
      </div>

      <div
        class="chat-component__container__message-container__no-chat"
        *ngIf="
          authenticationService.currentAuthenticationValue?.role?.label ===
            'Cleaner' &&
          (chatList?.length === 0 ||
            chatList === undefined ||
            chatList === null)
        "
      >
        Vous n'avez pas encore de fil de discussion. Pour se faire, il suffit de
        se rendre dans la rubrique "Recherche contrat", puis saisir le
        département souhaité afin d'executer une recherche, et prendre contact
        avec un hôte.
      </div>
      <app-dialog
        class="chat-component__container__message-container__dialog"
        *ngIf="submitted"
      ></app-dialog>
    </div>
  </div>
</ng-container>
