import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any, ...args: Array<unknown>): any {
    return super.transform(value, 'dd/MM/YYYY');
  }
}
