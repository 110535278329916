import { FormatDatePipe } from 'src/app/shared/services/date/format-date.pipe';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/core/models/symbiosis/user';

export interface GenderRadio {
  checked: boolean;
  value: number;
  name: string;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public radios: Array<GenderRadio>;
  public submitted: boolean = false;
  public editUserForm!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private formatDatePipe: FormatDatePipe,
    private formBuilder: FormBuilder
  ) {
    this.editUserForm = this.formBuilder.group({
      firstname: [user?.firstname ?? '', Validators.required],
      lastname: [user?.lastname ?? '', Validators.required],
      birthDay: [
        this.formatDatePipe.transform(user?.birthDay) ?? null,
        Validators.required,
      ],
      phoneNumber: [user?.phoneNumber ?? '', Validators.required],
      email: [
        user?.email ?? '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]),
      ],
      gender: [user!.gender ?? null, Validators.required],
      addressLabel: [user.address?.label ?? '', Validators.required],
      postalCode: [user.address?.postalCode ?? '', Validators.required],
      city: [user.address?.city ?? '', Validators.required],
      region: [user.address?.region ?? '', Validators.required],
      country: [user.address?.country ?? '', Validators.required],
    });
    this.radios = this.setRadios(user?.gender!);
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: { target: { innerWidth: number } }): void {
    event.target.innerWidth;
  }

  public get f() {
    return this.editUserForm.controls;
  }

  public get width(): number {
    return window.innerWidth;
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.editUserForm.invalid) {
      return;
    }

    this.dialogRef.close(this.editUserForm);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public setRadios(genderAttribute: number): Array<GenderRadio> {
    switch (genderAttribute) {
      case 0:
        return [
          { name: 'MMe', value: 1, checked: false },
          { name: 'M.', value: 0, checked: true },
        ];
      case 1:
        return [
          { name: 'MMe', value: 1, checked: true },
          { name: 'M.', value: 0, checked: false },
        ];
      default:
        return [
          { name: 'MMe', value: 1, checked: true },
          { name: 'M.', value: 0, checked: false },
        ];
    }
  }
}
