import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { WelcomerEventPopupComponent } from './welcomer-event-popup/welcomer-event-popup.component';
import { CleanerEventPopupComponent } from './cleaner-event-popup/cleaner-event-popup.component';
import { OwnerEventPopupComponent } from './owner-event-popup/owner-event-popup.component';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { CalendarService } from 'src/app/shared/services/calendar/calendar.service';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { defaultFilePath } from 'src/environments/environment.file.path';
import { FileService } from 'src/app/shared/services/file/file.service';
import { Archive } from 'src/app/core/models/archivum/archive';
import { EventPopupType } from '../enum/event-popup-type';
import { ImageType } from 'src/app/core/enum/image-type';
import { MatDialog } from '@angular/material/dialog';
import { ErrorObserver, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  private subList: Subscription = new Subscription();

  public lodgingArchive: Archive = new Archive(
    defaultFilePath.lodging_default_icon
  );
  public cleanerArchive: Archive = new Archive(
    defaultFilePath.user_default_icon
  );
  public welcomerArchive: Archive = new Archive(
    defaultFilePath.user_default_icon
  );

  public archiveList: Array<Archive> = new Array<Archive>();
  public reservationEvent?: FullCalendarEvent;
  public houseworkEvent?: FullCalendarEvent;
  public checkEvent?: FullCalendarEvent;
  public eventSelected: boolean = false;

  constructor(
    public authenticationService: AuthenticationService,
    public lodgingFileService: LodgingFileService,
    private calendarService: CalendarService,
    private fileService: FileService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setArchiveList();
    this.subscribeToCalendarEventClick();
    this.isMobileEventPopup();
  }

  ngOnDestroy() {
    this.calendarService.fullCalendarEventListSubject.next(null);
    this.calendarService.isMobileSubject?.next(false);
    this.calendarService.uniqueReference.next('');
    this.subList.unsubscribe();
  }

  private isMobileEventPopup(): void {
    if (
      this.authenticationService.currentAuthenticationValue?.role?.label ===
      'Owner'
    ) {
      this.openPopup(EventPopupType.ownerEventPopup);
    } else if (
      this.authenticationService.currentAuthenticationValue?.role?.label ===
      'Cleaner'
    ) {
      this.openPopup(EventPopupType.cleanerEventPopup);
    } else if (
      this.authenticationService.currentAuthenticationValue?.role?.label ===
      'Welcomer'
    ) {
      this.openPopup(EventPopupType.welcomerEventPopup);
    }
  }

  private openPopup(eventPopupType: EventPopupType) {
    this.subList.add(
      this.calendarService.isMobileSubject?.subscribe({
        next: (isMobile: boolean | null) => {
          if (isMobile) {
            this.openEventDetailsInDialog(eventPopupType);
          }
        },
        error: (error: Error) => {
          console.error(error);
        },
        complete: (): void => {},
      })
    );
  }

  private openEventDetailsInDialog(eventPopupType: EventPopupType): void {
    if (
      eventPopupType === EventPopupType.ownerEventPopup &&
      this.reservationEvent &&
      this.lodgingArchive
    ) {
      this.dialog.open(OwnerEventPopupComponent, {
        width: '400px',
        maxWidth: '90vw',
        data: {
          reservationEvent: this.reservationEvent,
          houseworkEvent: this.houseworkEvent,
          checkEvent: this.checkEvent,
          lodgingArchive: this.lodgingArchive,
          cleanerArchive: this.cleanerArchive,
          eventSelected: this.eventSelected,
          welcomerArchive: this.welcomerArchive,
        },
      });
    } else if (eventPopupType === EventPopupType.cleanerEventPopup) {
      this.dialog.open(CleanerEventPopupComponent, {
        width: '400px',
        maxWidth: '90vw',
        data: {
          houseworkEvent: this.houseworkEvent,
          lodgingArchive: this.lodgingArchive,
          eventSelected: this.eventSelected,
        },
      });
    } else if (eventPopupType === EventPopupType.welcomerEventPopup) {
      this.dialog.open(WelcomerEventPopupComponent, {
        width: '400px',
        maxWidth: '90vw',
        data: {
          lodgingArchive: this.lodgingArchive,
          eventSelected: this.eventSelected,
          checkEvent: this.checkEvent,
        },
      });
    }
  }

  private subscribeToCalendarEventClick(): void {
    this.subList.add(
      this.calendarService.uniqueReference.subscribe({
        next: (uniqueReference: any): void => {
          this.resetArchives();
          this.setReservationEvent(uniqueReference);
          this.setHouseworkEvent(uniqueReference);
          this.setCheckEvent(uniqueReference);
          this.setLodgingArchive();
          this.setCleanerArchive();
          this.setWelcomerArchive();
        },
        error: (error: ErrorObserver<string | null>): void => {
          console.error(error);
        },
        complete: (): void => {},
      })
    );
  }

  private setCleanerArchive(): void {
    if (this.archiveList) {
      for (let archive of this.archiveList) {
        if (
          this.houseworkEvent?.housework?.cleaner?.avatar ===
          archive.uniqueFileReference
        ) {
          this.cleanerArchive = archive;
          break;
        }
      }
    }
  }

  private setWelcomerArchive(): void {
    if (this.archiveList) {
      for (let archive of this.archiveList) {
        if (
          this.checkEvent?.check?.welcomer?.avatar ===
          archive.uniqueFileReference
        ) {
          this.welcomerArchive = archive;
          break;
        }
      }
    }
  }

  private setLodgingArchive(): void {
    if (this.archiveList) {
      for (let archive of this.archiveList) {
        if (
          this.houseworkEvent?.housework?.lodging?.image ===
          archive.uniqueFileReference
        ) {
          this.lodgingArchive = archive;
          break;
        }
      }
    }
  }

  private setHouseworkEvent(uniqueReference: string | null): void {
    for (let eve of this.calendarService.calendarEventList) {
      if (
        eve.housework !== undefined &&
        eve.housework.uniqueReference === uniqueReference
      ) {
        this.eventSelected = true;
        this.houseworkEvent = eve;
        break;
      }
    }
  }

  private setReservationEvent(uniqueReference: string | null): void {
    for (let eve of this.calendarService.calendarEventList) {
      if (
        eve.reservation !== undefined &&
        eve.reservation.uniqueReference === uniqueReference
      ) {
        this.eventSelected = true;
        this.reservationEvent = eve;
        break;
      }
    }
  }

  private setCheckEvent(uniqueReference: string | null): void {
    for (let eve of this.calendarService.calendarEventList) {
      if (
        eve.check !== undefined &&
        eve.check.uniqueReference === uniqueReference
      ) {
        this.eventSelected = true;
        this.checkEvent = eve;
        break;
      }
    }
  }

  private setArchiveList(): void {
    this.subList.add(
      this.calendarService.fullCalendarEventListSubject?.subscribe(
        (eventList) => {
          eventList?.forEach((event: FullCalendarEvent) => {
            if (event.housework !== undefined) {
              this.setArchive(
                ImageType.Lodging,
                event.housework?.lodging?.image
              );
              this.setArchive(ImageType.User, event.housework?.cleaner?.avatar);
            }
          });
        }
      )
    );
  }

  private setArchive(imageType: ImageType, guid?: string): void {
    if (guid !== undefined) {
      this.fileService.getImage(guid, imageType).then((archive) => {
        if (archive && archive.uniqueFileReference && this.archiveList) {
          this.archiveList.push(archive);
        }
      });
    }
  }

  private resetArchives(): void {
    this.lodgingArchive = new Archive(defaultFilePath.lodging_default_icon);
    this.cleanerArchive = new Archive(defaultFilePath.user_default_icon);
    this.welcomerArchive = new Archive(defaultFilePath.user_default_icon);
  }
}
