<ng-container class="search-component">
  <h2 class="search-component__title">Résulats de la recherche</h2>

  <div class="search-component__content">
    <mat-grid-list
      class="search-component__content__list"
      [gridRowHeights]="gridRowHeights"
      [gridCols]="gridColumns"
      [gutterSize]="width < 960 ? '40%' : '2%'"
    >
      <mat-grid-tile
        *ngFor="let lodging of lodgingList; let i = index"
        class="search-component__content__list__lodging"
      >
        <mat-card class="search-component__content__list__lodging__card">
          <mat-card-header
            class="search-component__content__list__lodging__card__header"
          >
            <img
              mat-card-avatar
              [src]="getAvatarPath(lodging.owner!.avatar!)"
              (error)="userFileService.handleMissingImage()"
              alt="Photo de l'agent d'entretien"
            />
            <mat-card-title>{{ lodging.owner!.lastname }}</mat-card-title>
            <mat-card-subtitle
              >{{ lodging.address!.city }} ({{
                lodging.address!.postalCode
              }})</mat-card-subtitle
            >
          </mat-card-header>
          <img
            mat-card-image
            class="search-component__content__list__lodging__card__lodging-image"
            [src]="getLodgingFilePath(lodging.image!)"
            (error)="this.lodgingFileService.handleMissingImage()"
            alt="Photo de l'appartement"
          />
          <mat-card-content
            class="search-component__content__list__lodging__card__content"
          >
            <p
              class="search-component__content__list__lodging__card__content__label"
            >
              {{ lodging.label }} ({{ lodging?.address?.postalCode }}
              {{ lodging?.address?.city }})
            </p>
            <p
              class="search-component__content__list__lodging__card__content__description"
            >
              {{ lodging.description }}
            </p>
          </mat-card-content>
          <mat-card-actions
            class="search-component__content__list__lodging__card__actions"
          >
            <button mat-raised-button (click)="sendPrivateMessage(lodging)">
              <mat-icon>comment</mat-icon>
              CONTACTER
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-container>
