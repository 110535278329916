import { defaultFilePath } from 'src/environments/environment.file.path';
import { Archive } from 'src/app/core/models/archivum/archive';
import { FileService } from './file.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserFileService {
  constructor(private fileService: FileService) {}

  public handleMissingImage(archive?: Archive): string {
    if (archive !== undefined && archive !== null) {
      archive.filePath = defaultFilePath.user_default_icon;
    } else {
      archive = new Archive(defaultFilePath.user_default_icon);
    }

    return defaultFilePath.user_default_icon;
  }

  public setUserFormData(archive: Archive): FormData {
    const formData = new FormData();
    this.fileService.setFormData(formData, archive);

    if (archive.user?.avatar !== undefined && archive.user?.avatar !== null) {
      formData.append('uniqueFileReference', archive.user?.avatar!);
    }

    return formData;
  }
}
