import { GridRowHeights } from 'src/app/core/user-interface/GridRowHeights';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatGridList } from '@angular/material/grid-list';
import { Directive, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[gridRowHeights]',
})
export class GridRowHeightDirective implements OnInit {
  private gridRowHeights: GridRowHeights = {
    xs: '1:1.6',
    sm: '1:1.6',
    md: '1:1.1',
    lg: '1:1.1',
    xl: '1:1.1',
  };

  public constructor(
    private breakpointObserver: BreakpointObserver,
    private grid: MatGridList
  ) {
    if (this.grid != null) {
      this.grid.rowHeight = this.gridRowHeights.md;
    }
  }

  public ngOnInit(): void {
    this.defineRowHeights();
  }

  public get rowHeights(): GridRowHeights {
    return this.gridRowHeights;
  }

  @Input('gridRowHeights')
  public set rowHeights(map: GridRowHeights) {
    if (map && 'object' === typeof map) {
      this.gridRowHeights = map;
    }
  }

  private defineRowHeights() {
    if (this.grid != null) {
      this.grid.rowHeight = this.gridRowHeights.md;
    }
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.grid.rowHeight = this.gridRowHeights.xs;
        }
        if (result.breakpoints[Breakpoints.Small]) {
          this.grid.rowHeight = this.gridRowHeights.sm;
        }
        if (result.breakpoints[Breakpoints.Medium]) {
          this.grid.rowHeight = this.gridRowHeights.md;
        }
        if (result.breakpoints[Breakpoints.Large]) {
          this.grid.rowHeight = this.gridRowHeights.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.grid.rowHeight = this.gridRowHeights.xl;
        }
      });
  }
}
