import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { refreshCalendarParameters } from '../core/models/symbiosis-front-app/refresh-calendar';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { CalendarService } from '../shared/services/calendar/calendar.service';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { UserTarget } from '../shared/builder/enum/user.target ';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaner',
  templateUrl: './cleaner.component.html',
  styleUrls: ['./cleaner.component.scss'],
})
export class CleanerComponent implements OnInit {
  private refreshCalendarParameters = new refreshCalendarParameters(
    symbiosisApi.baseUrl,
    symbiosisEndPoint.cleanerPlanningRefresh,
    this.authenticationService.currentAuthenticationValue?.user?.id!,
    UserTarget.cleaner
  );

  constructor(
    private authenticationService: AuthenticationService,
    private calendarService: CalendarService
  ) {
    this.calendarService.refreshCalendarParameters =
      this.refreshCalendarParameters;
  }

  ngOnInit(): void {
    this.calendarService.refreshCalendar(this.refreshCalendarParameters);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }
}
