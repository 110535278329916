import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { SignalRHubService } from '../shared/services/signalR-hub/signalR-hub.service';
import { HttpClientService } from '../shared/services/httpclient/httpclient.service';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { HttpCallType } from '../shared/services/httpclient/enum/httpCallType';
import { LodgingCleaner } from '../core/models/symbiosis/lodging-cleaner';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { AlertService } from '../shared/services/alert/alert.service';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { Component, HostListener, OnInit } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { UserBuilder } from '../shared/builder/user-builder';
import { role } from 'src/environments/environment.roles';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../core/models/symbiosis/user';
import { Chat } from '../core/models/dialogus/chat';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  public user!: User;
  public chatList = new Array<Chat>();
  public submitted: boolean = false;
  public ownerRole: string | undefined = role.owner;

  constructor(
    public authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private signalRHubService: SignalRHubService,
    private alertService: AlertService,
    private userBuilder: UserBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setUser();
    this.getChatList();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public openDialog(chat: Chat): void {
    this.signalRHubService.chatSubject.next(chat);
    this.submitted = true;
  }

  public openProfile(chat: Chat): void {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '600px',
      data: chat,
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (
        data !== undefined &&
        data.cleanerId != undefined &&
        data.lodgingId != undefined
      ) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.createLodgingCleanerLinkFromChat,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(
              new LodgingCleaner(data.cleanerId, data.lodgingId)
            ).replace('/\\/', '')
          )
          .subscribe({
            next: (currentLodgingCleaner: LodgingCleaner) => {},
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  private getChatList(): void {
    this.signalRHubService.chatListObservable.subscribe({
      next: (chatList: Array<Chat> | null) => {
        if (chatList !== undefined) {
          this.chatList = chatList!;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.error(error.message);
      },
    });
  }

  private setUser(): void {
    this.user = this.userBuilder.buildUserFromAuthenticaction(
      this.authenticationService.currentAuthenticationValue!
    );
  }
}
