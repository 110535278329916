<ng-container class="dialog-component">
  <div
    class="dialog-component__message-list"
    #scrollMe
    [scrollTop]="scrollMe.scrollHeight"
  >
    <mat-list class="dialog-component__message-list__mat-list">
      <mat-list-item
        class="dialog-component__message-list__mat-list__mat-item"
        *ngFor="let message of messageList"
      >
        <div
          mat-line
          [ngClass]="
            message.isSender === true
              ? 'dialog-component__message-list__mat-list__mat-item__message-body --sender'
              : 'dialog-component__message-list__mat-list__mat-item__message-body --receiver'
          "
        >
          <div
            class="dialog-component__message-list__mat-list__mat-item__message-body__user-icon"
          >
            <mat-icon mat-list-icon color="primary">portrait</mat-icon>
            <div
              class="dialog-component__message-list__mat-list__mat-item__message-body__user-icon__user-date-label"
            >
              <span>
                {{ message.sender?.lastname }} {{ message.sender?.firstname }}
              </span>
              : {{ message.sendingDate | date : "short" }}
            </div>
          </div>

          <div
            [ngClass]="
              message.isSender === true
                ? 'dialog-component__message-list__mat-list__mat-item__message-body__message-text --sender'
                : 'dialog-component__message-list__mat-list__mat-item__message-body__message-text --receiver'
            "
            class="dialog-component__message-list__mat-list__mat-item__message-body__message-text wrap"
          >
            <div
              [ngClass]="
                message.isSender === true
                  ? 'dialog-component__message-list__mat-list__mat-item__message-body__message-text__message --sender'
                  : 'dialog-component__message-list__mat-list__mat-item__message-body__message-text__message --receiver'
              "
              class="dialog-component__message-list__mat-list__mat-item__message-body__message-text__message"
            >
              {{ message.content }}
            </div>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <div class="dialog-component__input-container">
    <div class="dialog-component__input-container__input-field">
      <mat-form-field
        class="dialog-component__input-container__input-field__form-field"
        appearance="outline"
      >
        <mat-label>Message</mat-label>
        <textarea
          matInput
          #messageInput
          placeholder="Saisssez votre message..."
          class="dialog-component__input-container__input-field__form-field__chat-input"
          [(ngModel)]="message"
          (keyup.enter)="sendPrivateMessage()"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="dialog-component__input-container__button-container">
      <button mat-mini-fab color="primary" (click)="sendPrivateMessage()">
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
