import { LodgingListComponent } from './lodging/lodging-list/lodging-list.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { RegisterComponent } from './user/register/register.component';
import { WelcomerComponent } from './welcomer/welcomer.component';
import { MissionComponent } from './mission/mission.component';
import { LodgingComponent } from './lodging/lodging.component';
import { CompanyComponent } from './company/company.component';
import { CleanerComponent } from './cleaner/cleaner.component';
import { LoginComponent } from './user/login/login.component';
import { SearchComponent } from './search/search.component';
import { OwnerComponent } from './owner/owner.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { ChatComponent } from './chat/chat.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: 'welcomer', component: WelcomerComponent, canActivate: [AuthGuard] },
  { path: 'lodging', component: LodgingComponent, canActivate: [AuthGuard] },
  { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] },
  { path: 'cleaner', component: CleanerComponent, canActivate: [AuthGuard] },
  { path: 'mission', component: MissionComponent, canActivate: [AuthGuard] },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'owner', component: OwnerComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent },
  { path: '', component: LoginComponent },
  {
    path: 'companyList',
    component: CompanyListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lodgingList',
    component: LodgingListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
