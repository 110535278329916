import { UserTarget } from 'src/app/shared/builder/enum/user.target ';

export class refreshCalendarParameters {
  baseUrl: string;
  endPoint: string;
  id: number;
  userTarget: UserTarget;

  constructor(
    baseUrl: string,
    endPoint: string,
    id: number,
    userTarget: UserTarget
  ) {
    this.baseUrl = baseUrl;
    this.endPoint = endPoint;
    this.id = id;
    this.userTarget = userTarget;
  }
}
