import { HttpHeadersService } from '../httpheaders/httpheaders.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { HttpClientType } from './enum/httpClientType';
import { HttpCallType } from './enum/httpCallType';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  private httpClientNoIntercept: HttpClient;

  constructor(
    private httpHeaders: HttpHeadersService,
    private httpBackend: HttpBackend,
    private httpClient: HttpClient
  ) {
    this.httpClientNoIntercept = new HttpClient(this.httpBackend);
  }

  public buildUrl(baseUrl: string, endPoint?: string, id?: number): string {
    let url = baseUrl;
    url += `${endPoint}`;
    url += id != undefined ? `/${id}` : '';
    return url;
  }

  public httpCall(
    url: string,
    httpCallType: HttpCallType,
    method: string,
    contentType?: string,
    jsonString?: string,
    body?: any,
    reportProgress?: boolean,
    httpClientType?: HttpClientType
  ): Observable<any> {
    const httpClient = this.getHttpClientType(httpClientType);

    switch (httpCallType) {
      case HttpCallType.Request:
        return httpClient.request(method, url, {
          body: jsonString ?? body,
          responseType: 'json',
          headers: this.httpHeaders.GetHttpHeaders(contentType),
        });
      case HttpCallType.Get:
        return httpClient.get(url);
      case HttpCallType.Post:
        return httpClient.post(url, jsonString ?? body, {
          headers: this.httpHeaders.GetHttpHeaders(contentType),
          reportProgress: reportProgress,
        });
      case HttpCallType.Put:
        return httpClient.put(url, jsonString ?? body, {
          headers: this.httpHeaders.GetHttpHeaders(contentType),
        });
      case HttpCallType.Delete:
        return httpClient.delete(url, {
          headers: this.httpHeaders.GetHttpHeaders(contentType),
        });
      default:
        console.error('La requête est invalide.');
        return new Observable();
    }
  }

  private getHttpClientType(httpClientType?: HttpClientType): HttpClient {
    switch (httpClientType) {
      case HttpClientType.backend:
        return this.httpClientNoIntercept;
      default:
        return this.httpClient;
    }
  }
}
