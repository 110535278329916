<ng-container class="check-mission-component">
  <h2 class="check-mission-component__title">Liste des missions</h2>

  <div
    class="check-mission-component__no-mission"
    *ngIf="
      checkList?.length === 0 || checkList === undefined || checkList === null
    "
  >
    Aucune mission n'est disponible pour le moment. Rafraîchir la page
    régulièrement permet d'afficher les prochaines missions à venir.
  </div>

  <div class="check-mission-component__content">
    <mat-grid-list
      class="check-mission-component__content__list"
      [gridRowHeights]="gridRowHeights"
      [gridCols]="gridColumns"
      [gutterSize]="'2%'"
    >
      <mat-grid-tile
        *ngFor="let check of checkList"
        class="check-mission-component__content__list__check"
      >
        <mat-card class="check-mission-component__content__list__check__card">
          <mat-card-header
            class="check-mission-component__content__list__check__card__header"
          >
            <mat-card-title
              >Date de réception : {{ check.in | formatDate }}</mat-card-title
            >
            <mat-card-title
              >Date de sortie : {{ check.out | formatDate }}</mat-card-title
            >
          </mat-card-header>
          <img
            mat-card-image
            class="check-mission-component__content__list__check__card__check-image"
            [src]="getLodgingFilePath(check.lodging?.image!)"
            (error)="this.lodgingFileService.handleMissingImage()"
            alt="Photo de l'appartement"
          />
          <mat-card-content
            class="check-mission-component__content__list__check__card__content"
          >
            <p
              class="check-mission-component__content__list__check__card__content__label"
            >
              {{ check.lodging?.label }}
            </p>
            <p
              class="check-mission-component__content__list__check__card__content__description"
            >
              {{ check.lodging?.address?.label }} ({{
                check.lodging?.address?.postalCode
              }}
              {{ check.lodging?.address?.city }})
            </p>
          </mat-card-content>
          <mat-card-actions
            class="check-mission-component__content__list__check__card__actions"
          >
            <button
              mat-raised-button
              (click)="response(check.id, check.lodging?.id!, true)"
            >
              ACCEPTER
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-container>
