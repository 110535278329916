import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AdminLodgingComponent } from '../admin-lodging/admin-lodging.component';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { contentType } from 'src/environments/environment.content.types';
import { LodgingBuilder } from 'src/app/shared/builder/lodging-builder';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lodging-list',
  templateUrl: './lodging-list.component.html',
  styleUrls: ['./lodging-list.component.scss'],
})
export class LodgingListComponent implements OnInit {
  public lodgingList?: Array<Lodging | undefined>;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private lodgingBuilder: LodgingBuilder,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.lodgingList =
      this.authenticationService.currentAuthenticationValue?.lodgings;
    this.sortLodgingList();
  }

  public createLodging(): void {
    const dialogRef = this.dialog.open(AdminLodgingComponent, {
      width: '1000px',
    });

    dialogRef.afterClosed().subscribe((lodgingForm: FormGroup) => {
      if (lodgingForm != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.createLodging,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(
              this.lodgingBuilder.buildLodging(
                lodgingForm,
                OperationType.create
              )
            )
          )
          .subscribe({
            next: (lodging: Lodging) => {
              this.authenticationService.currentAuthenticationValue?.lodgings?.push(
                lodging
              );
              this.authenticationService.setOrRefreshLocalStorage(
                this.authenticationService.currentAuthenticationValue!
              );
            },
            error: (error: HttpErrorResponse) => {
              this.spinnerService.requestEnded();
              console.error(error);
            },
          });
      }
    });
  }

  private sortLodgingList() {
    this.lodgingList?.sort((a, b) => {
      const labelA = a?.label?.toUpperCase();
      const labelB = b?.label?.toUpperCase();

      if (labelA !== undefined && labelB !== undefined) {
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
      }

      return 0;
    });
  }
}
