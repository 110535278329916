<ng-container class="cleaner-event-component">
  <mat-card class="cleaner-event-component__infos">
    <h2
      [class]="
        houseworkEvent === undefined
          ? 'cleaner-event-component__infos__lodging-title --hidden'
          : 'cleaner-event-component__infos__lodging-title'
      "
    >
      {{ houseworkEvent?.housework?.lodging?.label }}
      <mat-card-subtitle
        class="cleaner-event-component__infos__lodging-title__address"
        >{{ houseworkEvent?.housework?.lodging?.address?.label }}
        {{ houseworkEvent?.housework?.lodging?.address?.postalCode }}
        {{
          houseworkEvent?.housework?.lodging?.address?.city
        }}</mat-card-subtitle
      >
    </h2>
    <mat-card-header class="cleaner-event-component__infos__lodging">
      <div class="cleaner-event-component__infos__lodging__image">
        <img
          mat-card-image
          [src]="lodgingArchive?.filePath"
          (error)="lodgingFileService.handleMissingImage(lodgingArchive!)"
          alt="Photo de l'appartement"
        />
      </div>
    </mat-card-header>

    <h2 class="cleaner-event-component__infos__housework-title">Ménage</h2>
    <mat-card-header class="cleaner-event-component__infos__housework">
      <mat-card-title
        >Statut :
        <span
          [class]="
            houseworkEvent?.housework?.summary === 'Annulé'
              ? 'cleaner-event-component__infos__housework__status --canceled'
              : 'cleaner-event-component__infos__housework__status'
          "
          >{{ houseworkEvent?.housework?.summary }}</span
        >
      </mat-card-title>
      <mat-card-title
        >Date d'intervention :
        {{ houseworkEvent?.housework?.eventDate | formatDate }}</mat-card-title
      >
      <mat-card-title
        >Fin des check-out :
        {{
          houseworkEvent?.housework?.lodging?.checkOutEnd | formatTime
        }}</mat-card-title
      >
      <mat-card-title
        >Début des check-in :
        {{
          houseworkEvent?.housework?.lodging?.checkInStart | formatTime
        }}</mat-card-title
      >
      <mat-card-title class="cleaner-event-component__infos__title__address"
        >Adresse : {{ houseworkEvent?.housework?.lodging?.address?.label }}
        {{ houseworkEvent?.housework?.lodging?.address?.postalCode }}
        {{ houseworkEvent?.housework?.lodging?.address?.city }}</mat-card-title
      >
    </mat-card-header>
  </mat-card>
</ng-container>
