<ng-container class="worker-list-component">
  <div class="worker-list-component__description">
    <h1 mat-dialog-title>
      Liste des agents d'entretien et welcomers liés au logement
    </h1>
    <p
      *ngIf="workers?.length === 0 || workers === undefined || workers === null"
    >
      Aucun agent d'entretien n'est rattaché à ce logement.
    </p>
  </div>

  <div mat-dialog-content class="worker-list-component__worker-list">
    <mat-list
      class="worker-list-component__worker-list__worker"
      *ngFor="let worker of workers"
    >
      <button
        mat-raised-button
        class="worker-list-component__worker-list__worker__name"
      >
        <mat-icon *ngIf="worker.role?.label === 'Cleaner'" aria-hidden="false"
          >brush</mat-icon
        >
        <mat-icon *ngIf="worker.role?.label === 'Welcomer'" aria-hidden="false"
          >key</mat-icon
        >
        {{ worker.lastname }} {{ worker.firstname }}
      </button>
      <button
        mat-raised-button
        class="worker-list-component__worker-list__worker__phoneNumber"
      >
        <mat-icon aria-hidden="false">phone</mat-icon>
        {{ worker.phoneNumber }}
      </button>
      <button
        mat-raised-button
        class="worker-list-component__worker-list__worker__email"
      >
        <mat-icon aria-hidden="false">mail</mat-icon>
        {{ worker.email }}
      </button>
      <button
        *ngIf="enableWorkerLineFunction"
        mat-raised-button
        (click)="deleteCleanerLink(worker)"
        [class]="
          'worker-list-component__worker-list__worker__actions-button ' +
          setActionButtonColor()
        "
        [matTooltip]="width < 1024 ? '' : workerLineTooltip"
      >
        <mat-icon aria-hidden="false">{{ workerLineIcon }}</mat-icon>
        <span *ngIf="width < 1024">{{ workerLineDescription }}</span>
      </button>

      <mat-divider
        *ngIf="width < 1024"
        class="worker-list-component__worker-list__worker__divider"
      ></mat-divider>
    </mat-list>
  </div>

  <mat-card-actions class="worker-list-component__buttons">
    <button
      mat-button
      class="worker-list-component__buttons__cancel-button"
      (click)="onClose()"
    >
      Fermer
    </button>
    <button
      *ngIf="enableModalFunction"
      mat-button
      class="worker-list-component__buttons__add-link"
      (click)="openUnlinkedCleanerList()"
    >
      Ajouter
    </button>
  </mat-card-actions>
</ng-container>
