import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from 'src/app/core/models/symbiosis/company';

@Component({
  selector: 'app-admin-company',
  templateUrl: './admin-company.component.html',
  styleUrls: ['./admin-company.component.scss'],
})
export class AdminCompanyComponent implements OnInit {
  public submitted: boolean = false;
  public operation: OperationType;
  public companyForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public company: Company,
    private formBuilder: FormBuilder
  ) {
    this.companyForm = this.formBuilder.group({
      label: [company != null ? company?.label : '', Validators.required],
      siret: [company != null ? company?.siret : '', Validators.required],
      phoneNumber: [
        company != null ? company?.phoneNumber : '',
        Validators.required,
      ],
      email: [company != null ? company?.email : '', Validators.required],
      companyAddressLabel: [
        company != null ? company?.address?.label : '',
        Validators.required,
      ],
      companyPostalCode: [
        company != null ? company?.address?.postalCode : '',
        Validators.required,
      ],
      companyCity: [
        company != null ? company?.address?.city : '',
        Validators.required,
      ],
      companyRegion: [
        company != null ? company?.address?.region : '',
        Validators.required,
      ],
      companyCountry: [
        company != null ? company?.address?.country : '',
        Validators.required,
      ],
      description: [
        company != null ? company?.description : '',
        Validators.compose([Validators.required, Validators.maxLength(1000)]),
      ],
    });
    this.operation =
      company != null ? OperationType.edit : OperationType.create;
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get f() {
    return this.companyForm.controls;
  }

  public get width() {
    return window.innerWidth;
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.companyForm.invalid) {
      return;
    }

    this.dialogRef.close(this.companyForm);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
