import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/core/models/symbiosis/user';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';

export enum ButtonColor {
  green,
  red,
}

@Component({
  selector: 'app-worker-list',
  templateUrl: './worker-list.component.html',
  styleUrls: ['./worker-list.component.scss'],
})
export class WorkerListComponent implements OnInit {
  public workers?: Array<User>;

  @Output() workerLineFunction;
  @Output() modalFunction;

  public enableWorkerLineFunction: boolean;
  public enableModalFunction: boolean;

  public workerActionButtonColor: ButtonColor;
  public workerLineDescription: string;
  public workerLineTooltip: string;
  public workerLineIcon: string;

  constructor(
    private dialogRef: MatDialogRef<WorkerListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.enableWorkerLineFunction = data.enableWorkerLineFunction;
    this.workerActionButtonColor = data.workerActionButtonColor;
    this.workerLineDescription = data.WorkerLineDescription;
    this.enableModalFunction = data.enableModalFunction;
    this.workerLineTooltip = data.workerLineTooltip;
    this.workerLineIcon = data.icon;
    this.workers = data.workers;

    if (this.enableWorkerLineFunction) {
      this.workerLineFunction = new EventEmitter<User>();
    }

    if (this.enableModalFunction) {
      this.modalFunction = new EventEmitter<boolean>();
    }
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width(): number {
    return window.innerWidth;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public deleteCleanerLink(worker: User): void {
    this.workerLineFunction?.emit(worker);
  }

  public openUnlinkedCleanerList(): void {
    this.modalFunction?.emit(true);
  }

  public setActionButtonColor(): string {
    switch (this.workerActionButtonColor) {
      case ButtonColor.green:
        return '--green';
      case ButtonColor.red:
        return '--red';
    }
  }
}
