import { defaultFilePath } from 'src/environments/environment.file.path';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { Archive } from 'src/app/core/models/archivum/archive';
import { FileService } from './file.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LodgingFileService {
  constructor(private fileService: FileService) {}

  public handleMissingImage(archive?: Archive): string {
    if (archive !== undefined && archive !== null) {
      archive.filePath = defaultFilePath.lodging_default_icon;
    } else {
      archive = new Archive(defaultFilePath.lodging_default_icon);
    }

    return defaultFilePath.lodging_default_icon;
  }

  public setLodgingFormData(archive: Archive, lodging: Lodging): FormData {
    const formData = new FormData();
    this.fileService.setFormData(formData, archive);

    if (lodging.image !== undefined && lodging.image !== null) {
      formData.append('uniqueFileReference', lodging.image!);
    }
    formData.append('label', lodging?.label!);

    return formData;
  }
}
