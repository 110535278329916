// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
};

const basePath = {
  localhost: `https://localhost:`,
  symbiosis_ovh: `https://symbiosis.ovh`,
};

export const symbiosis_ovh = {
  symbiosisApi: `${basePath.symbiosis_ovh}/symbiosisapi`,
  archivum: `${basePath.symbiosis_ovh}/archivum`,
  dialogus: `${basePath.symbiosis_ovh}/dialogus`,
};

const localhost = {
  symbiosisApi: `${basePath.localhost}7109`,
  archivum: `${basePath.localhost}7017`,
  dialogus: `${basePath.localhost}7244`,
};

const env = symbiosis_ovh;

export const symbiosisApi = {
  baseUrl: `${env.symbiosisApi}`,
};

export const archivumApi = {
  baseUrl: `${env.archivum}`,
};

export const dialogusApi = {
  baseUrl: `${env.dialogus}`,
};

export const dialogusHub = {
  baseUrl: `${env.dialogus}`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
