import { AlertMessage } from '../alert-message';
import { Address } from './address';
import { Company } from './company';
import { Lodging } from './lodging';
import { Role } from './role';
import { User } from './user';

export class Authentication {
  public token?: string;
  public refreshToken?: string;
  public refreshTokenExpiryTime?: Date;
  public user?: User;
  public role?: Role;
  public address?: Address;
  public alertMessage?: AlertMessage;
  public lodgings?: Array<Lodging>;
  public companies?: Array<Company>;
}
