import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'formatTime',
})
export class FormatTime extends DatePipe implements PipeTransform {
  override transform(value: any, ...args: Array<unknown>): any {
    const date = new Date();
    const formatedHour = value.replace(' AM', '').split(':');
    date.setHours(formatedHour[0]);
    date.setMinutes(formatedHour[1]);
    return super.transform(date, 'HH:mm');
  }
}
