import { EntityBase } from '../base/entitybase';
import { Lodging } from '../symbiosis/lodging';
import { User } from '../symbiosis/user';
import { Message } from './message';

export class Chat extends EntityBase {
  public firstContact: User;
  public secondContact: User;
  public senderConnectionId: string;
  public receiverConnectionId: string;
  public lodging: Lodging;
  public messages: Array<Message>;

  constructor(
    firstContact: User,
    secondContact: User,
    senderConnectionId: string,
    receiverConnectionId: string,
    lodging: Lodging,
    messages: Array<Message>
  ) {
    super();
    this.firstContact = firstContact;
    this.secondContact = secondContact;
    this.senderConnectionId = senderConnectionId;
    this.receiverConnectionId = receiverConnectionId;
    this.lodging = lodging;
    this.messages = messages;
  }
}
