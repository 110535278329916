export enum LodgingType {
  Studio,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  Souplex,
  Duplex,
  Triplex,
  Loft,
}
