import { AuthenticationService } from '../services/authentication/authentication.service';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { Company } from 'src/app/core/models/symbiosis/company';
import { Address } from 'src/app/core/models/symbiosis/address';
import { OperationType } from './enum/operation.type';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CompanyBuilder {
  constructor(private authenticationService: AuthenticationService) {}

  public buildCompany(
    companyForm?: FormGroup,
    operationType?: OperationType,
    companyId?: number,
    image?: string
  ): Company {
    let currentCompany: Company = this.getCurrentCompany(companyId);

    let companyImage = null;
    if (currentCompany) {
      companyImage = companyForm?.get('image')?.value ?? currentCompany.image;
    }

    let company = new Company(
      companyForm?.get('label')?.value ?? currentCompany!.label,
      companyForm?.get('siret')?.value.toString() ?? currentCompany!.siret,
      companyForm?.get('phoneNumber')?.value.toString() ??
        currentCompany!.phoneNumber,
      companyForm?.get('email')?.value ?? currentCompany!.email,
      companyForm?.get('description')?.value ?? currentCompany!.description,
      this.authenticationService.currentAuthenticationValue?.user?.id!,
      companyForm?.get('searchable')?.value ?? currentCompany!.searchable,
      new Address(
        companyForm?.get('companyAddressLabel')?.value ??
          currentCompany!.address?.label,
        companyForm?.get('companyPostalCode')?.value.toString() ??
          currentCompany!.address?.postalCode,
        companyForm?.get('companyCity')?.value ?? currentCompany!.address?.city,
        companyForm?.get('companyRegion')?.value ??
          currentCompany!.address?.region,
        companyForm?.get('companyCountry')?.value ??
          currentCompany!.address?.country
      )
    );

    if (image !== undefined) {
      company.image = image;
    }

    if (
      operationType === OperationType.edit ||
      (companyId !== undefined && companyId > 0)
    ) {
      company.id = companyId!;
    }

    return company;
  }

  private getCurrentCompany(companyId: number | undefined): Company {
    let currentCompany: Company;
    if (companyId !== null) {
      this.authenticationService.currentAuthenticationValue?.companies?.forEach(
        (company: Company) => {
          if (company.id === companyId) {
            currentCompany = company;
          }
        }
      );
    }

    return currentCompany!;
  }

  buildCompanyFromAuthenticaction(
    authentication: Authentication,
    companyId?: number,
    operationType?: OperationType
  ): Company {
    const index = authentication.companies?.findIndex(
      (company: Company) => company.id == companyId
    );
    let authCompany = authentication.companies![index!];

    let company = new Company(
      authCompany?.label!,
      authCompany?.siret!,
      authCompany?.phoneNumber!,
      authCompany?.email!,
      authCompany?.description!,
      authCompany?.managerId,
      authCompany?.searchable
    );
    let address = new Address(
      authCompany?.address!.label,
      authCompany?.address!.postalCode,
      authCompany?.address!.city,
      authCompany?.address!.region,
      authCompany?.address!.country
    );
    if (operationType === OperationType.edit) {
      company.id = authCompany?.id;
      address.id = authCompany?.address!.id;
    }
    company.address = address;
    company.members = authCompany?.members;
    company.image = company.image;

    return company;
  }
}
