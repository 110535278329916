import { AuthenticationService } from '../services/authentication/authentication.service';
import { role } from 'src/environments/environment.roles';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      new JwtHelperService().isTokenExpired(
        this.authenticationService.currentAuthenticationValue?.token
      )
    ) {
      return await this.authenticationService.tryRefreshAccessToken();
    } else if (
      this.authenticationService.currentAuthenticationValue?.role?.label ===
        role.owner ||
      this.authenticationService.currentAuthenticationValue?.role?.label ===
        role.cleaner ||
      this.authenticationService.currentAuthenticationValue?.role?.label ===
        role.welcomer
    ) {
      return true;
    } else {
      return this.authenticationService.logout();
    }
  }
}
