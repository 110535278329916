import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { CompanyFileService } from '../shared/services/file/company-file.service';
import { AdminCompanyComponent } from './admin-company/admin-company.component';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { Authentication } from '../core/models/symbiosis/authentication';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArchiveBuilder } from '../shared/builder/archive-builder';
import { CompanyBuilder } from '../shared/builder/company-builder';
import { FileService } from '../shared/services/file/file.service';
import { AuthBuilder } from 'src/app/shared/builder/auth-builder';
import { Company } from '../core/models/symbiosis/company';
import { Archive } from '../core/models/archivum/archive';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ImageType } from '../core/enum/image-type';
import {
  archivumEndPoint,
  symbiosisEndPoint,
} from 'src/environments/environment.api.endpoints';
import { Tile } from 'src/app/user/user.component';
import { FileType } from '../core/enum/file-type';
import { ActivatedRoute } from '@angular/router';
import {
  defaultFilePath,
  fileBasePath,
} from 'src/environments/environment.file.path';
import { finalize, tap } from 'rxjs';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  @ViewChild('selector')
  selector!: ElementRef;

  public companyArchive: Archive = new Archive(
    defaultFilePath.user_default_icon
  );
  public companyImageType: ImageType = ImageType.Company;
  public searchableSlideToggle!: FormControl;
  public rowHeightValue: number = 45;
  public colsValue: number = 4;
  public company?: Company;

  constructor(
    private authenticationService: AuthenticationService,
    public companyFileService: CompanyFileService,
    private httpClientService: HttpClientService,
    private activatedRoute: ActivatedRoute,
    private archiveBuilder: ArchiveBuilder,
    private companyBuilder: CompanyBuilder,
    private alertService: AlertService,
    private authBuilder: AuthBuilder,
    public fileService: FileService,
    private dialog: MatDialog
  ) {
    this.getActiavtedRoute();
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public editCompany(): void {
    const dialogRef = this.dialog.open(AdminCompanyComponent, {
      width: '1000px',
      data: this.company,
    });

    dialogRef.afterClosed().subscribe((companyForm: FormGroup) => {
      if (companyForm != null) {
        let authentication = this.authBuilder.buildAuthenticationFromForm(
          companyForm,
          OperationType.edit,
          false,
          this.company!.id,
          true
        );
        const url = this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateLodging,
          this.company!.id
        );
        this.httpClientService
          .httpCall(
            url,
            HttpCallType.Request,
            httpCallMethod.put,
            contentType.application_json,
            JSON.stringify(authentication).replace('/\\/', '')
          )
          .subscribe({
            next: (authentication: Authentication) => {
              this.authenticationService.setOrRefreshLocalStorage(
                authentication
              );
              this.setCompany(
                authentication,
                this.company?.id,
                OperationType.edit
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  public editSearchable(): void {
    if (this.searchableSlideToggle.value === true) {
      this.searchableSlideToggle = new FormControl(true, [Validators.required]);
      this.company!.searchable = true;
    } else {
      this.searchableSlideToggle = new FormControl(false, [
        Validators.required,
      ]);
      this.company!.searchable = false;
    }

    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateCompany,
          this.company?.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(
          this.authBuilder.buildAuthenticationFromForm(
            new FormGroup({ searchable: this.searchableSlideToggle }),
            OperationType.edit,
            false,
            undefined,
            false,
            this.company?.id,
            true
          )
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
          this.setCompany(authentication, this.company?.id, OperationType.edit);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public setTiles(description: string, companyAttribute: string): Array<Tile> {
    return [
      { text: description, cols: 1, rows: 1, color: '#F0F0F0' },
      { text: companyAttribute, cols: 3, rows: 1, color: '#F0F0F0' },
    ];
  }

  public selectFile(event: any, company: Company): void {
    if (event.target.files && event.target.files[0]) {
      this.fileService
        .uploadFileInArchivum(
          archivumEndPoint.upload,
          this.companyFileService.setCompanyFormData(
            new Archive(
              fileBasePath.image,
              event.target.files[0],
              FileType.Image,
              this.authenticationService.currentAuthenticationValue?.user!
            ),
            company
          )
        )
        .pipe(
          tap({
            next: (archive: Archive) => {
              if (archive !== undefined) {
                this.companyArchive = archive;
              }
              this.fileService.refreshLocalStorage(
                archive,
                ImageType.Lodging,
                company?.id
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          }),
          finalize(() => {
            if (company?.id !== undefined) {
              this.fileService
                .refreshGuid(
                  symbiosisApi.baseUrl,
                  symbiosisEndPoint.updateLodging,
                  company.id
                )
                .subscribe({
                  next: (authentication: Authentication) => {
                    this.authenticationService.setOrRefreshLocalStorage(
                      authentication
                    );

                    if (this.companyArchive.company?.id === company?.id) {
                      this.companyArchive.uniqueFileReference = company?.image;
                    }
                  },
                  error: (error: HttpErrorResponse) => {
                    console.error(error);
                  },
                });
            }
          })
        )
        .subscribe();
    }
  }

  private getActiavtedRoute() {
    this.activatedRoute.queryParams.subscribe({
      next: (data: any) => {
        this.setCompany(
          this.authenticationService.currentAuthenticationValue!,
          data.id,
          OperationType.edit
        );
        this.searchableSlideToggle = new FormControl(this.company?.searchable, [
          Validators.required,
        ]);
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.error(error.message);
      },
    });
  }

  private setCompany(
    authentication?: Authentication,
    companyId?: number,
    operationType?: OperationType
  ): void {
    this.company = this.companyBuilder.buildCompanyFromAuthenticaction(
      authentication ?? this.authenticationService.currentAuthenticationValue!,
      companyId,
      operationType
    );
    this.setCompanyArchive(this.company);
  }

  private setCompanyArchive(company: Company): void {
    this.fileService.getArchive(company.image!).subscribe({
      next: (archive: Archive) => {
        const newArchive = this.archiveBuilder.buildArchive(
          archive,
          this.authenticationService.currentAuthenticationValue?.user,
          undefined,
          company
        );
        if (archive !== undefined) {
          this.companyArchive = newArchive;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      },
    });
  }
}
