<ng-container class="company-list-component">
  <div class="company-list-component__description">
    <h1 mat-dialog-title>Liste des sociétés</h1>
    <p *ngIf="companyList !== null && companyList!.length > 0">
      Sélectionnez une société pour afficher son détail.
    </p>
    <p *ngIf="companyList === null || companyList!.length === 0">
      Vous n'avez pas encore créé de société. Pour ce faire, veuillez cliquer
      sur "Créer une société".
    </p>
  </div>

  <div mat-dialog-content class="company-list-component__company-list">
    <mat-list
      class="company-list-component__company-list__company-mat-list"
      *ngFor="let company of companyList"
    >
      <app-company-line [company]="company"></app-company-line>
    </mat-list>
  </div>

  <div class="company-list-component__button">
    <button mat-raised-button (click)="createCompany()">
      <mat-icon class="company-list-component__button__icon" aria-hidden="false"
        >add_circle</mat-icon
      >
      Créer une société
    </button>
  </div>
</ng-container>
