import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AdminCompanyComponent } from '../admin-company/admin-company.component';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { contentType } from 'src/environments/environment.content.types';
import { CompanyBuilder } from 'src/app/shared/builder/company-builder';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { Company } from 'src/app/core/models/symbiosis/company';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  public companyList?: Array<Company | undefined>;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private companyBuilder: CompanyBuilder,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.companyList =
      this.authenticationService.currentAuthenticationValue?.companies;
    this.sortCompanyList();
  }

  public createCompany(): void {
    const dialogRef = this.dialog.open(AdminCompanyComponent, {
      width: '1000px',
    });

    dialogRef.afterClosed().subscribe((companyForm: FormGroup) => {
      if (companyForm != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.createCompany,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(
              this.companyBuilder.buildCompany(
                companyForm,
                OperationType.create
              )
            )
          )
          .subscribe({
            next: (company: Company) => {
              if (
                company.alertMessage === null ||
                company.alertMessage === undefined
              ) {
                this.authenticationService.currentAuthenticationValue?.companies?.push(
                  company
                );
                this.authenticationService.setOrRefreshLocalStorage(
                  this.authenticationService.currentAuthenticationValue!
                );
              }
            },
            error: (error: HttpErrorResponse) => {
              this.spinnerService.requestEnded();
              console.error(error);
            },
          });
      }
    });
  }

  private sortCompanyList() {
    this.companyList?.sort((a, b) => {
      const labelA = a?.label?.toUpperCase();
      const labelB = b?.label?.toUpperCase();

      if (labelA !== undefined && labelB !== undefined) {
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
      }

      return 0;
    });
  }
}
