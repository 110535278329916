import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { UserFileService } from 'src/app/shared/services/file/user-file.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultFilePath } from 'src/environments/environment.file.path';
import { FileService } from 'src/app/shared/services/file/file.service';
import { Archive } from 'src/app/core/models/archivum/archive';
import { Component, Inject, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/symbiosis/user';
import { Chat } from 'src/app/core/models/dialogus/chat';
import { ImageType } from 'src/app/core/enum/image-type';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public lodgingImageType: ImageType = ImageType.Lodging;
  public connectionRequest: boolean = false;
  public submitted: boolean = false;
  public lodgingArchive?: Archive;
  public cleanerArchive?: Archive;
  public cleaner?: User;

  constructor(
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    public lodgingFileService: LodgingFileService,
    @Inject(MAT_DIALOG_DATA) public chat: Chat,
    public userFileService: UserFileService,
    public fileService: FileService
  ) {}

  ngOnInit(): void {
    this.setCleaner();
    this.setLodgingImage();
    this.setCleanerAvatar();
  }

  public onSubmit(cleanerId: number, lodgingId: number): void {
    this.submitted = true;

    if (lodgingId === undefined) {
      return;
    }

    this.dialogRef.close({ cleanerId: cleanerId, lodgingId: lodgingId });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  private setCleaner() {
    this.cleaner =
      this.chat.firstContact.id ===
      this.authenticationService.currentAuthenticationValue?.user?.id
        ? this.chat.secondContact
        : this.chat.firstContact;
  }

  private setLodgingImage() {
    if (this.chat.lodging.image) {
      this.fileService
        .getImage(this.chat.lodging.image, ImageType.Lodging)
        .then((archive) => {
          if (archive !== null) {
            this.lodgingArchive = archive;
          } else {
            this.lodgingArchive = new Archive(
              defaultFilePath.lodging_default_icon
            );
          }
        });
    } else {
      this.lodgingArchive = new Archive(defaultFilePath.lodging_default_icon);
    }
  }

  private setCleanerAvatar() {
    if (this.cleaner && this.cleaner.avatar) {
      this.fileService
        .getImage(this.cleaner.avatar, ImageType.User)
        .then((archive) => {
          if (archive !== null) {
            this.cleanerArchive = archive;
          } else {
            this.cleanerArchive = new Archive(
              defaultFilePath.user_default_icon
            );
          }
        });
    } else {
      this.cleanerArchive = new Archive(defaultFilePath.user_default_icon);
    }
  }
}
