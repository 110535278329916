import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CalendarService } from '../../services/calendar/calendar.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { SpinnerService } from '../spinner/spinner.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  public notAvailableSlideToggle!: FormControl;
  public reservationSlideToggle!: FormControl;
  public houseworkSlideToggle!: FormControl;
  public canceledSlideToggle!: FormControl;
  public checkSlideToggle!: FormControl;

  constructor(
    public authenticationService: AuthenticationService,
    public calendarService: CalendarService,
    private spinnerService: SpinnerService
  ) {
    this.calendarService.lodgings =
      this.authenticationService.currentAuthenticationValue?.lodgings!;

    this.sortLodgingList();

    this.calendarService.calendarFiltersSubject.subscribe((calendarFilters) => {
      this.notAvailableSlideToggle = new FormControl(
        calendarFilters.showNotAvailableList,
        [Validators.required]
      );
    });

    this.calendarService.calendarFiltersSubject.subscribe((calendarFilters) => {
      this.reservationSlideToggle = new FormControl(
        calendarFilters.showReservationList,
        [Validators.required]
      );
    });

    this.calendarService.calendarFiltersSubject.subscribe((calendarFilters) => {
      this.houseworkSlideToggle = new FormControl(
        calendarFilters.showHouseworkList,
        [Validators.required]
      );
    });

    this.calendarService.calendarFiltersSubject.subscribe((calendarFilters) => {
      this.canceledSlideToggle = new FormControl(
        calendarFilters.showCanceledList,
        [Validators.required]
      );
    });

    this.calendarService.calendarFiltersSubject.subscribe((calendarFilters) => {
      this.checkSlideToggle = new FormControl(calendarFilters.showCheckList, [
        Validators.required,
      ]);
    });
  }

  ngOnInit(): void {
    this.calendarService.showEventsOnCalendar();
    this.spinnerService.requestEnded();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public setHeaderToolbar() {
    if (this.width < 1024) {
      Array.from(
        document.getElementsByClassName(
          '.fc-header-toolbar'
        ) as HTMLCollectionOf<HTMLElement>
      ).forEach((toolbar) => {
        toolbar.style.backgroundColor = 'red';
        toolbar.style.display = 'block !important' as any;
      });
    }
  }

  private sortLodgingList() {
    this.calendarService.lodgings?.sort((a, b) => {
      const labelA = a?.label?.toUpperCase();
      const labelB = b?.label?.toUpperCase();

      if (labelA !== undefined && labelB !== undefined) {
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
      }

      return 0;
    });
  }
}
