<div class="confirm-popup-component">
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions class="confirm-popup-component__buttons">
    <button
      mat-button
      class="confirm-popup-component__buttons__cancel"
      (click)="annuler()"
    >
      Non
    </button>
    <button
      mat-button
      class="confirm-popup-component__buttons__confirm"
      (click)="confirmer()"
      cdkFocusInitial
    >
      Oui
    </button>
  </div>
</div>
