<main id="home-main">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="
        width < 1024 || (isHandset$ | async) ? 'dialog' : 'navigation'
      "
      [mode]="width < 1024 || (isHandset$ | async) ? 'over' : 'side'"
      [opened]="width < 1024 || (isHandset$ | async) ? false : true"
    >
      <mat-toolbar>Menu</mat-toolbar>
      <mat-nav-list>
        <a mat-list-item *ngIf="loading" (click)="openCalendar()">
          <mat-icon class="icon" aria-hidden="false">event</mat-icon>
          Calendrier
        </a>

        <a
          mat-list-item
          *ngIf="
            loading &&
            (authenticationService.currentAuthenticationValue?.role?.label ===
              cleanerRole ||
              authenticationService.currentAuthenticationValue?.role?.label ===
                welcomerRole)
          "
          (click)="openMission()"
        >
          <mat-icon class="icon" aria-hidden="false">work</mat-icon>
          Missions
        </a>

        <a mat-list-item *ngIf="loading" (click)="openChat()">
          <mat-icon class="icon" aria-hidden="false">message</mat-icon>
          Messages
        </a>

        <a
          mat-list-item
          *ngIf="
            loading &&
            authenticationService.currentAuthenticationValue?.role?.label ===
              ownerRole
          "
          (click)="openLodgingList()"
        >
          <mat-icon class="icon" aria-hidden="false">apartment</mat-icon>
          Logements
        </a>

        <a mat-list-item *ngIf="loading" (click)="openCompany()">
          <mat-icon class="icon" aria-hidden="false">work</mat-icon>
          Sociétés
        </a>

        <a
          mat-list-item
          *ngIf="
            loading &&
            (authenticationService.currentAuthenticationValue?.role?.label ===
              cleanerRole ||
              authenticationService.currentAuthenticationValue?.role?.label ===
                welcomerRole)
          "
          (click)="openSearchPage()"
        >
          <mat-icon class="icon" aria-hidden="false">handshake</mat-icon>
          Recherche contrat
        </a>

        <a mat-list-item *ngIf="loading" (click)="logout()">
          <mat-icon class="icon" aria-hidden="false">logout</mat-icon>
          Déconnexion
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="loading && (width < 1024 || (isHandset$ | async))"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span id="title"> Symbiosis </span>
        <a id="profil" *ngIf="loading" (click)="openUserInformation()">
          <mat-icon aria-hidden="false" id="account-icon">
            account_box
          </mat-icon>
          <a *ngIf="width >= 1024" id="name">
            {{
              authenticationService.currentAuthenticationValue?.user?.firstname
            }}
            {{
              authenticationService.currentAuthenticationValue?.user?.lastname
            }}
          </a>
          <a
            id="role"
            *ngIf="
              width >= 1024 &&
              authenticationService.currentAuthenticationValue?.role?.label ===
                ownerRole
            "
            >(hôte)</a
          >
          <a
            id="role"
            *ngIf="
              width >= 1024 &&
              authenticationService.currentAuthenticationValue?.role?.label ===
                cleanerRole
            "
            >(agent d'entretien)</a
          >
        </a>
      </mat-toolbar>
      <ng-content id="ng-content"></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
