import { AuthenticationService } from '../authentication/authentication.service';
import { dialogusHubEndPoint } from 'src/environments/environment.api.endpoints';
import { HubProfile } from 'src/app/core/models/dialogus/hub-profile';
import { Message } from 'src/app/core/models/dialogus/message';
import { Chat } from 'src/app/core/models/dialogus/chat';
import { BehaviorSubject, Observable } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SignalRHubService {
  private chatListSubject: BehaviorSubject<Array<Chat> | null>;
  public chatListObservable: Observable<Array<Chat> | null>;
  public chatSubject: BehaviorSubject<Chat | null>;
  public chatObservable: Observable<Chat | null>;
  private hubConnection!: signalR.HubConnection;

  constructor(private authenticationService: AuthenticationService) {
    this.chatListSubject = new BehaviorSubject<Array<Chat> | null>(null);
    this.chatListObservable = this.chatListSubject.asObservable();
    this.chatSubject = new BehaviorSubject<Chat | null>(null);
    this.chatObservable = this.chatSubject.asObservable();
  }

  public get chatListValue(): Array<Chat> | null {
    return this.chatListSubject.value;
  }

  public get chatValue(): Chat | null {
    return this.chatSubject.value;
  }

  public buildConnection(
    url: string,
    endPoint?: string
  ): signalR.HubConnection {
    return (this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${url}${endPoint}`)
      .build());
  }

  public startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        this.join();
        this.getAllHubProfile();
        this.getAllUserChatByUser();
        console.log(`Connected`);
      })
      .catch((error) => console.error(error));
  }

  public stopConnection(): void {
    this.remove();
    this.getAllHubProfile();
    this.hubConnection
      .stop()
      .then(() => {
        console.log(`Disconnected`);
      })
      .catch((error) => console.error(error));
  }

  public sendPrivateMessage(
    message: string,
    from: number,
    to: number,
    lodgingId?: number
  ) {
    if (message != null) {
      this.hubConnection
        .invoke(
          dialogusHubEndPoint.sendPrivateMessageAsync,
          new Message(message, from, to, lodgingId)
        )
        .catch((error) => console.error(error));
    }
  }

  public setListener(hubEndPoint: string): void {
    this.setLocalStorageListener();
    this.setChatListListener();
    this.setMessageListener(hubEndPoint);
  }

  private setLocalStorageListener(): void {
    this.hubConnection.on(
      'OnlineUsers',
      (hubProfileList: Array<HubProfile>) => {
        localStorage.setItem('onlineUsers', JSON.stringify(hubProfileList));
      }
    );
  }

  private setChatListListener(): void {
    this.hubConnection.on('UserChatList', (chatList: Array<Chat>) => {
      this.chatListSubject.next(chatList);
    });
  }

  private setMessageListener(hubEndPoint: string): void {
    this.hubConnection.on(
      `${hubEndPoint}`,
      (connectionId: string, newMessage: Message) => {
        this.chatListValue?.forEach((chatList) => {
          if (chatList !== null && chatList !== undefined) {
            if (
              chatList.id === newMessage.chatId ||
              chatList.senderConnectionId === connectionId ||
              chatList.receiverConnectionId === connectionId
            ) {
              newMessage.isSender = this.isSender(newMessage);
              chatList.messages.push(newMessage);
            }
          }
        });
      }
    );
  }

  private join(): void {
    this.hubConnection
      .invoke(
        'Join',
        this.authenticationService.currentAuthenticationValue?.user?.id
      )
      .catch((error) => console.error(error));
  }

  private remove(): void {
    this.hubConnection
      .invoke(
        'Remove',
        this.authenticationService.currentAuthenticationValue?.user?.id
      )
      .catch((error) => console.error(error));
  }

  private getAllHubProfile(): void {
    this.hubConnection
      .invoke('GetAllHubProfileAsync')
      .catch((error) => console.error(error));
  }

  private getAllUserChatByUser(): void {
    this.hubConnection
      .invoke('GetAllChatByUserIdAsync')
      .catch((error) => console.error(error));
  }

  private isSender(message: Message): boolean {
    if (
      message.sender?.id ===
      this.authenticationService.currentAuthenticationValue?.user?.id
    ) {
      return true;
    }

    return false;
  }

  public getReceiverId(): number {
    if (
      this.chatValue?.firstContact.id ===
      this.authenticationService.currentAuthenticationValue?.user?.id!
    ) {
      return this.chatValue!.secondContact.id;
    } else {
      return this.chatValue!.firstContact.id;
    }
  }
}
