import { AddWorkerComponent } from 'src/app/shared/components/worker-list/add-worker/add-worker.component';
import { ConfirmPopupComponent } from 'src/app/shared/components/confirm-popup/confirm-popup.component';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { WorkerListComponent } from 'src/app/shared/components/worker-list/worker-list.component';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { AdminCompanyComponent } from '../../admin-company/admin-company.component';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { CompanyMember } from 'src/app/core/models/symbiosis/company-member';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { contentType } from 'src/environments/environment.content.types';
import { CompanyBuilder } from 'src/app/shared/builder/company-builder';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { routeEndPoint } from 'src/environments/environment.routes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthBuilder } from 'src/app/shared/builder/auth-builder';
import { Company } from 'src/app/core/models/symbiosis/company';
import { User } from 'src/app/core/models/symbiosis/user';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-line',
  templateUrl: './company-line.component.html',
  styleUrls: ['./company-line.component.scss'],
})
export class CompanyLineComponent implements OnInit {
  @Input() public company?: Company;

  private memberListDialogRef?: MatDialogRef<WorkerListComponent>;
  public searchableSlideToggle!: FormControl;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private companyBuilder: CompanyBuilder,
    private alertService: AlertService,
    private authBuilder: AuthBuilder,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchableSlideToggle = new FormControl(this.company?.searchable, [
      Validators.required,
    ]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width(): number {
    return window.innerWidth;
  }

  public editSearchable(): void {
    if (this.searchableSlideToggle.value === true) {
      this.searchableSlideToggle = new FormControl(true, [Validators.required]);
      this.company!.searchable = true;
    } else {
      this.searchableSlideToggle = new FormControl(false, [
        Validators.required,
      ]);
      this.company!.searchable = false;
    }

    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateCompany,
          this.company!.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(
          this.authBuilder.buildAuthenticationFromForm(
            new FormGroup({ searchable: this.searchableSlideToggle }),
            OperationType.edit,
            false,
            undefined,
            false,
            this.company?.id,
            true
          )
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
          this.setCompany(authentication, this.company?.id, OperationType.edit);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public onSubmit(): void {
    if (this.company != null) {
      this.router.navigate([routeEndPoint.company], {
        queryParams: this.company,
      });
    }
  }

  public manageMember(): void {
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.getCleanerListByLodgingId,
          this.authenticationService.currentAuthenticationValue?.user?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(this.company!.id)
      )
      .subscribe({
        next: (companyMember: CompanyMember) => {
          this.memberListDialogRef = this.dialog.open(WorkerListComponent, {
            width: '1200px',
            data: {
              enableWorkerLineFunction: true,
              enableModalFunction: true,
              workers: companyMember.members,
              icon: 'delete',
              workerLineDescription: 'Suppimer le lien',
            },
          });

          this.memberListDialogRef.componentInstance.workerLineFunction?.subscribe(
            (member) => {
              if (member) {
                this.deleteMemberLink(member);
              }
            }
          );
          this.memberListDialogRef.componentInstance.modalFunction?.subscribe(
            (open) => {
              if (open) {
                this.openUnlinkedMemberList();
              }
            }
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public editCompany(): void {
    const dialogRef = this.dialog.open(AdminCompanyComponent, {
      width: '1000px',
      data: this.company,
    });

    dialogRef.afterClosed().subscribe((companyForm: FormGroup) => {
      if (companyForm != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.updateCompany,
              this.company!.id
            ),
            HttpCallType.Request,
            httpCallMethod.put,
            contentType.application_json,
            JSON.stringify(
              this.authBuilder.buildAuthenticationFromForm(
                companyForm,
                OperationType.edit,
                false,
                undefined,
                false,
                this.company!.id,
                true
              )
            ).replace('/\\/', '')
          )
          .subscribe({
            next: (authentication: Authentication) => {
              this.authenticationService.setOrRefreshLocalStorage(
                authentication
              );
              this.setCompany(
                authentication,
                this.company?.id,
                OperationType.edit
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  public deleteCompany(): void {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer ${this.company?.label} ?`,
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.deleteCompany,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.delete,
            contentType.application_json,
            JSON.stringify(this.company)
          )
          .subscribe({
            next: (currentCompany: Company) => {
              this.authenticationService.currentAuthenticationValue?.companies?.forEach(
                (company: Company) => {
                  if (company.id === currentCompany.id) {
                    const index =
                      this.authenticationService.currentAuthenticationValue?.companies?.indexOf(
                        company,
                        0
                      );
                    if (index! > -1) {
                      this.authenticationService.currentAuthenticationValue?.companies?.splice(
                        index!,
                        1
                      );
                      this.authenticationService.setOrRefreshLocalStorage(
                        this.authenticationService.currentAuthenticationValue!
                      );
                    }
                  }
                }
              );
            },
            error: (error: HttpErrorResponse) => {
              this.alertService.error(error.message);
            },
          });
      }
    });
  }

  private openUnlinkedMemberList() {
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.getUnlinkedMemberList,
          this.company?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(
          this.authenticationService.currentAuthenticationValue?.user?.id
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (memberList: Array<User>) => {
          const addWorkerDialog = this.dialog.open(AddWorkerComponent, {
            width: '950px',
            data: {
              memberList: memberList,
              companyId: this.company?.id,
            },
          });

          addWorkerDialog.componentInstance.workerLineFunction?.subscribe(
            (member) => {
              if (member) {
                const confirmPopupDialog = this.dialog.open(
                  ConfirmPopupComponent,
                  {
                    width: '600px',
                    data: `Confirmer le lien avec ${member.firstname} ${member.lastname} ?`,
                  }
                );

                confirmPopupDialog
                  .afterClosed()
                  .subscribe((confirm: boolean) => {
                    if (confirm && member != null) {
                      this.httpClientService
                        .httpCall(
                          this.httpClientService.buildUrl(
                            symbiosisApi.baseUrl,
                            symbiosisEndPoint.createCompanyMemberLink,
                            this.authenticationService
                              .currentAuthenticationValue?.user?.id
                          ),
                          HttpCallType.Request,
                          httpCallMethod.post,
                          contentType.application_json,
                          JSON.stringify(
                            new CompanyMember(member.id, this.company?.id)
                          ).replace('/\\/', '')
                        )
                        .subscribe({
                          next: (addedCompanyMember: CompanyMember) => {
                            addWorkerDialog.componentInstance.workerList.forEach(
                              (member: User) => {
                                if (member.id === addedCompanyMember.memberId) {
                                  const index =
                                    addWorkerDialog.componentInstance.workerList.indexOf(
                                      member,
                                      0
                                    );
                                  if (index! > -1) {
                                    addWorkerDialog.componentInstance.workerList.splice(
                                      index!,
                                      1
                                    );
                                  }
                                  this.memberListDialogRef?.componentInstance.workers?.push(
                                    member
                                  );
                                }
                              }
                            );
                          },
                        });
                    }
                  });
              }
            }
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  private deleteMemberLink(member: User): void {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer le lien avec ${member.firstname} ${member.lastname} ?`,
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm && member != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.deleteCompanyMemberLink,
              this.company?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(member.id)
          )
          .subscribe({
            next: (currentCompanyMember: CompanyMember) => {
              if (this.memberListDialogRef) {
                this.memberListDialogRef.componentInstance.workers =
                  currentCompanyMember.members;
              }
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  private setCompany(
    authentication?: Authentication,
    companyId?: number,
    operationType?: OperationType
  ): void {
    this.company = this.companyBuilder.buildCompanyFromAuthenticaction(
      authentication ?? this.authenticationService.currentAuthenticationValue!,
      companyId,
      operationType
    );
  }
}
