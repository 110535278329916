import { AuthenticationService } from '../services/authentication/authentication.service';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { Address } from 'src/app/core/models/symbiosis/address';
import { User } from 'src/app/core/models/symbiosis/user';
import { Role } from 'src/app/core/models/symbiosis/role';
import { OperationType } from './enum/operation.type';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UserBuilder {
  constructor(private authenticationService: AuthenticationService) {}

  buildUserFromForm(form: FormGroup, operationType?: OperationType): User {
    let user = new User(
      form.get('firstname')?.value ??
        this.authenticationService.currentAuthenticationValue?.user?.firstname!,
      form.get('lastname')?.value ??
        this.authenticationService.currentAuthenticationValue?.user?.lastname!,
      form.get('birthDay')?.value ??
        this.authenticationService.currentAuthenticationValue?.user?.birthDay!,
      `+33${form
        .get('phoneNumber')
        ?.value.toString()
        .replace(/(?<=\d)[\s.]+(?=\d)/g, '')
        .substring(0, 10)}` ??
        this.authenticationService.currentAuthenticationValue?.user
          ?.phoneNumber!,
      form.get('email')?.value ??
        this.authenticationService.currentAuthenticationValue?.user?.email!,
      parseInt(
        form.get('gender')?.value ??
          this.authenticationService.currentAuthenticationValue?.user?.gender!
      ),
      form.get('avatar')?.value ??
        this.authenticationService.currentAuthenticationValue?.user?.avatar!,
      new Address(
        form.get('addressLabel')?.value ??
          this.authenticationService.currentAuthenticationValue?.address!.label,
        form.get('postalCode')?.value.toString() ??
          this.authenticationService.currentAuthenticationValue?.address!
            .postalCode,
        form.get('city')?.value ??
          this.authenticationService.currentAuthenticationValue?.address!.city,
        form.get('region')?.value ??
          this.authenticationService.currentAuthenticationValue?.address!
            .region,
        form.get('country')?.value ??
          this.authenticationService.currentAuthenticationValue?.address!
            .country
      )
    );
    if (operationType === OperationType.create) {
      user.role = new Role(
        form.get('roleLabel')?.value ??
          this.authenticationService.currentAuthenticationValue?.role!
      );
      user.password = form.get('password')?.value;
    }
    if (operationType === OperationType.edit) {
      user.id =
        this.authenticationService.currentAuthenticationValue?.user?.id!;
      user.address!.id =
        this.authenticationService.currentAuthenticationValue?.address?.id!;
      user.calendarFilters =
        this.authenticationService.currentAuthenticationValue?.user?.calendarFilters;
    }

    return user;
  }

  buildUserFromAuthenticaction(
    authentication: Authentication,
    operationType?: OperationType
  ): User {
    let user = new User(
      authentication?.user?.firstname!,
      authentication?.user?.lastname!,
      authentication?.user?.birthDay!,
      `0${authentication?.user?.phoneNumber?.toString().substring(3, 12)}`,
      authentication?.user?.email!,
      authentication?.user?.gender,
      authentication?.user?.avatar
    );
    let address = new Address(
      authentication?.address!.label,
      authentication?.address!.postalCode,
      authentication?.address!.city,
      authentication?.address!.region,
      authentication?.address!.country
    );
    if (operationType === OperationType.edit) {
      user.id = authentication.user?.id!;
      address.id = authentication?.address!.id;
    }
    user.address = address;

    return user;
  }
}
