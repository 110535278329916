import { AuthenticationService } from '../services/authentication/authentication.service';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { Address } from 'src/app/core/models/symbiosis/address';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { OperationType } from './enum/operation.type';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LodgingBuilder {
  constructor(private authenticationService: AuthenticationService) {}

  public buildLodging(
    lodgingForm?: FormGroup,
    operationType?: OperationType,
    lodgingId?: number,
    image?: string
  ): Lodging {
    let currentLodging: Lodging = this.getCurrentLodging(lodgingId);

    let lodImage = null;
    if (currentLodging) {
      lodImage = lodgingForm?.get('image')?.value ?? currentLodging.image;
    }

    let lodging = new Lodging(
      lodgingForm?.get('lodgingLabel')?.value ?? currentLodging!.label,
      lodgingForm?.get('lodgingSurfaceArea')?.value.toString() ??
        currentLodging!.surfaceArea,
      lodgingForm?.get('lodgingType')?.value ?? currentLodging!.lodgingType,
      lodgingForm?.get('description')?.value ?? currentLodging!.description,
      lodgingForm?.get('checkInStart')?.value ?? currentLodging!.checkInStart,
      lodgingForm?.get('checkOutEnd')?.value ?? currentLodging!.checkOutEnd,
      lodgingForm?.get('searchable')?.value ?? currentLodging!.searchable,
      lodgingForm?.get('image')?.value ?? lodImage,
      this.authenticationService.currentAuthenticationValue?.user?.id,
      new Address(
        lodgingForm?.get('lodgingAddressLabel')?.value ??
          currentLodging!.address?.label,
        lodgingForm?.get('lodgingPostalCode')?.value.toString() ??
          currentLodging!.address?.postalCode,
        lodgingForm?.get('lodgingCity')?.value ?? currentLodging!.address?.city,
        lodgingForm?.get('lodgingRegion')?.value ??
          currentLodging!.address?.region,
        lodgingForm?.get('lodgingCountry')?.value ??
          currentLodging!.address?.country
      )
    );
    if (currentLodging !== undefined) {
      lodging.calendars = currentLodging.calendars;
    }
    if (image !== undefined) {
      lodging.image = image;
    }
    if (
      operationType === OperationType.edit ||
      (lodgingId !== undefined && lodgingId > 0)
    ) {
      lodging.id = lodgingId!;
      lodging.welcomerForCheck = lodgingForm?.get('welcomerForCheck')?.value;
    }

    return lodging;
  }

  private getCurrentLodging(lodgingId: number | undefined): Lodging {
    let currentLodging: Lodging;
    if (lodgingId !== null) {
      this.authenticationService.currentAuthenticationValue?.lodgings?.forEach(
        (lodging: Lodging) => {
          if (lodging.id === lodgingId) {
            currentLodging = lodging;
          }
        }
      );
    }

    return currentLodging!;
  }

  buildLodgingFromAuthenticaction(
    authentication: Authentication,
    lodgingId?: number,
    operationType?: OperationType
  ): Lodging {
    const index = authentication.lodgings?.findIndex(
      (lodging: Lodging) => lodging.id == lodgingId
    );
    let authLodging = authentication.lodgings![index!];

    let lodging = new Lodging(
      authLodging?.label!,
      authLodging?.surfaceArea!,
      authLodging?.lodgingType!,
      authLodging?.description!,
      authLodging?.checkInStart!,
      authLodging?.checkOutEnd!,
      authLodging?.searchable!,
      authLodging?.image
    );
    let address = new Address(
      authLodging?.address!.label,
      authLodging?.address!.postalCode,
      authLodging?.address!.city,
      authLodging?.address!.region,
      authLodging?.address!.country
    );
    if (operationType === OperationType.edit) {
      lodging.id = authLodging?.id;
      address.id = authLodging?.address!.id;
    }
    lodging.address = address;
    lodging.calendars = authLodging?.calendars;
    lodging.welcomerForCheck = authLodging.welcomerForCheck;

    return lodging;
  }
}
