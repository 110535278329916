import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { SignalRHubService } from 'src/app/shared/services/signalR-hub/signalR-hub.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { routeEndPoint } from 'src/environments/environment.routes';
import { dialogusHub } from 'src/environments/environment.api.urls';
import { Component, HostListener, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/symbiosis/user';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import {
  dialogusEndPoint,
  dialogusHubEndPoint,
} from 'src/environments/environment.api.endpoints';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public returnUrl!: string;
  public submitted = false;
  public user!: User;
  public hidePassword = true;

  constructor(
    private authenticationService: AuthenticationService,
    private signalRHubService: SignalRHubService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) {
    if (this.authenticationService.currentAuthenticationValue) {
      this.router.navigate([
        `./${this.authenticationService.currentAuthenticationValue?.role?.label?.toLocaleLowerCase()}`,
      ]);
    }
  }

  ngOnInit(): void {
    this.spinnerService.requestEnded();
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get f() {
    return this.loginForm.controls;
  }

  public get width() {
    return window.innerWidth;
  }

  onSubmit(): void {
    this.submitted = true;
    this.alertService.clear();

    if (this.loginForm.invalid) {
      return;
    }

    this.spinnerService.requestStarted();

    setTimeout(() => {
      this.spinnerService.requestEnded();
    }, 4000);

    this.authenticationService
      .login(
        this.loginForm.get('email')?.value.toLowerCase().trim(),
        this.loginForm.get('password')?.value
      )
      .pipe(first())
      .subscribe({
        next: (authentication: Authentication | null) => {
          if (
            authentication?.role?.label !== null ||
            authentication?.role?.label !== undefined
          ) {
            this.signalRHubService.buildConnection(
              dialogusHub.baseUrl,
              dialogusEndPoint.message
            );
            this.signalRHubService.startConnection();
            this.signalRHubService.setListener(
              dialogusHubEndPoint.privateMessage
            );
            this.router.navigate([
              `./${authentication?.role?.label?.toLocaleLowerCase()}`,
            ]);
          }
          window.location.reload();
        },
        error: (error: HttpErrorResponse) => {
          this.spinnerService.requestEnded();
          this.alertService.error(error.message);
        },
      });
  }

  public goToRegister(): void {
    this.router.navigate([routeEndPoint.register]);
  }
}
