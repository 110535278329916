import { EntityBase } from '../base/entitybase';
import { User } from '../symbiosis/user';

export class Message extends EntityBase {
  public content?: string;
  public from?: number;
  public to?: number;
  public lodgingId?: number;
  public sendingDate?: Date;
  public sender?: User;
  public receiver?: User;
  public chatId?: number;
  public isSender?: boolean;

  constructor(
    content?: string,
    from?: number,
    to?: number,
    lodgingId?: number,
    sender?: User,
    receiver?: User,
    chatId?: number,
    isSender?: boolean
  ) {
    super();
    this.content = content;
    this.from = from;
    this.to = to;
    this.lodgingId = lodgingId;
    this.sender = sender;
    this.receiver = receiver;
    this.chatId = chatId;
    this.isSender = isSender;
  }
}
