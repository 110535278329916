<ng-container *ngIf="company" class="company-component">
  <div class="company-component__profile">
    <div class="company-component__profile__general-profil">
      <div
        [class]="
          width < 1024
            ? 'company-component__profile__general-profil__header --mobile'
            : 'company-component__profile__general-profil__header'
        "
      >
        <div
          *ngIf="width >= 1024"
          class="company-component__profile__general-profil__header__general-infos"
        >
          <h2>Informations générales</h2>
        </div>
        <div
          [class]="
            width < 1024
              ? 'company-component__profile__general-profil__header__buttons --mobile'
              : 'company-component__profile__general-profil__header__buttons'
          "
        >
          <mat-slide-toggle
            [formControl]="searchableSlideToggle"
            (change)="editSearchable()"
            [class]="
              width < 1024
                ? 'company-component__profile__general-profil__header__buttons__toggle --mobile'
                : 'company-component__profile__general-profil__header__buttons__toggle'
            "
            matTooltip="En activant l'option, 
                        la société devient visible dans les résultats de recherche 
                        des agents d'entretien."
          >
            Activer la recherche d'agents d'entretien
          </mat-slide-toggle>
          <button
            mat-raised-button
            class="company-component__profile__general-profil__header__buttons__admin-button --mobile"
            (click)="editCompany()"
          >
            <mat-icon
              class="company-component__profile__general-profil__header__buttons__admin-button__icon"
              aria-hidden="false"
              >edit</mat-icon
            >
            Modifier
          </button>
        </div>
        <div
          *ngIf="width < 1024"
          class="company-component__profile__general-profil__header__general-infos --mobile"
        >
          <h2>Informations générales</h2>
        </div>
      </div>
      <mat-grid-list
        class="company-component__profile__general-profil__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('NOM', company.label!)"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__general-profil__list__tile"
        >
          <div
            *ngIf="tile.text === 'NOM'"
            class="company-component__profile__general-profil__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.label"
            class="company-component__profile__general-profil__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <mat-divider class="company-component__profile__divider"></mat-divider>

    <div class="company-component__profile__contact-info">
      <h2>Caractéristiques</h2>
      <mat-grid-list
        class="company-component__profile__contact-info__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('SURFACE', company.siret!.toString())"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__contact-info__list__tile"
        >
          <div
            *ngIf="tile.text === 'SURFACE'"
            class="company-component__profile__contact-info__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.siret!.toString()"
            class="company-component__profile__contact-info__list__tile__edit-text"
          >
            {{ tile.text }} m²
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <mat-divider class="company-component__profile__divider"></mat-divider>

    <div class="company-component__profile__address">
      <h2>Adresse</h2>
      <mat-grid-list
        class="company-component__profile__address__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('RUE', company.address!.label)"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__address__list__tile"
        >
          <div
            *ngIf="tile.text === 'RUE'"
            class="company-component__profile__address__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.address!.label"
            class="company-component__profile__address__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list
        class="company-component__profile__address__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="
            let tile of setTiles('CODE POSTAL', company.address!.postalCode)
          "
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__address__list__tile"
        >
          <div
            *ngIf="tile.text === 'CODE POSTAL'"
            class="company-component__profile__address__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.address!.postalCode"
            class="company-component__profile__address__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list
        class="company-component__profile__address__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('VILLE', company.address!.city)"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__address__list__tile"
        >
          <div
            *ngIf="tile.text === 'VILLE'"
            class="company-component__profile__address__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.address!.city"
            class="company-component__profile__address__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list
        class="company-component__profile__address__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('REGION', company.address!.region)"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__address__list__tile"
        >
          <div
            *ngIf="tile.text === 'REGION'"
            class="company-component__profile__address__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.address!.region"
            class="company-component__profile__address__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list
        class="company-component__profile__address__list"
        [cols]="colsValue"
        [rowHeight]="rowHeightValue"
      >
        <mat-grid-tile
          *ngFor="let tile of setTiles('PAYS', company.address!.country)"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color"
          class="company-component__profile__address__list__tile"
        >
          <div
            *ngIf="tile.text === 'PAYS'"
            class="company-component__profile__address__list__tile__edit-title"
          >
            {{ tile.text }}
          </div>
          <div
            *ngIf="tile.text === company.address!.country"
            class="company-component__profile__address__list__tile__edit-text"
          >
            {{ tile.text }}
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <mat-divider class="company-component__profile__divider"></mat-divider>

    <section class="company-component__profile__image-section">
      <div class="company-component__profile__image-section__container">
        <img
          class="company-component__profile__image-section__container__image"
          mat-card-image
          [src]="companyArchive!.filePath"
          (error)="companyFileService.handleMissingImage(companyArchive!)"
          alt="Photo de profil"
        />
        <div>
          <button
            mat-flat-button
            class="company-component__profile__image-section__container__button"
            color="primary"
          >
            <input
              #selector
              type="file"
              id="select"
              class="company-component__profile__image-section__container__button__input"
              (change)="selectFile($event, company)"
            />
            <label
              class="company-component__profile__image-section__container__button__label"
              for="select"
              >Choisir une image</label
            >
          </button>
        </div>
      </div>
    </section>
  </div>
</ng-container>
