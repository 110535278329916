import { AlertMessage } from '../alert-message';
import { EntityBase } from '../base/entitybase';

export class Calendar extends EntityBase {
  public url: string;
  public lodgingId?: number;
  public alertMessage?: AlertMessage;

  constructor(url: string, lodgingId?: number) {
    super();
    this.url = url;
    this.lodgingId = lodgingId;
  }
}
