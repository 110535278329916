import { AuthenticationService } from '../authentication/authentication.service';
import { archivumEndPoint } from 'src/environments/environment.api.endpoints';
import { defaultFilePath } from 'src/environments/environment.file.path';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { HttpClientService } from '../httpclient/httpclient.service';
import { archivumApi } from 'src/environments/environment.api.urls';
import { HttpClientType } from '../httpclient/enum/httpClientType';
import { HttpCallType } from '../httpclient/enum/httpCallType';
import { Archive } from 'src/app/core/models/archivum/archive';
import { AuthBuilder } from '../../builder/auth-builder';
import { ImageType } from 'src/app/core/enum/image-type';
import { Observable, lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private authBuilder: AuthBuilder
  ) {}

  public uploadFileInArchivum(
    endPoint: string,
    formData: FormData,
    httpClientType?: HttpClientType,
    userId?: number
  ): Observable<any> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        archivumApi.baseUrl,
        endPoint,
        userId ??
          this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Post,
      httpCallMethod.post,
      undefined,
      undefined,
      formData,
      true,
      httpClientType
    );
  }

  public getArchive(guid: string): Observable<any> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        archivumApi.baseUrl,
        archivumEndPoint.download,
        this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Request,
      httpCallMethod.post,
      contentType.application_json,
      JSON.stringify(guid)
    );
  }

  public async getImage(
    guid: string,
    imageType: ImageType
  ): Promise<Archive | null> {
    let archive: Archive = this.getDefaultArchive(imageType);

    if (guid !== null) {
      archive.uniqueFileReference = guid;

      const observable = this.httpClientService.httpCall(
        this.httpClientService.buildUrl(
          archivumApi.baseUrl,
          archivumEndPoint.download,
          this.authenticationService.currentAuthenticationValue?.user?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(guid)
      );

      const result: Archive | null = await lastValueFrom(observable);

      if (result !== null) {
        archive.filePath = result.filePath;
      }
    }

    return archive;
  }

  public refreshLocalStorage(
    archive: Archive,
    imageType: ImageType,
    lodgingId?: number
  ) {
    this.authenticationService.setOrRefreshLocalStorage(
      this.authBuilder.updateUserAuthenticationFromArchive(
        this.authenticationService.currentAuthenticationValue!,
        archive,
        imageType,
        lodgingId
      )
    );
  }

  public refreshGuid(
    baseUrl: string,
    endpoint: string,
    id: number
  ): Observable<any> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(baseUrl, endpoint, id),
      HttpCallType.Request,
      httpCallMethod.put,
      contentType.application_json,
      JSON.stringify(this.authenticationService.currentAuthenticationValue)
    );
  }

  public setFormData(formData: FormData, archive: Archive): void {
    formData.append('filePath', archive!.filePath);
    formData.append('file', archive!.file!);

    if (archive.user !== undefined) {
      formData.append('userfirstname', archive!.user!.firstname!);
      formData.append('userlastname', archive!.user!.lastname!);
      formData.append(
        'userBirthDay',
        archive!.user!.birthDay!.toLocaleString()
      );
    }
  }

  public getDefaultArchive(imageType: ImageType): Archive {
    switch (imageType) {
      case ImageType.User:
        return new Archive(defaultFilePath.user_default_icon);
      case ImageType.Lodging:
        return new Archive(defaultFilePath.lodging_default_icon);
      case ImageType.Company:
        return new Archive(defaultFilePath.user_default_icon);
    }
  }
}
