<ng-container class="edit-user-component">
  <form
    [formGroup]="editUserForm"
    (keyup.enter)="onSubmit()"
    class="edit-user-component__edit-user-form"
  >
    <div
      [class]="
        width < 1024
          ? 'edit-user-component__edit-user-form__user-address --mobile'
          : 'edit-user-component__edit-user-form__user-address'
      "
    >
      <div
        [class]="
          width < 1024
            ? 'edit-user-component__edit-user-form__user-address__user-fields --mobile'
            : 'edit-user-component__edit-user-form__user-address__user-fields'
        "
      >
        <div
          class="edit-user-component__edit-user-form__user-address__user-fields__radio-group"
        >
          <mat-radio-group
            class="edit-user-component__edit-user-form__user-address__user-fields__radio-group__gender"
            formControlName="gender"
          >
            <mat-radio-button
              *ngFor="let radio of radios"
              class="edit-user-component__edit-user-form__user-address__user-fields__radio-group__gender__radio"
              [checked]="radio.checked"
              [value]="radio.value"
              name="{{ radio.name }}"
            >
              {{ radio.name }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Nom</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input__edit-user-firstname"
            type="text"
            formControlName="firstname"
            class="form-control"
            placeholder="Nom"
            [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }"
          />
          <div
            *ngIf="submitted && f['firstname'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['firstname'].errors['required']">
              Le nom est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Prénom</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input__edit-user-lastname"
            type="text"
            formControlName="lastname"
            class="form-control"
            placeholder="Prénom"
            [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }"
          />
          <div
            *ngIf="submitted && f['lastname'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['lastname'].errors['required']">
              Le prénom est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Date de naissance</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input__edit-user-birthDay"
            type="text"
            formControlName="birthDay"
            class="form-control"
            placeholder="Date de naissance"
            [ngClass]="{ 'is-invalid': submitted && f['birthDay'].errors }"
          />
          <div
            *ngIf="submitted && f['birthDay'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['birthDay'].errors['required']">
              La date de naissance est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Numéro de portable</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input__edit-user-phonenumber"
            type="text"
            formControlName="phoneNumber"
            class="form-control"
            placeholder="Numéro de mobile"
            [ngClass]="{ 'is-invalid': submitted && f['phoneNumber'].errors }"
          />
          <div
            *ngIf="submitted && f['phoneNumber'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['phoneNumber'].errors['required']">
              La numéro de mobile est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Adresse mail</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__user-fields__edit-user-input__edit-user-email"
            type="text"
            formControlName="email"
            class="form-control"
            placeholder="Adresse mail"
            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
          />
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']">
              L'adresse mail est requise
            </div>
            <div *ngIf="f['email'].errors['pattern']">
              L'adresse mail n'est pas au bon format
            </div>
          </div>
        </mat-form-field>
      </div>

      <div
        [class]="
          width < 1024
            ? 'edit-user-component__edit-user-form__user-address__address-fields --mobile'
            : 'edit-user-component__edit-user-form__user-address__address-fields'
        "
      >
        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Adresse</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input__edit-user-label"
            type="text"
            formControlName="addressLabel"
            class="form-control"
            placeholder="Adresse"
            [ngClass]="{ 'is-invalid': submitted && f['addressLabel'].errors }"
          />
          <div
            *ngIf="submitted && f['addressLabel'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['addressLabel'].errors['required']">
              L'adresse est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Code postal</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input__edit-user-code"
            type="text"
            formControlName="postalCode"
            class="form-control"
            placeholder="Code postal"
            [ngClass]="{ 'is-invalid': submitted && f['postalCode'].errors }"
          />
          <div
            *ngIf="submitted && f['postalCode'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['postalCode'].errors['required']">
              Le code postal est requis
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Ville</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input__edit-user-city"
            type="text"
            formControlName="city"
            class="form-control"
            placeholder="Ville"
            [ngClass]="{ 'is-invalid': submitted && f['city'].errors }"
          />
          <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
            <div *ngIf="f['city'].errors['required']">La ville est requise</div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Région</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input__edit-user-region"
            type="text"
            formControlName="region"
            class="form-control"
            placeholder="Région"
            [ngClass]="{ 'is-invalid': submitted && f['region'].errors }"
          />
          <div *ngIf="submitted && f['region'].errors" class="invalid-feedback">
            <div *ngIf="f['region'].errors['required']">
              La région est requise
            </div>
          </div>
        </mat-form-field>

        <mat-form-field
          class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input"
          appearance="fill"
        >
          <mat-label>Pays</mat-label>
          <input
            matInput
            class="edit-user-component__edit-user-form__user-address__address-fields__edit-user-input__edit-user-country"
            type="text"
            formControlName="country"
            class="form-control"
            placeholder="Pays"
            [ngClass]="{ 'is-invalid': submitted && f['country'].errors }"
          />
          <div
            *ngIf="submitted && f['country'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['country'].errors['required']">
              Le pays est requis
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div
      mat-dialog-actions
      class="edit-user-component__edit-user-form__buttons"
    >
      <button
        mat-button
        class="edit-user-component__edit-user-form__buttons__cancel-button"
        (click)="onClose()"
      >
        Annuler
      </button>
      <button
        mat-raised-button
        class="edit-user-component__edit-user-form__buttons__validate-button"
        (click)="onSubmit()"
      >
        <mat-icon>check_circle</mat-icon>
        Valider
      </button>
    </div>
  </form>
</ng-container>
