<ng-container class="alert-message-component">
  <p *ngIf="message.type === success" class="alert-message-component__success">
    {{ message.content }}
  </p>
  <p *ngIf="message.type === fail" class="alert-message-component__fail">
    {{ message.content }}
  </p>

  <button mat-button (click)="onClose()">Ok</button>
</ng-container>
