import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { Company } from 'src/app/core/models/symbiosis/company';
import { Archive } from 'src/app/core/models/archivum/archive';
import { User } from 'src/app/core/models/symbiosis/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArchiveBuilder {
  constructor() {}

  public buildArchive(
    archive: Archive,
    user?: User,
    lodging?: Lodging,
    Company?: Company
  ): Archive {
    return new Archive(
      archive?.filePath!,
      archive?.file!,
      archive?.fileType!,
      archive?.user! ?? user,
      archive?.lodging ?? lodging,
      archive?.company ?? Company,
      archive?.uniqueFileReference
    );
  }
}
