<ng-container class="lodging-line-component">
  <div class="lodging-line-component__lodging-line">
    <div class="lodging-line-component__admin-buttons">
      <mat-slide-toggle
        *ngIf="width < 1024"
        [id]="lodging!.id.toString()"
        [formControl]="searchableSlideToggle"
        (change)="editSearchable()"
        class="lodging-line-component__admin-buttons__admin-button"
        matTooltip="En activant l'option, 
        le logement devient visible dans les résultats de recherche 
        des agents d'entretien."
      >
        Recherche agent d'entretien
      </mat-slide-toggle>
      <button
        mat-raised-button
        (click)="onSubmit()"
        class="lodging-line-component__admin-buttons__lodging"
      >
        <mat-icon
          class="lodging-line-component__admin-buttons__lodging__icon"
          aria-hidden="false"
          >house</mat-icon
        >
        {{ lodging?.label }}
      </button>

      <button
        class="lodging-line-component__admin-buttons__admin-button"
        mat-raised-button
        (click)="manageCleaner()"
      >
        <mat-icon
          class="lodging-line-component__admin-buttons__admin-button__icon"
          aria-hidden="false"
          >link</mat-icon
        >
        Intervenants
      </button>

      <button
        class="lodging-line-component__admin-buttons__admin-button"
        mat-raised-button
        (click)="createCalendar()"
      >
        <mat-icon
          class="lodging-line-component__admin-buttons__admin-button__icon"
          aria-hidden="false"
          >save_alt</mat-icon
        >
        <span *ngIf="width > 1450 || width <= 1024"
          >Importer un calendrier</span
        >
      </button>

      <button
        class="lodging-line-component__admin-buttons__admin-button"
        mat-raised-button
        (click)="editLodging()"
      >
        <mat-icon
          class="lodging-line-component__admin-buttons__admin-button__icon"
          aria-hidden="false"
          >edit</mat-icon
        >
        <span *ngIf="width > 1450 || width <= 1024">Modifier</span>
      </button>

      <mat-slide-toggle
        *ngIf="width >= 1024"
        [id]="lodging!.id.toString()"
        [formControl]="searchableSlideToggle"
        (change)="editSearchable()"
        class="lodging-line-component__admin-buttons__admin-button"
        matTooltip="En activant l'option, 
          le logement devient visible dans les résultats de recherche 
          des agents d'entretien."
      >
        <span *ngIf="width > 1200">Recherche agent d'entretien</span>
      </mat-slide-toggle>

      <button
        mat-raised-button
        (click)="deleteLodging()"
        class="lodging-line-component__admin-buttons__basket"
        [matTooltip]="
          width < 1024 ? '' : 'Supprimer  définitivement le logement.'
        "
      >
        <mat-icon
          class="lodging-line-component__admin-buttons__basket__icon"
          aria-hidden="false"
          >delete</mat-icon
        >
      </button>
    </div>
  </div>

  <mat-divider class="lodging-line-component__divider"></mat-divider>
</ng-container>
