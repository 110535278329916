import { Calendar } from 'src/app/core/models/symbiosis/calendar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarBuilder {
  constructor() {}

  buildCalendar(iCalUrl: string, lodgingId?: number): Calendar {
    let calendar = new Calendar(iCalUrl, lodgingId);

    return calendar;
  }
}
