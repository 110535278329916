<ng-container class="company-line-component">
  <div class="company-line-component__company-line">
    <div class="company-line-component__admin-buttons">
      <mat-slide-toggle
        *ngIf="width < 1024"
        [id]="company!.id.toString()"
        [formControl]="searchableSlideToggle"
        (change)="editSearchable()"
        class="company-line-component__admin-buttons__admin-button"
        matTooltip="En activant l'option, 
          la société devient visible dans les résultats de recherche 
          des agents d'entretien."
      >
        Recherche agent d'entretien
      </mat-slide-toggle>
      <button
        mat-raised-button
        (click)="onSubmit()"
        class="company-line-component__admin-buttons__company"
      >
        <mat-icon
          class="company-line-component__admin-buttons__company__icon"
          aria-hidden="false"
          >house</mat-icon
        >
        {{ company?.label }}
      </button>

      <button
        class="company-line-component__admin-buttons__admin-button"
        mat-raised-button
        (click)="manageMember()"
      >
        <mat-icon
          class="company-line-component__admin-buttons__admin-button__icon"
          aria-hidden="false"
          >link</mat-icon
        >
        Intervenants
      </button>

      <button
        class="company-line-component__admin-buttons__admin-button"
        mat-raised-button
        (click)="editCompany()"
      >
        <mat-icon
          class="company-line-component__admin-buttons__admin-button__icon"
          aria-hidden="false"
          >edit</mat-icon
        >
        <span *ngIf="width > 1450 || width <= 1024">Modifier</span>
      </button>

      <mat-slide-toggle
        *ngIf="width >= 1024"
        [id]="company!.id.toString()"
        [formControl]="searchableSlideToggle"
        (change)="editSearchable()"
        class="company-line-component__admin-buttons__admin-button"
        matTooltip="En activant l'option, 
            la société devient visible dans les résultats de recherche 
            des agents d'entretien."
      >
        <span *ngIf="width > 1200">Recherche agent d'entretien</span>
      </mat-slide-toggle>

      <button
        mat-raised-button
        (click)="deleteCompany()"
        class="company-line-component__admin-buttons__basket"
        [matTooltip]="
          width < 1024 ? '' : 'Supprimer  définitivement la société.'
        "
      >
        <mat-icon
          class="company-line-component__admin-buttons__basket__icon"
          aria-hidden="false"
          >delete</mat-icon
        >
      </button>
    </div>
  </div>

  <mat-divider class="company-line-component__divider"></mat-divider>
</ng-container>
