import { AddWorkerComponent } from '../../../shared/components/worker-list/add-worker/add-worker.component';
import { ConfirmPopupComponent } from 'src/app/shared/components/confirm-popup/confirm-popup.component';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { ImportCalendarComponent } from '../../import-calendar/import-calendar.component';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { AdminLodgingComponent } from '../../admin-lodging/admin-lodging.component';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { LodgingCleaner } from 'src/app/core/models/symbiosis/lodging-cleaner';
import { Authentication } from 'src/app/core/models/symbiosis/authentication';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { CalendarBuilder } from 'src/app/shared/builder/calendar-builder';
import { contentType } from 'src/environments/environment.content.types';
import { LodgingBuilder } from 'src/app/shared/builder/lodging-builder';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { httpCallMethod } from 'src/environments/environment.methods';
import {
  ButtonColor,
  WorkerListComponent,
} from 'src/app/shared/components/worker-list/worker-list.component';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { routeEndPoint } from 'src/environments/environment.routes';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthBuilder } from 'src/app/shared/builder/auth-builder';
import { Calendar } from 'src/app/core/models/symbiosis/calendar';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { User } from 'src/app/core/models/symbiosis/user';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lodging-line',
  templateUrl: './lodging-line.component.html',
  styleUrls: ['./lodging-line.component.scss'],
})
export class LodgingLineComponent implements OnInit {
  @Input() public lodging?: Lodging;

  private cleanerListDialogRef?: MatDialogRef<WorkerListComponent>;
  public searchableSlideToggle!: FormControl;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService,
    private calendarBuilder: CalendarBuilder,
    private lodgingBuilder: LodgingBuilder,
    private alertService: AlertService,
    private authBuilder: AuthBuilder,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchableSlideToggle = new FormControl(this.lodging?.searchable, [
      Validators.required,
    ]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width(): number {
    return window.innerWidth;
  }

  public onSubmit(): void {
    if (this.lodging != null) {
      this.router.navigate([routeEndPoint.lodging], {
        queryParams: this.lodging,
      });
    }
  }

  public createCalendar(): void {
    const dialogRef = this.dialog.open(ImportCalendarComponent, {
      width: '600px',
      data: this.lodging,
    });

    dialogRef.afterClosed().subscribe((iCalUrl: any) => {
      if (iCalUrl != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.createCalendar,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(
              this.calendarBuilder.buildCalendar(iCalUrl, this.lodging?.id)
            )
          )
          .subscribe({
            next: (calendar: Calendar) => {
              this.authenticationService.currentAuthenticationValue?.lodgings?.forEach(
                (lodging: Lodging) => {
                  if (lodging.id === this.lodging?.id) {
                    if (lodging.calendars === null) {
                      lodging.calendars = new Array<Calendar>();
                    }
                    lodging?.calendars?.push(calendar);
                    this.lodging = lodging;
                  }
                }
              );
              this.authenticationService.setOrRefreshLocalStorage(
                this.authenticationService.currentAuthenticationValue!
              );
              window.location.reload();
            },
            error: (error: HttpErrorResponse) => {
              this.alertService.error(error.message);
            },
          });
      }
    });
  }

  public editLodging(): void {
    const dialogRef = this.dialog.open(AdminLodgingComponent, {
      width: '1000px',
      data: this.lodging,
    });

    dialogRef.afterClosed().subscribe((lodgingForm: FormGroup) => {
      if (lodgingForm != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.updateLodging,
              this.lodging!.id
            ),
            HttpCallType.Request,
            httpCallMethod.put,
            contentType.application_json,
            JSON.stringify(
              this.authBuilder.buildAuthenticationFromForm(
                lodgingForm,
                OperationType.edit,
                false,
                this.lodging!.id,
                true
              )
            ).replace('/\\/', '')
          )
          .subscribe({
            next: (authentication: Authentication) => {
              this.authenticationService.setOrRefreshLocalStorage(
                authentication
              );
              this.setLodging(
                authentication,
                this.lodging?.id,
                OperationType.edit
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  public manageCleaner(): void {
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.getCleanerListByLodgingId,
          this.authenticationService.currentAuthenticationValue?.user?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(this.lodging!.id)
      )
      .subscribe({
        next: (lodgingCleaner: LodgingCleaner) => {
          this.cleanerListDialogRef = this.dialog.open(WorkerListComponent, {
            width: '1200px',
            data: {
              enableWorkerLineFunction: true,
              enableModalFunction: true,
              workers: lodgingCleaner.cleaners,
              icon: 'delete',
              workerActionButtonColor: ButtonColor.red,
              workerLineDescription: 'Suppimer le lien',
              workerLineTooltip:
                "Supprimer le lien avec l'agent d'entretien ou le welcomer",
            },
          });

          this.cleanerListDialogRef.componentInstance.workerLineFunction?.subscribe(
            (cleaner) => {
              if (cleaner) {
                this.deleteCleanerLink(cleaner);
              }
            }
          );

          this.cleanerListDialogRef.componentInstance.modalFunction?.subscribe(
            (open) => {
              if (open) {
                this.openUnlinkedCleanerList();
              }
            }
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public editSearchable(): void {
    if (this.searchableSlideToggle.value === true) {
      this.searchableSlideToggle = new FormControl(true, [Validators.required]);
      this.lodging!.searchable = true;
    } else {
      this.searchableSlideToggle = new FormControl(false, [
        Validators.required,
      ]);
      this.lodging!.searchable = false;
    }

    let lodgingForm = new FormGroup({ searchable: this.searchableSlideToggle });
    let authentication = this.authBuilder.buildAuthenticationFromForm(
      lodgingForm,
      OperationType.edit,
      false,
      this.lodging!.id,
      true
    );
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateLodging,
          this.lodging!.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(authentication).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
          this.setLodging(authentication, this.lodging?.id, OperationType.edit);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public deleteLodging(): void {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer ${this.lodging?.label} ?`,
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.deleteLodging,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.delete,
            contentType.application_json,
            JSON.stringify(this.lodging)
          )
          .subscribe({
            next: (currentLodging: Lodging) => {
              this.authenticationService.currentAuthenticationValue?.lodgings?.forEach(
                (lodging: Lodging) => {
                  if (lodging.id === currentLodging.id) {
                    const index =
                      this.authenticationService.currentAuthenticationValue?.lodgings?.indexOf(
                        lodging,
                        0
                      );
                    if (index! > -1) {
                      this.authenticationService.currentAuthenticationValue?.lodgings?.splice(
                        index!,
                        1
                      );
                      this.authenticationService.setOrRefreshLocalStorage(
                        this.authenticationService.currentAuthenticationValue!
                      );
                    }
                  }
                }
              );
            },
            error: (error: HttpErrorResponse) => {
              this.alertService.error(error.message);
            },
          });
      }
    });
  }

  private openUnlinkedCleanerList() {
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.getUnlinkedCleanerList,
          this.lodging?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(
          this.authenticationService.currentAuthenticationValue?.user?.id
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (cleanerList: Array<User>) => {
          const addWorkerDialog = this.dialog.open(AddWorkerComponent, {
            width: '950px',
            data: {
              workerList: cleanerList,
              lodgingId: this.lodging?.id,
            },
          });

          addWorkerDialog.componentInstance.workerLineFunction?.subscribe(
            (cleaner) => {
              if (cleaner) {
                const confirmPopupDialog = this.dialog.open(
                  ConfirmPopupComponent,
                  {
                    width: '600px',
                    data: `Confirmer le lien avec ${cleaner.firstname} ${cleaner.lastname} ?`,
                  }
                );

                confirmPopupDialog
                  .afterClosed()
                  .subscribe((confirm: boolean) => {
                    if (confirm && cleaner != null) {
                      this.httpClientService
                        .httpCall(
                          this.httpClientService.buildUrl(
                            symbiosisApi.baseUrl,
                            symbiosisEndPoint.createLodgingCleanerLink,
                            this.authenticationService
                              .currentAuthenticationValue?.user?.id
                          ),
                          HttpCallType.Request,
                          httpCallMethod.post,
                          contentType.application_json,
                          JSON.stringify(
                            new LodgingCleaner(cleaner.id, this.lodging?.id)
                          ).replace('/\\/', '')
                        )
                        .subscribe({
                          next: (addedLodgingCleaner: LodgingCleaner) => {
                            addWorkerDialog.componentInstance.workerList.forEach(
                              (cleaner: User) => {
                                if (
                                  cleaner.id === addedLodgingCleaner.cleanerId
                                ) {
                                  const index =
                                    addWorkerDialog.componentInstance.workerList.indexOf(
                                      cleaner,
                                      0
                                    );
                                  if (index! > -1) {
                                    addWorkerDialog.componentInstance.workerList.splice(
                                      index!,
                                      1
                                    );
                                  }
                                  this.cleanerListDialogRef?.componentInstance.workers?.push(
                                    cleaner
                                  );
                                }
                              }
                            );
                          },
                        });
                    }
                  });
              }
            }
          );
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  private deleteCleanerLink(cleaner: User): void {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer le lien avec ${cleaner.firstname} ${cleaner.lastname} ?`,
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm && cleaner != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.deleteLodgingCleanerLink,
              this.lodging?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(cleaner.id)
          )
          .subscribe({
            next: (currentLodgingCleaner: LodgingCleaner) => {
              if (this.cleanerListDialogRef) {
                this.cleanerListDialogRef.componentInstance.workers =
                  currentLodgingCleaner.cleaners;
              }
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  private setLodging(
    authentication?: Authentication,
    lodgingId?: number,
    operationType?: OperationType
  ): void {
    this.lodging = this.lodgingBuilder.buildLodgingFromAuthenticaction(
      authentication ?? this.authenticationService.currentAuthenticationValue!,
      lodgingId,
      operationType
    );
  }
}
