import { AlertMessage } from '../alert-message';
import { EntityBase } from '../base/entitybase';
import { Address } from './address';
import { Lodging } from './lodging';
import { User } from './user';

export class Company extends EntityBase {
  label: string;
  siret: string;
  phoneNumber: string;
  email: string;
  description: string;
  managerId: number;
  searchable?: boolean;
  address?: Address;
  members?: Array<User>;
  lodgings?: Array<Lodging>;
  image?: string;
  alertMessage?: AlertMessage;

  constructor(
    label: string,
    siret: string,
    phoneNumber: string,
    email: string,
    description: string,
    managerId: number,
    searchable?: boolean,
    address?: Address
  ) {
    super();
    this.label = label;
    this.siret = siret;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.description = description;
    this.managerId = managerId;
    this.searchable = searchable;
    this.address = address;
  }
}
