// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const symbiosisEndPoint = {
  setMissionWelcomerResponse: '/WelcomerMission/SetMissionWelcomerResponse',
  getCleanerListByLodgingId: '/ManageCleaner/GetCleanerListByLodgingId',
  assignWelcomerToMission: '/WelcomerMission/AssignWelcomerToMission',
  unlinkWelcomerToMission: '/WelcomerMission/UnlinkWelcomerToMission',
  deleteLodgingCleanerLink: '/ManageCleaner/DeleteLodgingCleanerLink',
  createLodgingCleanerLinkFromChat: '/LodgingCleaner/CreateFromChat',
  deleteCompanyMemberLink: '/ManageCleaner/DeleteCompanyMemberLink',
  setMissionCleanerResponse: '/Mission/SetMissionCleanerResponse',
  getUnlinkedCleanerList: '/ManageCleaner/GetUnlinkedCleanerList',
  getUnlinkedMemberList: '/ManageMember/GetUnlinkedMemberList',
  assignCleanerToMission: '/Mission/AssignCleanerToMission',
  unlinkCleanerToMission: '/Mission/UnlinkCleanerToMission',
  getWelcomerMissionList: '/WelcomerMission/GetMissionList',
  getLodgingCleaners: '/LodgingCleaner/GetLodgingCleaners',
  welcomerPlanningRefresh: '/WelcomerPlanning/Refresh',
  cleanerPlanningRefresh: '/CleanerPlanning/Refresh',
  createLodgingCleanerLink: '/LodgingCleaner/Create',
  createCompanyMemberLink: '/CompanyMember/Create',
  refreshAccessToken: '/Login/RefreshAccessToken',
  ownerPlanningRefresh: '/OwnerPlanning/Refresh',
  getMissionList: '/Mission/GetMissionList',
  createCalendar: '/Calendar/Create',
  deleteCalendar: '/Calendar/Delete',
  searchLodging: '/Search/Lodging',
  createLodging: '/Lodging/Create',
  updateLodging: '/Lodging/Update',
  deleteLodging: '/Lodging/Delete',
  createCompany: '/Company/Create',
  updateCompany: '/Company/Update',
  deleteCompany: '/Company/Delete',
  createUser: '/Register/Create',
  updateUser: '/User/Update',
  login: '/Login/Connexion',
};

export const archivumEndPoint = {
  uploadRegister: '/Archive/Upload/Register',
  download: '/Archive/Download',
  upload: '/Archive/Upload',
};

export const dialogusEndPoint = {
  sendMessage: '/Message/SendMessage',
  message: '/Message',
};

export const dialogusHubEndPoint = {
  sendPrivateMessageAsync: 'SendPrivateMessageAsync',
  privateMessage: 'PrivateMessage',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
