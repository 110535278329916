import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { Archive } from 'src/app/core/models/archivum/archive';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcomer-event',
  templateUrl: './welcomer-event.component.html',
  styleUrls: ['./welcomer-event.component.scss'],
})
export class WelcomerEventComponent implements OnInit {
  @Input() public checkEvent?: FullCalendarEvent;
  @Input() public lodgingArchive?: Archive;
  @Input() public eventSelected?: boolean;

  constructor(public lodgingFileService: LodgingFileService) {}

  ngOnInit(): void {}
}
