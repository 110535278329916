import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { HttpClientService } from '../shared/services/httpclient/httpclient.service';
import { LodgingFileService } from '../shared/services/file/lodging-file.service';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { HttpCallType } from '../shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from '../shared/components/spinner/spinner.service';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { AlertService } from '../shared/services/alert/alert.service';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { FileService } from '../shared/services/file/file.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Housework } from '../core/models/symbiosis/housework';
import { Archive } from '../core/models/archivum/archive';
import { HttpErrorResponse } from '@angular/common/http';
import { Check } from '../core/models/symbiosis/check';
import { ImageType } from '../core/enum/image-type';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent implements OnInit {
  public houseworkList: Array<Housework> = new Array<Housework>();
  public checkList: Array<Check> = new Array<Check>();
  public lodgingArchiveList = new Array<Archive>();

  constructor(
    public authenticationService: AuthenticationService,
    public lodgingFileService: LodgingFileService,
    private httpClientService: HttpClientService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.refreshMission();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public refreshMission(): void {
    this.spinnerService.requestStarted();

    let endPoint =
      this.authenticationService.currentAuthenticationValue?.role?.label ===
      'Cleaner'
        ? symbiosisEndPoint.getMissionList
        : symbiosisEndPoint.getWelcomerMissionList;

    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          endPoint,
          this.authenticationService.currentAuthenticationValue?.user?.id
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(
          this.authenticationService.currentAuthenticationValue?.user?.id
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (eventList: Array<Housework | Check>) => {
          if (
            this.authenticationService.currentAuthenticationValue?.role
              ?.label === 'Cleaner'
          ) {
            this.setHouseworkList(eventList as Array<Housework>);
          }

          if (
            this.authenticationService.currentAuthenticationValue?.role
              ?.label === 'Welcomer'
          ) {
            this.setCheckList(eventList as Array<Check>);
          }

          this.spinnerService.requestEnded();
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.error(error.message);
          this.spinnerService.requestEnded();
        },
      });
  }

  private setHouseworkList(eventList: Array<Housework>) {
    this.houseworkList = eventList;
    this.houseworkList.forEach(async (housework) => {
      let archive = await this.fileService.getImage(
        housework.lodging?.image!,
        ImageType.Lodging
      );
      if (archive !== null) {
        archive.lodging = housework.lodging;
      }
      if (!this.lodgingArchiveList.includes(archive!)) {
        this.lodgingArchiveList.push(archive!);
      }
    });
  }

  private setCheckList(eventList: Array<Check>) {
    this.checkList = eventList;
    this.checkList.forEach(async (check) => {
      let archive = await this.fileService.getImage(
        check.lodging?.image!,
        ImageType.Lodging
      );
      if (archive !== null) {
        archive.lodging = check.lodging;
      }
      if (!this.lodgingArchiveList.includes(archive!)) {
        this.lodgingArchiveList.push(archive!);
      }
    });
  }
}
