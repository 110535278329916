import { CleanerEventPopupComponent } from '../cleaner-event-popup/cleaner-event-popup.component';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Archive } from 'src/app/core/models/archivum/archive';

@Component({
  selector: 'app-welcomer-event-popup',
  templateUrl: './welcomer-event-popup.component.html',
  styleUrls: ['./welcomer-event-popup.component.scss'],
})
export class WelcomerEventPopupComponent implements OnInit {
  @Input() public checkEvent?: FullCalendarEvent;
  @Input() public lodgingArchive?: Archive;
  @Input() public eventSelected?: boolean;

  constructor(
    private dialogRef: MatDialogRef<CleanerEventPopupComponent>,
    public lodgingFileService: LodgingFileService,
    @Inject(MAT_DIALOG_DATA) public details: any
  ) {
    this.lodgingArchive = details.lodgingArchive;
    this.eventSelected = details.eventSelected;
    this.checkEvent = details.checkEvent;
  }

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close(true);
  }
}
