<ng-container class="mission-component">
  <app-housework-mission
    *ngIf="
      authenticationService.currentAuthenticationValue?.role?.label ===
      'Cleaner'
    "
    [houseworkList]="houseworkList"
    [lodgingArchiveList]="lodgingArchiveList"
  ></app-housework-mission>
  <app-check-mission
    *ngIf="
      authenticationService.currentAuthenticationValue?.role?.label ===
      'Welcomer'
    "
    [checkList]="checkList"
    [lodgingArchiveList]="lodgingArchiveList"
  ></app-check-mission>
</ng-container>
