import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Archive } from 'src/app/core/models/archivum/archive';

@Component({
  selector: 'app-cleaner-event-popup',
  templateUrl: './cleaner-event-popup.component.html',
  styleUrls: ['./cleaner-event-popup.component.scss'],
})
export class CleanerEventPopupComponent implements OnInit {
  @Input() public houseworkEvent?: FullCalendarEvent;
  @Input() public lodgingArchive?: Archive;
  @Input() public eventSelected?: boolean;

  constructor(
    private dialogRef: MatDialogRef<CleanerEventPopupComponent>,
    public lodgingFileService: LodgingFileService,
    @Inject(MAT_DIALOG_DATA) public details: any
  ) {
    this.houseworkEvent = details.houseworkEvent;
    this.lodgingArchive = details.lodgingArchive;
    this.eventSelected = details.eventSelected;
  }

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close(true);
  }
}
