import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { Archive } from 'src/app/core/models/archivum/archive';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaner-event',
  templateUrl: './cleaner-event.component.html',
  styleUrls: ['./cleaner-event.component.scss'],
})
export class CleanerEventComponent implements OnInit {
  @Input() public houseworkEvent?: FullCalendarEvent;
  @Input() public lodgingArchive?: Archive;
  @Input() public eventSelected?: boolean;

  constructor(public lodgingFileService: LodgingFileService) {}

  ngOnInit(): void {}
}
