import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { SignalRHubService } from 'src/app/shared/services/signalR-hub/signalR-hub.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { Message } from 'src/app/core/models/dialogus/message';
import { HttpErrorResponse } from '@angular/common/http';
import { Chat } from 'src/app/core/models/dialogus/chat';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DatePipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

registerLocaleData(localeFr);

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }, DatePipe],
})
export class DialogComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  @ViewChild('messageInput') messageInput: any;

  public messageList = new Array<Message>();
  public message?: string;

  constructor(
    private authenticationService: AuthenticationService,
    private signalRHubService: SignalRHubService,
    private alertService: AlertService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.scrollToBottom();
    this.signalRHubService.chatObservable.subscribe({
      next: (chat: Chat | null) => {
        chat?.messages.forEach((message) => {
          message.isSender =
            message.from ===
            this.authenticationService.currentAuthenticationValue?.user?.id!
              ? true
              : false;
          this.datePipe.transform(
            message.sendingDate,
            'dd/MM/yyyy HH:mm',
            'fr'
          );
        });
        this.messageList = chat?.messages!;
        this.messageList.sort(
          (b, a) =>
            new Date(b.sendingDate!).getTime() -
            new Date(a.sendingDate!).getTime()
        );
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.error(error.message);
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  public sendPrivateMessage(): void {
    if (this.message != null) {
      this.signalRHubService.sendPrivateMessage(
        this.message,
        this.authenticationService.currentAuthenticationValue?.user?.id!,
        this.signalRHubService.getReceiverId()
      );
      this.messageInput.nativeElement.value = ' ';
    }
  }

  private scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
