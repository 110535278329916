import { AlertMessage } from '../alert-message';
import { EntityBase } from '../base/entitybase';
import { User } from './user';

export class LodgingCleaner extends EntityBase {
  public cleanerId?: number;
  public lodgingId?: number;
  public cleaners?: Array<User>;
  public alertMessage?: AlertMessage;

  constructor(
    cleanerId?: number,
    lodgingId?: number,
    cleaners?: Array<User>,
    alertMessage?: AlertMessage
  ) {
    super();
    this.cleanerId = cleanerId;
    this.lodgingId = lodgingId;
    this.cleaners = cleaners;
    this.alertMessage = alertMessage;
  }
}
