import { AuthenticationService } from '../authentication/authentication.service';
import { WorkerResponse } from 'src/app/core/models/symbiosis/worker-response';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { LodgingCleaner } from 'src/app/core/models/symbiosis/lodging-cleaner';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { contentType } from 'src/environments/environment.content.types';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { HttpClientService } from '../httpclient/httpclient.service';
import { Housework } from 'src/app/core/models/symbiosis/housework';
import { HttpCallType } from '../httpclient/enum/httpCallType';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  constructor(
    private authenticationService: AuthenticationService,
    private httpClientService: HttpClientService
  ) {}

  public response(
    eventId: number,
    lodgingId: number,
    workerId: number,
    endPoint: string,
    accepted: boolean
  ): Observable<Housework> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        symbiosisApi.baseUrl,
        endPoint,
        this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Request,
      httpCallMethod.post,
      contentType.application_json,
      JSON.stringify(new WorkerResponse(eventId, workerId, lodgingId, accepted))
    );
  }

  public showLodgingCleanerList(
    houseworkEvent: FullCalendarEvent
  ): Observable<LodgingCleaner> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        symbiosisApi.baseUrl,
        symbiosisEndPoint.getCleanerListByLodgingId,
        this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Request,
      httpCallMethod.post,
      contentType.application_json,
      JSON.stringify(houseworkEvent?.housework?.lodging?.id)
    );
  }

  public unlinkCleanerToMission(
    cleanerResponse: WorkerResponse
  ): Observable<LodgingCleaner> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        symbiosisApi.baseUrl,
        symbiosisEndPoint.unlinkCleanerToMission,
        this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Request,
      httpCallMethod.post,
      contentType.application_json,
      JSON.stringify(cleanerResponse)
    );
  }

  public unlinkWelcomerToMission(
    welcomerResponse: WorkerResponse
  ): Observable<LodgingCleaner> {
    return this.httpClientService.httpCall(
      this.httpClientService.buildUrl(
        symbiosisApi.baseUrl,
        symbiosisEndPoint.unlinkWelcomerToMission,
        this.authenticationService.currentAuthenticationValue?.user?.id
      ),
      HttpCallType.Request,
      httpCallMethod.post,
      contentType.application_json,
      JSON.stringify(welcomerResponse)
    );
  }
}
