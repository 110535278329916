import { ConfirmPopupComponent } from '../../../confirm-popup/confirm-popup.component';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { CalendarService } from 'src/app/shared/services/calendar/calendar.service';
import { UserFileService } from 'src/app/shared/services/file/user-file.service';
import { MissionService } from 'src/app/shared/services/mission/mission.service';
import { WorkerResponse } from 'src/app/core/models/symbiosis/worker-response';
import { LodgingCleaner } from 'src/app/core/models/symbiosis/lodging-cleaner';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { Housework } from 'src/app/core/models/symbiosis/housework';
import { Archive } from 'src/app/core/models/archivum/archive';
import { Check } from 'src/app/core/models/symbiosis/check';
import { User } from 'src/app/core/models/symbiosis/user';
import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import {
  ButtonColor,
  WorkerListComponent,
} from '../../../worker-list/worker-list.component';

@Component({
  selector: 'app-owner-event',
  templateUrl: './owner-event.component.html',
  styleUrls: ['./owner-event.component.scss'],
})
export class OwnerEventComponent implements OnInit {
  @Input() public reservationEvent?: FullCalendarEvent;
  @Input() public houseworkEvent?: FullCalendarEvent;
  @Input() public checkEvent?: FullCalendarEvent;
  @Input() public welcomerArchive?: Archive;
  @Input() public lodgingArchive?: Archive;
  @Input() public cleanerArchive?: Archive;
  @Input() public eventSelected?: boolean;

  constructor(
    public lodgingFileService: LodgingFileService,
    public calendarService: CalendarService,
    public userFileService: UserFileService,
    private missionService: MissionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public showLodgingCleanerList(): void {
    if (this.houseworkEvent) {
      this.missionService
        .showLodgingCleanerList(this.houseworkEvent)
        .subscribe({
          next: (lodgingCleaner: LodgingCleaner) => {
            const cleanerListDialog = this.dialog.open(WorkerListComponent, {
              width: '1200px',
              data: {
                enableWorkerLineFunction: true,
                workers: lodgingCleaner.cleaners,
                icon: 'add',
                workerActionButtonColor: ButtonColor.green,
                workerLineDescription: 'Affecter à la mission',
                workerLineTooltip: "Affecter l'agent d'entretien à la mission",
              },
            });

            cleanerListDialog.componentInstance.workerLineFunction?.subscribe(
              (worker: User) => {
                const confirmPopupDialog = this.dialog.open(
                  ConfirmPopupComponent,
                  {
                    width: '600px',
                    data: `Affilier ${worker.firstname} ${worker.lastname} à cette mission ?`,
                  }
                );

                confirmPopupDialog
                  .afterClosed()
                  .subscribe((confirm: boolean) => {
                    if (
                      confirm &&
                      worker &&
                      worker.role?.label === 'Cleaner' &&
                      this.houseworkEvent?.housework?.id &&
                      this.houseworkEvent?.housework?.lodging?.id
                    ) {
                      this.missionService
                        .response(
                          this.houseworkEvent?.housework?.id,
                          this.houseworkEvent?.housework?.lodging?.id,
                          worker?.id!,
                          symbiosisEndPoint.assignCleanerToMission,
                          true
                        )
                        .subscribe({
                          next: (housework: Housework) => {
                            if (this.houseworkEvent?.housework) {
                              this.houseworkEvent.housework = housework;
                              this.calendarService.refreshCalendar(
                                this.calendarService.refreshCalendarParameters
                              );
                              cleanerListDialog.close();
                            }
                          },
                          error: (error: ErrorCallback) => {
                            console.error(error);
                          },
                        });
                    }

                    if (
                      confirm &&
                      worker &&
                      worker.role?.label === 'Welcomer' &&
                      this.checkEvent?.check?.id &&
                      this.checkEvent?.check?.lodging?.id
                    ) {
                      this.missionService
                        .response(
                          this.checkEvent?.check?.id,
                          this.checkEvent?.check?.lodging?.id,
                          worker?.id!,
                          symbiosisEndPoint.assignWelcomerToMission,
                          true
                        )
                        .subscribe({
                          next: (check: Check) => {
                            if (this.checkEvent?.check) {
                              this.checkEvent.check = check;
                              this.calendarService.refreshCalendar(
                                this.calendarService.refreshCalendarParameters
                              );
                              cleanerListDialog.close();
                            }
                          },
                          error: (error: ErrorCallback) => {
                            console.error(error);
                          },
                        });
                    }
                  });
              }
            );
          },
          error: (error: HttpErrorResponse) => {
            console.error(error);
          },
        });
    }
  }

  public unlinkCleanerToMission(): void {
    const confirmPopupDialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer ${this.houseworkEvent?.housework?.cleaner?.firstname} ${this.houseworkEvent?.housework?.cleaner?.lastname} de cette mission ?`,
    });

    confirmPopupDialog.afterClosed().subscribe((confirm: boolean) => {
      if (
        confirm &&
        this.houseworkEvent?.housework?.cleaner &&
        this.houseworkEvent?.housework?.id &&
        this.houseworkEvent?.housework?.lodging?.id
      ) {
        this.missionService
          .unlinkCleanerToMission(
            new WorkerResponse(
              this.houseworkEvent?.housework?.id,
              this.houseworkEvent?.housework?.cleaner?.id,
              this.houseworkEvent?.housework?.lodging?.id
            )
          )
          .subscribe({
            next: (housework: Housework) => {
              if (this.houseworkEvent?.housework) {
                this.houseworkEvent.housework = housework;
                this.calendarService.refreshCalendar(
                  this.calendarService.refreshCalendarParameters
                );
              }
            },
            error: (error: ErrorCallback) => {
              console.error(error);
            },
          });
      }
    });
  }

  public unlinkWelcomerToMission(): void {
    const confirmPopupDialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer ${this.checkEvent?.check?.welcomer?.firstname} ${this.checkEvent?.check?.welcomer?.lastname} de cette mission ?`,
    });

    confirmPopupDialog.afterClosed().subscribe((confirm: boolean) => {
      if (
        confirm &&
        this.checkEvent?.check?.welcomer &&
        this.checkEvent?.check?.id &&
        this.checkEvent?.check?.lodging?.id
      ) {
        this.missionService
          .unlinkWelcomerToMission(
            new WorkerResponse(
              this.checkEvent?.check?.id,
              this.checkEvent?.check?.welcomer?.id,
              this.checkEvent?.check?.lodging?.id
            )
          )
          .subscribe({
            next: (check: Check) => {
              if (this.checkEvent?.check) {
                this.checkEvent.check = check;
                this.calendarService.refreshCalendar(
                  this.calendarService.refreshCalendarParameters
                );
              }
            },
            error: (error: ErrorCallback) => {
              console.error(error);
            },
          });
      }
    });
  }
}
