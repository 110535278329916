import { AlertMessageComponent } from '../components/alert-message/alert-message.component';
import { SpinnerService } from '../components/spinner/spinner.service';
import { AlertMessage } from 'src/app/core/models/alert-message';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';

@Injectable()
export class ApiMessageInterceptor implements HttpInterceptor {
  constructor(
    private spinnerService: SpinnerService,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        let alertMessage = this.defineTheRightAlertMessage(event);
        if (alertMessage !== null) {
          const dialog = this.dialog.open(AlertMessageComponent, {
            width: '600px',
            data: alertMessage,
          });

          dialog.afterClosed().subscribe({
            next: () => {
              this.spinnerService.resetSpinner();
            },
          });
        }
      })
    );
  }

  private defineTheRightAlertMessage(
    event: HttpEvent<any>
  ): AlertMessage | null {
    if (
      event instanceof HttpResponse &&
      event.body.alertMessage != undefined &&
      event.body.alertMessage instanceof AlertMessage
    ) {
      return event.body.alertMessage;
    } else if (
      event instanceof HttpResponse &&
      event.body != undefined &&
      event.body instanceof AlertMessage
    ) {
      return event.body;
    } else {
      return null;
    }
  }
}
