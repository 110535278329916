import { FullCalendarEvent } from 'src/app/core/models/full.calendar.event';
import { Reservation } from 'src/app/core/models/symbiosis/reservation';
import { Housework } from 'src/app/core/models/symbiosis/housework';
import { Event } from 'src/app/core/models/symbiosis/event';
import { Check } from 'src/app/core/models/symbiosis/check';
import { UserTarget } from './enum/user.target ';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

enum EventType {
  reservation,
  housework,
  check,
}

enum CheckType {
  in,
  out,
}

@Injectable({
  providedIn: 'root',
})
export class FullCalendarEventBuilder {
  public fullCalendarEventList = new Subject<Array<FullCalendarEvent>>();

  constructor() {}

  public getCurrentFullCalendarEventList(): Observable<
    Array<FullCalendarEvent>
  > {
    return this.fullCalendarEventList.asObservable();
  }

  public buildFullCalendarListFromReservationList(
    eventList: Array<unknown>,
    userTarget: UserTarget
  ): Array<FullCalendarEvent> {
    let fullCalendarEventList = new Array();
    eventList.map((event: unknown) => {
      let eve = event as Event;
      if (userTarget === UserTarget.cleaner) {
        if (eve.isHousework) {
          let housework = event as Housework;
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(housework, userTarget, EventType.housework),
              this.setColor(userTarget, housework, EventType.housework),
              housework.eventDate,
              undefined,
              this.getPlatformLabel(housework.uniqueReference),
              this.getEventStatus(housework.summary),
              undefined,
              housework,
              undefined
            )
          );
        }
      } else if (userTarget === UserTarget.welcomer) {
        if (eve.isCheck) {
          let check = event as Check;
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(check, userTarget, EventType.check, CheckType.in),
              this.setColor(userTarget, check, EventType.check),
              check.in,
              undefined,
              this.getPlatformLabel(check.uniqueReference),
              this.getEventStatus(check.summary),
              undefined,
              undefined,
              check
            )
          );
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(check, userTarget, EventType.check, CheckType.out),
              this.setColor(userTarget, check, EventType.check, true),
              check.out,
              undefined,
              this.getPlatformLabel(check.uniqueReference),
              this.getEventStatus(check.summary),
              undefined,
              undefined,
              check
            )
          );
        }
      } else if (userTarget === UserTarget.owner) {
        if (eve.isReservation) {
          let reservation = event as Reservation;
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(reservation, userTarget, EventType.reservation),
              this.setColor(userTarget, reservation, EventType.reservation),
              reservation.start,
              reservation.end,
              this.getPlatformLabel(reservation.uniqueReference),
              this.getEventStatus(reservation.summary),
              reservation,
              undefined,
              undefined
            )
          );
        }

        if (eve.isHousework) {
          let housework = event as Housework;
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(housework, userTarget, EventType.housework),
              this.setColor(userTarget, housework, EventType.housework),
              housework.eventDate,
              undefined,
              this.getPlatformLabel(housework.uniqueReference),
              this.getEventStatus(housework.summary),
              undefined,
              housework,
              undefined
            )
          );
        }

        if (eve.isCheck) {
          let check = event as Check;
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(check, userTarget, EventType.check, CheckType.in),
              this.setColor(userTarget, check, EventType.check),
              check.in,
              undefined,
              this.getPlatformLabel(check.uniqueReference),
              this.getEventStatus(check.summary),
              undefined,
              undefined,
              check
            )
          );
          fullCalendarEventList.push(
            new FullCalendarEvent(
              this.setTitle(check, userTarget, EventType.check, CheckType.out),
              this.setColor(userTarget, check, EventType.check),
              check.out,
              undefined,
              this.getPlatformLabel(check.uniqueReference),
              this.getEventStatus(check.summary),
              undefined,
              undefined,
              check
            )
          );
        }
      }
    });
    this.fullCalendarEventList.next(fullCalendarEventList);

    return fullCalendarEventList;
  }

  private setTitle(
    event: Reservation | Housework | Check,
    userTarget: UserTarget,
    eventType: EventType,
    checkType?: CheckType
  ): string | undefined {
    let label = '';

    label = this.setProgrammedMissionTitleForWorker(
      userTarget,
      event,
      label,
      checkType
    );

    label = this.setNewMissionTitleForOwner(
      userTarget,
      event,
      label,
      eventType,
      checkType!
    );

    label = this.setProgrammedMissionTitleForOwner(
      userTarget,
      event,
      label,
      eventType,
      checkType!
    );

    label = this.setCalendarTypeReservationTitle(
      userTarget,
      event as Reservation,
      label
    );

    label = this.setCanceledEventTitle(
      userTarget,
      event,
      label,
      eventType,
      checkType
    );

    label = this.setNotAvailableEvent(event as Reservation, label);

    label = this.setProgrammedReservation(event as Reservation, label);

    return label;
  }

  private setProgrammedReservation(event: Reservation, label: string) {
    if (
      event.summary?.toLowerCase().includes('reserved') ||
      (event.summary?.toLowerCase().includes('closed') &&
        !event.summary?.toLowerCase().includes('not available'))
    ) {
      label += ' / Loué';
    }

    return label;
  }

  private setNotAvailableEvent(event: Reservation, label: string) {
    if (event.summary?.toLowerCase().includes('not available')) {
      label += ' / Vérrouillé';
    }

    return label;
  }

  private setCanceledEventTitle(
    userTarget: UserTarget,
    event: Reservation | Housework | Check,
    label: string,
    eventType?: EventType,
    checkType?: CheckType
  ) {
    if (userTarget === UserTarget.welcomer) {
      let check = event as Check;

      if (checkType === CheckType.in) {
        label += `Check-in: ${check.summary} / ${check.lodging?.label}: ${check.lodging?.address?.label} ${check.lodging?.address?.city} / ${check.summary}`;
      } else {
        label += `Check-in: ${check.summary} / ${check.lodging?.label}: ${check.lodging?.address?.label} ${check.lodging?.address?.city} / ${check.summary}`;
      }
    }

    if (userTarget === UserTarget.cleaner) {
      let housework = event as Housework;

      if (event.summary === 'Annulé' && housework.eventDate != null) {
        label += `${housework.summary} / ${housework.lodging?.label}: ${housework.lodging?.address?.label} ${housework.lodging?.address?.city} / ${housework.summary}`;
      }
    }

    if (userTarget === UserTarget.owner) {
      if (eventType === EventType.reservation) {
        let reservation = event as Reservation;

        if (reservation.summary === 'Annulé') {
          label += ` / ${reservation.summary}`;
        }
      }

      if (eventType === EventType.housework) {
        let housework = event as Housework;

        if (event.summary!.includes('Annulé') && housework.eventDate != null) {
          label = `MENAGE: ${housework.summary} / ${housework.lodging?.label}: ${housework.lodging?.address?.label} ${housework.lodging?.address?.city} / ${housework.summary}`;
        }

        if (event.summary === 'Annulé' && housework.eventDate == null) {
          label += `/ ${housework.summary}`;
        }
      }

      if (eventType === EventType.check) {
        let check = event as Check;
        if (checkType === CheckType.in) {
          label = `Check-in: ${check.summary} / ${check.lodging?.label}`;
        } else {
          label = `Check-out: ${check.summary} / ${check.lodging?.label}`;
        }
      }
    }

    return label;
  }

  private setCalendarTypeReservationTitle(
    userTarget: UserTarget,
    reservation: Reservation,
    label: string
  ) {
    if (
      userTarget === UserTarget.owner &&
      !reservation.summary?.includes('Programmé') &&
      !reservation.summary?.includes('Nouveau')
    ) {
      if (reservation.uniqueReference?.toLowerCase().includes('airbnb')) {
        label += `${reservation.label}: Airbnb`;
      } else if (
        reservation.uniqueReference?.toLowerCase().includes('booking')
      ) {
        label += `${reservation.label}: Booking`;
      }
    }

    return label;
  }

  private setProgrammedMissionTitleForOwner(
    userTarget: UserTarget,
    event: Reservation | Housework,
    label: string,
    eventType: EventType,
    checkType: CheckType
  ) {
    if (
      eventType === EventType.housework &&
      userTarget === UserTarget.owner &&
      event.summary?.includes('Programmé')
    ) {
      let housework = event as Housework;
      label = `MENAGE: ${housework.summary} / ${housework.lodging?.label} / ${housework.cleaner?.firstname} ${housework.cleaner?.lastname} / ${housework.cleaner?.phoneNumber} / ${housework.cleaner?.email}`;
    }

    if (
      eventType === EventType.check &&
      userTarget === UserTarget.owner &&
      event.summary?.includes('Programmé')
    ) {
      let check = event as Check;
      if (checkType === CheckType.in) {
        label = `Check-in: ${check.summary} / ${check.lodging?.label} / ${check.welcomer?.firstname} ${check.welcomer?.lastname} / ${check.welcomer?.phoneNumber} / ${check.welcomer?.email}`;
      } else {
        label = `Check-out: ${check.summary} / ${check.lodging?.label} / ${check.welcomer?.firstname} ${check.welcomer?.lastname} / ${check.welcomer?.phoneNumber} / ${check.welcomer?.email}`;
      }
    }

    return label;
  }

  private setNewMissionTitleForOwner(
    userTarget: UserTarget,
    event: Reservation | Housework,
    label: string,
    eventType: EventType,
    checkType: CheckType
  ) {
    if (
      eventType === EventType.housework &&
      userTarget === UserTarget.owner &&
      event.summary?.includes('Nouveau')
    ) {
      let housework = event as Housework;
      label = `MENAGE: ${housework.summary} / ${housework.lodging?.label}`;
    }

    if (
      eventType === EventType.check &&
      userTarget === UserTarget.owner &&
      event.summary?.includes('Nouveau')
    ) {
      let check = event as Check;
      if (checkType === CheckType.in) {
        label = `Check-in: ${check.summary} / ${check.lodging?.label}`;
      } else {
        label = `Check-out: ${check.summary} / ${check.lodging?.label}`;
      }
    }

    return label;
  }

  private setProgrammedMissionTitleForWorker(
    userTarget: UserTarget,
    event: Reservation | Housework | Check,
    label: string,
    checkType?: CheckType
  ) {
    if (
      userTarget === UserTarget.cleaner &&
      event.summary?.includes('Programmé')
    ) {
      let housework = event as Housework;
      label = `${housework.lodging?.label}: ${housework.lodging?.address?.label} ${housework.lodging?.address?.city} / ${housework.summary} / MENAGE`;
    }

    if (
      userTarget === UserTarget.welcomer &&
      event.summary?.includes('Programmé')
    ) {
      let check = event as Check;
      if (checkType === CheckType.in) {
        label = `Check-in: ${check.lodging?.label}: ${check.lodging?.address?.label} ${check.lodging?.address?.city} / ${check.summary}`;
      } else {
        label = `Check-out: ${check.lodging?.label}: ${check.lodging?.address?.label} ${check.lodging?.address?.city} / ${check.summary}`;
      }
    }

    return label;
  }

  private setColor(
    userTarget: UserTarget,
    event: Reservation | Housework | Check,
    eventType: EventType,
    isCheckOut?: boolean
  ): string | undefined {
    let color;

    if (userTarget === UserTarget.owner) {
      if (eventType === EventType.reservation) {
        if (event.summary === 'Annulé') {
          color = '#8B0000';
        } else if (event.summary?.toLowerCase().includes('not available')) {
          color = '#E65100';
        } else if (event.uniqueReference?.toLowerCase().includes('airbnb')) {
          color = '#FF5A5F';
        } else if (event.uniqueReference?.toLowerCase().includes('booking')) {
          color = '#003580';
        }
      }

      if (eventType === EventType.housework) {
        if (event.summary === 'Annulé') {
          color = '#8B0000';
        } else if (event.summary?.toLowerCase().includes('nouveau')) {
          color = '#FFD600';
        } else if (event.summary?.toLowerCase().includes('programmé')) {
          color = '#3788d8';
        }
      }

      if (eventType === EventType.check) {
        if (event.summary === 'Annulé') {
          color = '#8B0000';
        } else if (event.summary?.toLowerCase().includes('nouveau')) {
          color = '#FFD600';
        } else if (event.summary?.toLowerCase().includes('programmé')) {
          color = '#1ad279';
        }
      }
    }

    if (userTarget === UserTarget.cleaner) {
      if (eventType === EventType.housework) {
        if (event.summary === 'Annulé') {
          color = '#8B0000';
        } else if (event.summary?.toLowerCase().includes('programmé')) {
          color = '#3788d8';
        }
      }
    }

    if (userTarget === UserTarget.welcomer) {
      if (eventType === EventType.check) {
        if (event.summary === 'Annulé') {
          color = '#8B0000';
        } else if (isCheckOut) {
          color = '#1ad279';
        } else if (event.summary?.toLowerCase().includes('programmé')) {
          color = '#3788d8';
        }
      }
    }

    return color;
  }

  private getPlatformLabel(uniqueReference?: string): string {
    let platformLabel: string = '';

    if (uniqueReference?.toLowerCase().includes('airbnb')) {
      platformLabel = 'Airbnb';
    }

    if (uniqueReference?.toLowerCase().includes('booking')) {
      platformLabel = 'Booking';
    }

    return platformLabel;
  }

  private getEventStatus(summary?: string): string {
    let status: string = '';

    if (
      summary?.toLowerCase().includes('reserved') ||
      (summary?.toLowerCase().includes('closed') &&
        !summary?.toLowerCase().includes('not available'))
    ) {
      status += 'Loué';
    } else if (summary?.toLowerCase().includes('not available')) {
      status = 'Vérrouillé';
    } else if (summary?.toLowerCase().includes('nouveau')) {
      status = 'Mission vacante';
    } else if (summary?.toLowerCase().includes('programmé')) {
      status = 'Mission attribuée';
    } else {
      status = 'Annulé';
    }

    return status;
  }
}
