<ng-container class="user-component" *ngIf="user">
  <div
    [class]="
      width < 1080
        ? 'user-component__container --mobile'
        : 'user-component__container'
    "
  >
    <div
      [class]="
        width < 1080
          ? 'user-component__container__picture --mobile'
          : 'user-component__container__picture'
      "
    >
      <img
        mat-card-avatar
        class="user-component__container__picture__image"
        [src]="userArchive!.filePath"
        (error)="userFileService.handleMissingImage(userArchive)"
        alt="Photo de profil"
      />
      <div class="user-component__container__picture__button">
        <div>
          <button
            mat-flat-button
            class="user-component__container__picture__button__select"
            color="primary"
          >
            <input
              #selector
              type="file"
              id="select"
              class="user-component__container__picture__button__select__input"
              (change)="selectFile($event)"
            />
            <label
              class="user-component__container__picture__button__select__label"
              for="select"
              >Choisir une image</label
            >
          </button>
        </div>

        <div class="user-component__container__picture__button__progress">
          <span
            class="user-component__container__picture__button__progress__upload"
            *ngIf="progress > 0"
          >
            {{ progress }}%
          </span>
          <span
            class="user-component__container__picture__button__progress__upload"
            *ngIf="message"
          >
            {{ message }}
          </span>
        </div>
      </div>
    </div>

    <div
      [class]="
        width < 1080
          ? 'user-component__container__infos --mobile'
          : 'user-component__container__infos'
      "
    >
      <div class="user-component__container__infos__general-profil">
        <div class="user-component__container__infos__general-profil__header">
          <div
            class="user-component__container__infos__general-profil__header__general-infos"
          >
            <h2>Informations générales</h2>
          </div>
          <div
            class="user-component__container__infos__general-profil__header__button"
          >
            <button mat-raised-button (click)="editUser()">
              <mat-icon class="icon" aria-hidden="false">edit</mat-icon>
              Modifier
            </button>
          </div>
        </div>
        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('NOM', user.firstname!)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'NOM'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.firstname" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('PRENOM', user.lastname!)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'PRENOM'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.lastname" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="
              let tile of setTiles(
                'DATE DE NAISSANCE',
                user.birthDay!.toLocaleString()
              )
            "
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'DATE DE NAISSANCE'" class="edit-title">
              {{ tile.text }}
            </div>
            <div
              *ngIf="tile.text == user.birthDay?.toLocaleString()"
              class="edit-text"
            >
              {{ tile.text | formatDate }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <div class="user-component__container__infos__contact-info">
        <h2>Coordonnées</h2>
        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('TELEPHONE', user.phoneNumber!)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'TELEPHONE'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.phoneNumber" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('ADRESSE E-MAIL', user.email!)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'ADRESSE E-MAIL'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.email" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <div class="user-component__container__infos__address">
        <h2>Adresse</h2>
        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('RUE', user.address!.label)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'RUE'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.address!.label" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="
              let tile of setTiles('CODE POSTAL', user.address!.postalCode)
            "
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'CODE POSTAL'" class="edit-title">
              {{ tile.text }}
            </div>
            <div
              *ngIf="tile.text == user.address!.postalCode"
              class="edit-text"
            >
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('VILLE', user.address!.city)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'VILLE'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.address!.city" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('REGION', user.address!.region)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'REGION'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.address!.region" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list [cols]="colsValue" [rowHeight]="rowHeightValue">
          <mat-grid-tile
            *ngFor="let tile of setTiles('PAYS', user.address!.country)"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="tile.color"
          >
            <div *ngIf="tile.text == 'PAYS'" class="edit-title">
              {{ tile.text }}
            </div>
            <div *ngIf="tile.text == user.address!.country" class="edit-text">
              {{ tile.text }}
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</ng-container>
