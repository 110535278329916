<div id="description">
  <h1 mat-dialog-title>Ajouter un agent d'entretien</h1>
  <p
    *ngIf="
      workerList?.length === 0 ||
      workerList === undefined ||
      workerList === null
    "
  >
    Aucun agent d'entretien n'est disponible pour ce logement.
  </p>
</div>

<div mat-dialog-content id="worker-list">
  <mat-list id="worker-mat-list" *ngFor="let worker of workerList">
    <button mat-raised-button id="worker-name">
      <mat-icon *ngIf="worker.role?.label === 'Cleaner'" aria-hidden="false"
        >brush</mat-icon
      >
      <mat-icon *ngIf="worker.role?.label === 'Welcomer'" aria-hidden="false"
        >key</mat-icon
      >
      {{ worker.lastname }} {{ worker.firstname }}
    </button>
    <button mat-raised-button id="worker-address">
      <mat-icon class="icon" aria-hidden="false">place</mat-icon>
      {{ worker.address?.postalCode }} {{ worker.address?.city }}
    </button>
    <button mat-raised-button id="worker-phoneNumber">
      <mat-icon class="icon" aria-hidden="false">phone</mat-icon>
      {{ worker.phoneNumber }}
    </button>
    <button mat-raised-button id="worker-email">
      <mat-icon class="icon" aria-hidden="false">mail</mat-icon>
      {{ worker.email }}
    </button>
    <button
      mat-raised-button
      (click)="deleteCleanerLink(worker)"
      id="add-link"
      [matTooltip]="
        width < 1024 ? '' : 'Créer le lien avec l\'agent d\'entetient.'
      "
    >
      <mat-icon class="icon" aria-hidden="false">add</mat-icon>
      <span *ngIf="width < 1024">Créer le lien</span>
    </button>

    <mat-divider *ngIf="width < 1024" id="divider"></mat-divider>
  </mat-list>
</div>

<mat-card-actions id="buttons">
  <button mat-button id="cancel-button" (click)="onClose()">Fermer</button>
</mat-card-actions>
