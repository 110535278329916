import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { ConfirmPopupComponent } from '../shared/components/confirm-popup/confirm-popup.component';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { ImportCalendarComponent } from './import-calendar/import-calendar.component';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { LodgingFileService } from '../shared/services/file/lodging-file.service';
import { AdminLodgingComponent } from './admin-lodging/admin-lodging.component';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { CalendarBuilder } from 'src/app/shared/builder/calendar-builder';
import { Authentication } from '../core/models/symbiosis/authentication';
import { contentType } from 'src/environments/environment.content.types';
import { LodgingBuilder } from 'src/app/shared/builder/lodging-builder';
import { httpCallMethod } from 'src/environments/environment.methods';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArchiveBuilder } from '../shared/builder/archive-builder';
import { FileService } from '../shared/services/file/file.service';
import { AuthBuilder } from 'src/app/shared/builder/auth-builder';
import { LodgingType } from 'src/app/core/enum/lodging.type';
import { Calendar } from '../core/models/symbiosis/calendar';
import { Lodging } from '../core/models/symbiosis/lodging';
import { Archive } from '../core/models/archivum/archive';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ImageType } from '../core/enum/image-type';
import {
  archivumEndPoint,
  symbiosisEndPoint,
} from 'src/environments/environment.api.endpoints';
import { Tile } from 'src/app/user/user.component';
import { FileType } from '../core/enum/file-type';
import { ActivatedRoute } from '@angular/router';
import {
  defaultFilePath,
  fileBasePath,
} from 'src/environments/environment.file.path';
import { finalize, tap } from 'rxjs';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-lodging',
  templateUrl: './lodging.component.html',
  styleUrls: ['./lodging.component.scss'],
})
export class LodgingComponent implements OnInit {
  @ViewChild('selector')
  selector!: ElementRef;

  public lodgingArchive: Archive = new Archive(
    defaultFilePath.lodging_default_icon
  );
  public lodgingImageType: ImageType = ImageType.Lodging;
  public searchableSlideToggle!: FormControl;
  public keys!: Array<string | LodgingType>;
  public isHiddenUrlList!: Array<boolean>;
  public welcomerForCheck!: FormControl;
  public rowHeightValue: number = 45;
  public colsValue: number = 4;
  public lodging?: Lodging;

  constructor(
    private authenticationService: AuthenticationService,
    public lodgingFileService: LodgingFileService,
    private httpClientService: HttpClientService,
    private calendarBuilder: CalendarBuilder,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    private archiveBuilder: ArchiveBuilder,
    private lodgingBuilder: LodgingBuilder,
    private alertService: AlertService,
    private authBuilder: AuthBuilder,
    public fileService: FileService,
    private dialog: MatDialog
  ) {
    this.getActiavtedRoute();
    this.getKeys();
  }

  ngOnInit(): void {
    this.spinnerService.requestEnded();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public createCalendar(): void {
    const dialogRef = this.dialog.open(ImportCalendarComponent, {
      width: '600px',
      data: this.lodging,
    });

    dialogRef.afterClosed().subscribe((iCalUrl: any) => {
      if (iCalUrl != null) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.createCalendar,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.post,
            contentType.application_json,
            JSON.stringify(
              this.calendarBuilder.buildCalendar(iCalUrl, this.lodging?.id)
            )
          )
          .subscribe({
            next: (calendar: Calendar) => {
              if (
                calendar.alertMessage === undefined ||
                calendar.alertMessage === null
              ) {
                this.authenticationService.currentAuthenticationValue?.lodgings?.forEach(
                  (lodging: Lodging) => {
                    if (lodging.id === this.lodging?.id) {
                      if (lodging.calendars === null) {
                        lodging.calendars = new Array<Calendar>();
                      }
                      lodging?.calendars?.push(calendar);
                      this.lodging = lodging;
                    }
                  }
                );
                this.authenticationService.setOrRefreshLocalStorage(
                  this.authenticationService.currentAuthenticationValue!
                );
                window.location.reload();
              }
            },
            error: (error: HttpErrorResponse) => {
              this.alertService.error(error.message);
            },
          });
      }
    });
  }

  public editLodging(): void {
    const dialogRef = this.dialog.open(AdminLodgingComponent, {
      width: '1000px',
      data: this.lodging,
    });

    dialogRef.afterClosed().subscribe((lodgingForm: FormGroup) => {
      if (lodgingForm != null) {
        let authentication = this.authBuilder.buildAuthenticationFromForm(
          lodgingForm,
          OperationType.edit,
          false,
          this.lodging!.id,
          true
        );
        const url = this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateLodging,
          this.lodging!.id
        );
        this.httpClientService
          .httpCall(
            url,
            HttpCallType.Request,
            httpCallMethod.put,
            contentType.application_json,
            JSON.stringify(authentication).replace('/\\/', '')
          )
          .subscribe({
            next: (authentication: Authentication) => {
              this.authenticationService.setOrRefreshLocalStorage(
                authentication
              );
              this.setLodging(
                authentication,
                this.lodging?.id,
                OperationType.edit
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          });
      }
    });
  }

  public editSearchable(): void {
    if (this.searchableSlideToggle.value === true) {
      this.searchableSlideToggle = new FormControl(true, [Validators.required]);
      this.lodging!.searchable = true;
    } else {
      this.searchableSlideToggle = new FormControl(false, [
        Validators.required,
      ]);
      this.lodging!.searchable = false;
    }

    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateLodging,
          this.lodging?.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(
          this.authBuilder.buildAuthenticationFromForm(
            new FormGroup({ searchable: this.searchableSlideToggle }),
            OperationType.edit,
            false,
            this.lodging!.id,
            true
          )
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
          this.setLodging(authentication, this.lodging?.id, OperationType.edit);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public editWelcomerForCheck(): void {
    if (this.welcomerForCheck.value === true) {
      this.welcomerForCheck = new FormControl(true, [Validators.required]);
      this.lodging!.welcomerForCheck = true;
    } else {
      this.welcomerForCheck = new FormControl(false, [Validators.required]);
      this.lodging!.welcomerForCheck = false;
    }

    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.updateLodging,
          this.lodging?.id
        ),
        HttpCallType.Request,
        httpCallMethod.put,
        contentType.application_json,
        JSON.stringify(
          this.authBuilder.buildAuthenticationFromForm(
            new FormGroup({
              welcomerForCheck: this.welcomerForCheck,
            }),
            OperationType.edit,
            false,
            this.lodging!.id,
            true
          )
        ).replace('/\\/', '')
      )
      .subscribe({
        next: (authentication: Authentication) => {
          this.authenticationService.setOrRefreshLocalStorage(authentication);
          this.setLodging(authentication, this.lodging?.id, OperationType.edit);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public setTiles(description: string, lodgingAttribute: string): Array<Tile> {
    return [
      { text: description, cols: 1, rows: 1, color: '#F0F0F0' },
      { text: lodgingAttribute, cols: 3, rows: 1, color: '#F0F0F0' },
    ];
  }

  public showHideCalendarUrl(index: number): void {
    this.isHiddenUrlList[index] = this.isHiddenUrlList[index] ? false : true;
  }

  public selectFile(event: any, lodging: Lodging): void {
    if (event.target.files && event.target.files[0]) {
      this.fileService
        .uploadFileInArchivum(
          archivumEndPoint.upload,
          this.lodgingFileService.setLodgingFormData(
            new Archive(
              fileBasePath.image,
              event.target.files[0],
              FileType.Image,
              this.authenticationService.currentAuthenticationValue?.user!
            ),
            lodging
          )
        )
        .pipe(
          tap({
            next: (archive: Archive) => {
              if (archive !== undefined) {
                this.lodgingArchive = archive;
              }
              this.fileService.refreshLocalStorage(
                archive,
                ImageType.Lodging,
                lodging?.id
              );
            },
            error: (error: HttpErrorResponse) => {
              console.error(error);
            },
          }),
          finalize(() => {
            if (lodging?.id !== undefined) {
              this.fileService
                .refreshGuid(
                  symbiosisApi.baseUrl,
                  symbiosisEndPoint.updateLodging,
                  lodging.id
                )
                .subscribe({
                  next: (authentication: Authentication) => {
                    this.authenticationService.setOrRefreshLocalStorage(
                      authentication
                    );

                    if (this.lodgingArchive.lodging?.id === lodging?.id) {
                      this.lodgingArchive.uniqueFileReference = lodging?.image;
                    }
                  },
                  error: (error: HttpErrorResponse) => {
                    console.error(error);
                  },
                });
            }
          })
        )
        .subscribe();
    }
  }

  public deleteCalendar(calendar: Calendar): void {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      width: '600px',
      data: `Supprimer définitivement le lien : ${calendar.url} ?`,
    });

    dialog.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.httpClientService
          .httpCall(
            this.httpClientService.buildUrl(
              symbiosisApi.baseUrl,
              symbiosisEndPoint.deleteCalendar,
              this.authenticationService.currentAuthenticationValue?.user?.id
            ),
            HttpCallType.Request,
            httpCallMethod.delete,
            contentType.application_json,
            JSON.stringify(calendar)
          )
          .subscribe({
            next: (deletedCalendar: Calendar) => {
              this.lodging?.calendars?.forEach((calendar) => {
                let index: number | undefined = -1;
                if (calendar.id === deletedCalendar.id) {
                  index = this.lodging?.calendars?.indexOf(calendar, 0);
                }
                if (index !== undefined && index > -1) {
                  this.lodging?.calendars?.splice(index, 1);
                }
              });
              this.authenticationService.currentAuthenticationValue?.lodgings?.forEach(
                (lodging: Lodging) => {
                  if (lodging.id === this.lodging?.id) {
                    lodging = this.lodging;
                    this.authenticationService.setOrRefreshLocalStorage(
                      this.authenticationService.currentAuthenticationValue!
                    );
                  }
                }
              );
            },
            error: (error: HttpErrorResponse) => {
              this.alertService.error(error.message);
            },
          });
      }
    });
  }

  private getKeys() {
    this.keys = Object.values(LodgingType).filter(
      (value) => typeof value === 'string'
    );
  }

  private getActiavtedRoute() {
    this.activatedRoute.queryParams.subscribe({
      next: (data: any) => {
        this.setLodging(
          this.authenticationService.currentAuthenticationValue!,
          data.id,
          OperationType.edit
        );
        this.isHiddenUrlList = new Array<boolean>();
        this.lodging!.calendars?.forEach((calendar) =>
          this.isHiddenUrlList.push(true)
        );
        this.searchableSlideToggle = new FormControl(this.lodging?.searchable, [
          Validators.required,
        ]);
        this.welcomerForCheck = new FormControl(
          this.lodging?.welcomerForCheck,
          [Validators.required]
        );
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.error(error.message);
      },
    });
  }

  private setLodging(
    authentication?: Authentication,
    lodgingId?: number,
    operationType?: OperationType
  ): void {
    this.lodging = this.lodgingBuilder.buildLodgingFromAuthenticaction(
      authentication ?? this.authenticationService.currentAuthenticationValue!,
      lodgingId,
      operationType
    );
    this.setLodgingArchive(this.lodging);
  }

  private setLodgingArchive(lodging: Lodging): void {
    this.fileService.getArchive(lodging.image!).subscribe({
      next: (archive: Archive) => {
        const newArchive = this.archiveBuilder.buildArchive(
          archive,
          this.authenticationService.currentAuthenticationValue?.user,
          lodging
        );
        if (archive !== undefined) {
          this.lodgingArchive = newArchive;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      },
    });
  }
}
