import { OperationType } from 'src/app/shared/builder/enum/operation.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { LodgingType } from 'src/app/core/enum/lodging.type';

@Component({
  selector: 'app-create-lodging',
  templateUrl: './admin-lodging.component.html',
  styleUrls: ['./admin-lodging.component.scss'],
})
export class AdminLodgingComponent implements OnInit {
  public lodgingTypeKeys: Array<string | LodgingType>;
  public submitted: boolean = false;
  public operation: OperationType;
  public lodgingForm!: FormGroup;
  public selected?: number;

  constructor(
    public dialogRef: MatDialogRef<AdminLodgingComponent>,
    @Inject(MAT_DIALOG_DATA) public lodging: Lodging,
    private formBuilder: FormBuilder
  ) {
    this.lodgingForm = this.formBuilder.group({
      lodgingLabel: [
        lodging != null ? lodging?.label : '',
        Validators.required,
      ],
      lodgingSurfaceArea: [
        lodging != null ? lodging?.surfaceArea : '',
        Validators.required,
      ],
      lodgingType: [
        lodging != null ? Number(lodging?.lodgingType) : '',
        Validators.required,
      ],
      checkInStart: [
        lodging != null ? lodging?.checkInStart : '',
        Validators.required,
      ],
      checkOutEnd: [
        lodging != null ? lodging?.checkOutEnd : '',
        Validators.required,
      ],
      searchable: [
        lodging != null ? lodging?.searchable : true,
        Validators.required,
      ],
      lodgingAddressLabel: [
        lodging != null ? lodging?.address?.label : '',
        Validators.required,
      ],
      lodgingPostalCode: [
        lodging != null ? lodging?.address?.postalCode : '',
        Validators.required,
      ],
      lodgingCity: [
        lodging != null ? lodging?.address?.city : '',
        Validators.required,
      ],
      lodgingRegion: [
        lodging != null ? lodging?.address?.region : '',
        Validators.required,
      ],
      lodgingCountry: [
        lodging != null ? lodging?.address?.country : '',
        Validators.required,
      ],
      description: [
        lodging != null ? lodging?.description : '',
        Validators.compose([Validators.required, Validators.maxLength(1000)]),
      ],
    });
    this.operation =
      lodging != null ? OperationType.edit : OperationType.create;
    this.selected = lodging != null ? Number(lodging.lodgingType) : undefined;
    this.lodgingTypeKeys = Object.values(LodgingType).filter(
      (value) => typeof value === 'string'
    );
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get f() {
    return this.lodgingForm.controls;
  }

  public get width() {
    return window.innerWidth;
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.lodgingForm.invalid) {
      return;
    }

    this.dialogRef.close(this.lodgingForm);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
