import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { SignalRHubService } from 'src/app/shared/services/signalR-hub/signalR-hub.service';
import { HttpClientService } from 'src/app/shared/services/httpclient/httpclient.service';
import { LodgingFileService } from 'src/app/shared/services/file/lodging-file.service';
import { MessageComponent } from 'src/app/shared/components/message/message.component';
import { HttpCallType } from 'src/app/shared/services/httpclient/enum/httpCallType';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { UserFileService } from 'src/app/shared/services/file/user-file.service';
import { symbiosisEndPoint } from 'src/environments/environment.api.endpoints';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { GridRowHeights } from 'src/app/core/user-interface/GridRowHeights';
import { defaultFilePath } from 'src/environments/environment.file.path';
import { contentType } from 'src/environments/environment.content.types';
import { FileService } from 'src/app/shared/services/file/file.service';
import { GridColumns } from 'src/app/core/user-interface/GridColumns';
import { httpCallMethod } from 'src/environments/environment.methods';
import { HubProfile } from 'src/app/core/models/dialogus/hub-profile';
import { symbiosisApi } from 'src/environments/environment.api.urls';
import { Component, HostListener, OnInit } from '@angular/core';
import { Lodging } from 'src/app/core/models/symbiosis/lodging';
import { Archive } from 'src/app/core/models/archivum/archive';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageType } from 'src/app/core/enum/image-type';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-lodging-result',
  templateUrl: './lodging-result.component.html',
  styleUrls: ['./lodging-result.component.scss'],
})
export class LodgingResultComponent implements OnInit {
  public gridColumns: GridColumns = { xs: 1, sm: 1, md: 2, lg: 2, xl: 3 };
  public gridRowHeights: GridRowHeights = {
    xs: '1:1.1',
    sm: '1:1.1',
    md: '1:1.1',
    lg: '1:1.1',
    xl: '1:1.1',
  };
  public lodgingArchiveList: Array<Archive> = new Array<Archive>();
  public userArchiveList: Array<Archive> = new Array<Archive>();
  public lodgingList = new Array<Lodging>();
  public receiver!: HubProfile;

  constructor(
    private authenticationService: AuthenticationService,
    public lodgingFileService: LodgingFileService,
    private httpClientService: HttpClientService,
    private signalRHubService: SignalRHubService,
    public userFileService: UserFileService,
    private spinnerService: SpinnerService,
    private searchService: SearchService,
    private fileService: FileService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.spinnerService.requestEnded();
    this.searchService.getSearchObject().subscribe((data: any) => {
      this.getSearchableLodging(data.searchInput);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public getSearchableLodging(searchInput: string): void {
    if (searchInput === '') {
      return;
    }

    this.spinnerService.requestStarted();
    this.httpClientService
      .httpCall(
        this.httpClientService.buildUrl(
          symbiosisApi.baseUrl,
          symbiosisEndPoint.searchLodging,
          this.authenticationService.currentAuthenticationValue?.user?.id!
        ),
        HttpCallType.Request,
        httpCallMethod.post,
        contentType.application_json,
        JSON.stringify(searchInput).replace('/\\/', '')
      )
      .subscribe({
        next: (lodgingList: Array<Lodging>) => {
          this.spinnerService.requestEnded();
          if (lodgingList.length > 0 || lodgingList.length !== undefined) {
            this.lodgingList = lodgingList;
            lodgingList.forEach(async (lodging) => {
              await this.setUserListAvatar(lodging);
              await this.setLodgingListImage(lodging);
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }

  public sendPrivateMessage(lodging: Lodging): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((messageForm) => {
      if (messageForm != null) {
        let receiver = this.getHubProfileByName(lodging.owner!.id!);
        this.signalRHubService.sendPrivateMessage(
          messageForm.message,
          this.authenticationService.currentAuthenticationValue?.user?.id!,
          receiver.id!,
          lodging.id
        );
      }
    });
  }

  public getAvatarPath(avatar: string): string {
    let filePath: string = defaultFilePath.user_default_icon;
    for (let archive of this.userArchiveList) {
      if (
        archive.uniqueFileReference === avatar &&
        archive.filePath !== undefined
      ) {
        filePath = archive.filePath;
        break;
      }
    }

    return filePath;
  }

  public getLodgingFilePath(image: string): string | undefined {
    return this.lodgingArchiveList.find(
      (archive) => archive.lodging?.image === image
    )?.filePath;
  }

  private getHubProfileByName(id: number): HubProfile {
    let receiver = new HubProfile();
    let onlineUsers = JSON.parse(localStorage.getItem('onlineUsers')!);
    onlineUsers.forEach((onlineUser: HubProfile) => {
      if (onlineUser.id === id) {
        receiver = onlineUser;
      }
    });

    return receiver;
  }

  private async setUserListAvatar(lodging: Lodging): Promise<void> {
    const userArchive = await this.fileService.getImage(
      lodging.owner?.avatar!,
      ImageType.User
    );
    if (userArchive !== null && !this.userArchiveList.includes(userArchive)) {
      this.userArchiveList.push(userArchive);
    }
  }

  private async setLodgingListImage(lodging: Lodging): Promise<void> {
    const lodgingArchive = await this.fileService.getImage(
      lodging.image!,
      ImageType.Lodging
    );
    if (
      lodgingArchive !== null &&
      !this.lodgingArchiveList.includes(lodgingArchive)
    ) {
      lodgingArchive.lodging = lodging;
      this.lodgingArchiveList.push(lodgingArchive);
    }
  }
}
