import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/core/models/symbiosis/user';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-add-worker',
  templateUrl: './add-worker.component.html',
  styleUrls: ['./add-worker.component.scss'],
})
export class AddWorkerComponent implements OnInit {
  public workerList: Array<User>;
  public addedWorkerList: Array<User>;

  @Output() workerLineFunction;

  constructor(
    private dialogRef: MatDialogRef<AddWorkerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;
    this.workerList = data.workerList;
    this.addedWorkerList = new Array<User>();

    this.workerLineFunction = new EventEmitter<User>();
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any } }) {
    event.target.innerWidth;
  }

  public get width() {
    return window.innerWidth;
  }

  public deleteCleanerLink(worker: User): void {
    this.workerLineFunction?.emit(worker);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
