import { Reservation } from './symbiosis/reservation';
import { Housework } from './symbiosis/housework';
import { Check } from './symbiosis/check';

export class FullCalendarEvent {
  public title?: string;
  public color?: string;
  public start?: Date;
  public end?: Date;
  public platform?: string;
  public status?: string;
  public reservation?: Reservation;
  public housework?: Housework;
  public check?: Check;

  constructor(
    title?: string,
    color?: string,
    start?: Date,
    end?: Date,
    platfom?: string,
    status?: string,
    reservation?: Reservation,
    housework?: Housework,
    check?: Check
  ) {
    this.title = title;
    this.color = color;
    this.start = start;
    this.end = end;
    this.platform = platfom;
    this.status = status;
    this.reservation = reservation;
    this.housework = housework;
    this.check = check;
  }
}
