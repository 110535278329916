import { SearchService } from '../shared/services/search/search.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public searchForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchInput: ['', Validators.required],
    });
  }

  search() {
    this.searchService.searchSubject.next(this.searchForm.value);
  }
}
