<ng-container class="lodging-list-component">
  <div class="lodging-list-component__description">
    <h1 mat-dialog-title>Liste des logements</h1>
    <p *ngIf="lodgingList!.length > 0">
      Sélectionnez un logement pour afficher son détail.
    </p>
    <p *ngIf="lodgingList!.length === 0">
      Vous n'avez pas encore créé de logement. Pour ce faire, veuillez cliquer
      sur "Créer un logement".
    </p>
  </div>

  <div mat-dialog-content class="lodging-list-component__lodging-list">
    <mat-list
      class="lodging-list-component__lodging-list__lodging-mat-list"
      *ngFor="let lodging of lodgingList"
    >
      <app-lodging-line [lodging]="lodging"></app-lodging-line>
    </mat-list>
  </div>

  <div class="lodging-list-component__button">
    <button mat-raised-button (click)="createLodging()">
      <mat-icon class="lodging-list-component__button__icon" aria-hidden="false"
        >add_circle</mat-icon
      >
      Créer un logement
    </button>
  </div>
</ng-container>
