<ng-container class="event-component">
  <div class="event-component__container">
    <app-owner-event
      *ngIf="
        authenticationService.currentAuthenticationValue?.role?.label ===
        'Owner'
      "
      [reservationEvent]="reservationEvent"
      [houseworkEvent]="houseworkEvent"
      [checkEvent]="checkEvent"
      [lodgingArchive]="lodgingArchive"
      [cleanerArchive]="cleanerArchive"
      [welcomerArchive]="welcomerArchive"
      [eventSelected]="eventSelected"
    ></app-owner-event>

    <app-cleaner-event
      *ngIf="
        authenticationService.currentAuthenticationValue?.role?.label ===
        'Cleaner'
      "
      [houseworkEvent]="houseworkEvent"
      [lodgingArchive]="lodgingArchive"
      [eventSelected]="eventSelected"
    ></app-cleaner-event>

    <app-welcomer-event
      *ngIf="
        authenticationService.currentAuthenticationValue?.role?.label ===
        'Welcomer'
      "
      [checkEvent]="checkEvent"
      [lodgingArchive]="lodgingArchive"
      [eventSelected]="eventSelected"
    ></app-welcomer-event>
  </div>
</ng-container>
